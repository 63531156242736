import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { slugify } from '../../utils';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Comment from '../../pages/blog/Comment';


const IeltsInnerBlog = () => {
    const { state} = useLocation();

    console.log(state);
    const { id } = useParams();
    const blogId = parseInt(id, 10);
    
    
    const dataItem = state.data;
   
    const data = dataItem;
    console.log(data);
    // console.log(data);
    return (
        <>
            <SEO title={data.title} />
            <Layout>
                <BreadcrumbOne
                    // title={ data.title }
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog Details"
                />
                <div className="edu-blog-details-area  bg-color-white">
                    
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-1 style-variation3">

                                    <div className="content-blog-top">
                                    <h4 style={{ marginRight: "5px" }}>{data.titlee}</h4>

                                        <div className="content-status-top d-flex justify-content-between mb--30 align-items-center">
                                           
                                            <ul className="blog-meta">
                                                <li className="blog-author"><img src="/images/blog/author/author-small/author.png" alt="Blog Images" /> <Link to={process.env.PUBLIC_URL}>{data.author}</Link></li>
                                                <li><i className="icon-calendar-2-line"></i>{data.date}</li>
                                                <li><i className="icon-discuss-line"></i>{data.comment}</li>
                                                <li><i className="icon-time-line"></i>{data.readingTime}</li>
                                            </ul>
                                        </div>

                                  
                                        <div className="thumbnail block-alignwide">
                                            <img className="radius-small w-100 mb--30" src={`${process.env.PUBLIC_URL}${data.image}`} alt="Blog Thumb" />
                                        </div>
                                    </div>

                                    <div className="blog-main-content">
                                        <h5 className="title">{data.title1}</h5>
                                        <p style={{fontFamily:"sans-serif",fontSize:"20px"}} >{data.title2}</p>
                                       
                                        <ul  style={{fontFamily:"sans-serif",fontSize:"20px"}}className="list-style-2">
                                           
                                            
                                           {data.dataa.map((list)=>{
                                              {console.log(list)}
                                            return(<li style={{fontFamily:"sans-serif",fontSize:"19px",fontWeight:"bold",color:"black"}}>{list}</li>)
                                        })}
                                      </ul>
                                      <p style={{fontSize:"18px",fontFamily:"sans-serif"}}><b style={{color:"black"}}>Ans </b> -{data.title3}</p>
                                      <p style={{fontSize:"18px",fontFamily:"sans-serif"}}>{data.title5}</p>
                                      <p style={{fontSize:"18px",fontFamily:"sans-serif"}}>{data.title4}</p>
                                      <h5 className="title">{data.title6}</h5>
                                      <ul  style={{fontFamily:"sans-serif",fontSize:"20px"}}className="list-style-2">
                                           
                                            
                                           {data.dataa2.map((list)=>{
                                              {console.log(list)}
                                            return(<li style={{fontFamily:"sans-serif",fontSize:"18px"}}>{list}</li>)
                                        })}
                                      </ul>
                                      
                                        {/* <p style={{fontSize:"18px",fontFamily:"sans-serif"}}>The person I would like to talk about is my former boss, Sarah. Sarah was the director of a non-profit organization where I worked for several years. She was an exceptional leader, and I learned a great deal from her.

                                            Firstly, Sarah had a clear and compelling vision for the organization. She knew what she wanted to achieve, and she was able to communicate that vision to the staff and volunteers. She was also open to feedback and suggestions, and she was always willing to listen to different perspectives.

                                            Secondly, Sarah was a great communicator.
                                            </p><p style={{fontSize:"18px",fontFamily:"sans-serif"}}> She was always available to talk to, and she was very approachable. She listened attentively to people and made them feel valued. She was also very good at giving feedback, both positive and constructive, and she was able to do so in a way that was respectful and helpful.

                                            Thirdly, Sarah was a risk-taker. She was not afraid to try new things, and she encouraged the staff and volunteers to do the same. She was also willing to take calculated risks, and she was always looking for ways to improve the organization’s programs and services.

                                            Sarah was not only a great leader but also a great human being. One day, I was feeling overwhelmed with my workload and was struggling to meet a deadline.</p> <p style={{fontSize:"18px",fontFamily:"sans-serif"}}> I went to Sarah’s office to discuss my concerns with her. Instead of telling me what to do, Sarah asked me questions and listened to me attentively. She acknowledged the challenges I was facing and offered words of encouragement. She also gave me some suggestions on how I could prioritize my tasks and manage my time more effectively.

                                            In conclusion, Sarah was an outstanding leader who embodied the qualities of a good leader. She had a clear vision, was a great communicator, was willing to take risks, and was a visionary thinker. I am grateful for the opportunity to have worked with her, and I have no doubt that she will continue to make a positive impact in the non-profit sector..</p>
                                        
                                        <blockquote className="blockquote-style-1">“ This is a Quote quis eleifend quam adipiscing vitae aliquet bibendum enim facilisis gravida neque velit euismod in pellentesque massa placerat volutpat lacus laoreet non curabitured gravida odio aenean sed adipiscing.”
                                            <img className="quote-image" src="/images/icons/quote-2.png" alt="Quote Images" />
                                        </blockquote> */}

                                        {/* <h5 className="title">The 3 most important things to consider</h5>
                                        <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec odio aea the dumm ipsumm ipsum that dolocons rsus mal suada and fadolorit to the consectetur dummy read more elit.Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec that dolocons rsus mal suada and fadolorit to the consectetur elit.</p>
                                        <ul className="list-style-2">
                                            <li>Struggling to sell one multi-million dollar home currently on the market</li>
                                            <li>The point of using Lorem Ipsum is that it has a normal distribution of letters.</li>
                                            <li>Publishing packages and web page editors now use their default model text.</li>
                                            <li>Publishing packages and web page editors now use their default model text.</li>
                                        </ul> */}
                                         <h5 className="title">Related Article</h5>
                                        <ul className="gallery gallery-column-2 mt--5">
                                            <li>
                                                <img className="w-100" src={`${process.env.PUBLIC_URL}${data.img1}`} alt="Blog Thumb" />
                                                <h6 style={{margin:"15px"}}>{data.article1}</h6>
                                            </li>
                                            <li><img className="w-100" src={`${process.env.PUBLIC_URL}${data.img2}`} alt="Blog Thumb" />
                                            <h6 style={{margin:"15px"}}>{data.article2}</h6></li>
                                        </ul>
                                        {/* <h5>Learned From Doing One Of Those Social Media</h5>
                                        <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion 
                                            consectetur elit. Vesti at bulum nec the odio aea the dumm ipsumm ipsum
                                             that dolocons rsus mal suada and fadolorit to the consectetur elit. 
                                             All the Lorem Ipsum generators on the Internet tend to repeat that
                                              predefined chunks as necessary making this the first true dummy generator on 
                                              the Internet.Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion 
                                              consectetur elit.</p> */}

                                    </div>

                                    <div className="blog-tag-and-share mt--5">
                                        {/* {data.tags && data.tags.length > 0 &&
                                            <div className="blog-tag">
                                                <div className="tag-list bg-shade">
                                                    {
                                                        data.tags.map((tag, i) => {
                                                            return (
                                                                <Link key={i} to={process.env.PUBLIC_URL + `/tag/${slugify(tag)}`}>
                                                                    {tag}
                                                                </Link>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        } */}
                                        {/* <div className="eduvibe-post-share">
                                            <span>Share: </span>
                                            <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                            <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                            <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                            <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                        </div> */}
                                    </div>

                                    {/* <div className="blog-author-wrapper">
                                        <div className="thumbnail">
                                            <img src="/images/blog/author/author-medium/author-02.jpg" alt="Author Images" />
                                        </div>
                                        <div className="author-content">
                                            <h6 className="title">John Smith</h6>
                                            <p>Jhon Smith is an author, blogger, and designer living in a suburb of Washington, DC. When she’s not designing, blogging, or writing, Owen can be found with her head in a book or pinning like a madman.</p>
                                            <ul className="social-share icon-transparent">
                                                <li><a href="#"><i className="icon-Fb"></i></a></li>
                                                <li><a href="#"><i className="icon-linkedin"></i></a></li>
                                                <li><a href="#"><i className="icon-Pinterest"></i></a></li>
                                                <li><a href="#"><i className="icon-Twitter"></i></a></li>
                                            </ul>
                                        </div>
                                    </div> */}

                                    <div className="blog-pagination">
                                        <div className="row g-5">
                                            <div className="col-lg-6">
                                                <div className="blog-pagination-list style-variation-2" style={{border:"1px solid gray"}}>
                                                    <a >
                                                        <i className="ri-arrow-left-s-line"></i>
                                                        <span>Previous</span>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="blog-pagination-list style-variation-2 next-post" style={{border:"1px solid gray"}}>

                                                    <a>
                                                        <span>Next</span>
                                                        <i className="ri-arrow-right-s-line"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="edu-comment-form mt--50">
                                        <Comment
                                            url=""
                                            id={data.id}
                                            title={data.title}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default IeltsInnerBlog;