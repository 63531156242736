// import { useDeepCompareEffect, useThemeMediaQuery } from "@fuse/hooks";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import AddNewStudentApplicationSelectCountry from "./AddNewStudentApplicationSelectCountry";
import AddNewStudentApplicationGetStarted from "./AddNewStudentApplicationGetStarted";
// import FusepageCarded from "../../@fuse/core/FusePageCarded"
// import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  Icon,
  ButtonBase,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
const AddNewStudentApplicationApp = () => {
  // const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  return (
    <div className="flex w-full h-full justify-center items-center fixed ">
    {/* <img src='/images/applicationflow/countrybg.jpg' /> */}
      <div className="justify-center items-center absolute mb-36  w-screen h-fit rounded-[30px] backdrop-filter backdrop-blur-sm bg-opacity-50 hover:shadow-gray-500 shadow-xl  border-gray-600">
        <AddNewStudentApplicationGetStarted />
        </div></div>
  );
};
export default AddNewStudentApplicationApp;
// export default withReducer("AddNewApplicationNMasterApp", reducer)(AddNewApplicationNMasterApp);
