import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants";  

export const GetAllCourse = createAsyncThunk('getallcourse', async() => {
  const response = await publicRequest.get('/courses/getallcourse');
  // console.log('This is response ', response)
  return response.data;
});

const getallcourse = createSlice({
  name: 'getallcoursetype',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(GetAllCourse.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default getallcourse.reducer;