import Dialog from '@mui/material/Dialog';
import { AppBar, Toolbar, Icon, DialogActions, Box, Typography,Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewAllDetailCoursePopUp from './ViewAllDetailCoursePopUp';
import React,{useState} from "react"
const CoursesInUniversityPopup = (props) => {
    const { openDeletePopup, setOpenDeletePopup, handleDeleteFalse, handleDeleteTrue, name,data } = props;
    const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
    const [AllDetailOf, setAllDetailOf] = useState([]);
    // let AllDetailOf;
    const handleUpdateTrue = (name) => {
        console.log("you said yes");
        setOpenUpdatePopup(false);
      };
      const handleUpdateFalse = () => {
        console.log("you said No");
        setOpenUpdatePopup(false);
      };    
    const handleClose = () => {
        setOpenDeletePopup(false);
    };

    const handleAllDetailPopUpForCourse=(name)=>{
        setOpenUpdatePopup(true);
        setAllDetailOf(name)
        // setOpenPopupFor("Student_Profile");
    }

// console.log("the data that is going ",AllDetailOf)

    return (
        <>
        <ViewAllDetailCoursePopUp 
                openDeletePopupForAllDetail={openUpdatePopup}
                setOpenDeletePopupForAllDetail={setOpenUpdatePopup}
                handleDeleteTrueForAllDetail={() => handleUpdateTrue()}
                handleDeleteFalseForAllDetail={() => handleUpdateFalse()}
                // nameForAllDetail={openPopupFor}
                dataForAllDetail={AllDetailOf}
        />
            <Dialog
                classes={{
                    paper: 'm-24 rounded-3xl',
                }}
                open={openDeletePopup}
                onClose={handleClose}
                fullWidth
                maxWidth="xs"
            >
                <AppBar position="static" elevation={0} className=''>
                    <Toolbar className="flex w-full">
                        <label>here are courses of choosen university</label>
                        <button
                            // size="large"
                            // edge="start"
                            // color="inherit"
                            // aria-label="menu"
                            // sx={{ mr: 2 }}
                            className="absolute mr-10 p-2.5 px-3 rounded-full right-0 hover:bg-[#ffff] hover:bg-opacity-25 hover:text-black hover:shadow-md "
                            onClick={handleClose}
                        >
                            <CloseIcon sx={{ fontSize: "2rem" }} />
                        </button>
                    </Toolbar>
                </AppBar>
                {/* //   ${uk ? "bg-sky-300" : "bg-gray-200"} */}
               <Grid container className=' pb-5'>
                {data && data.map((name)=>(
                <Grid item xs={12} className=' justify-start px-5 flex items-center'>
               <div className='w-full'>
                    <div className="w-full ">
                        <motion.div
                            className={`dark:focus-visible:outline-gray-500 w-full bg-opacity-45  
               
                h-auto focus:outline-gray-400 focus:scale-105 
           
              `}
                            viewport={{ once: true }}
                            initial={{ opacity: 0.97, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ type: "easeIn", duration: 0.25 }}
                            whileHover={{ scale: 1.05 }}

                        >
                            <div className="w-full  h-fit mt-5 ml-5 flex justify-between items-center rounded-[10px] shadow-lg ">
                                <div className=' ml-3'>{name.course_id.course_name}-({name.course_id.course_short_name})</div>
                                <div className=" ml-3   flex justify-center h-fit w-fit    ">
                                    <button onClick={()=>handleAllDetailPopUpForCourse(name)} className=" h-fit p-1 px-4     rounded-l-none
                       backdrop-blur-sm rounded-[10px] mt-0 justify-center items-center flex
                        w-full bg-blue-500 relative  sparkle-button gap-1  font-semibold  bg-gradient-to-r from-blue-500 to-blue-700 text-white text-[16px] py-4 focus:outline-none  focus:ring-blue-500  ">

                                        <VisibilityIcon sx={{ fontSize: "2rem", color: "white" }} className=' opacity-60' />

                                    </button>
                                </div>
                            </div>

                        </motion.div>
                    </div>
                </div>
                </Grid>
                
                ))}
               </Grid>

            </Dialog>
        </>
    );
};

export default CoursesInUniversityPopup;