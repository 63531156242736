var courses= [
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Economics",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Clinical Psychology",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 44100
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Master of Business Analytics (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Master of Business Analytics (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Master of Business Analytics (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Engineering & Technology",
      "CourseName": "Bachelor of Arts and Bachelor of Engineering (Honours)",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41300
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Engineering & Technology",
      "CourseName": "Bachelor of Science and Bachelor of Engineering (Honours)",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Applied Economics and Master of Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Banking and Finance and Master of Management",
      "Duration (in Years)": 2.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Master of Business Analytics and Master of Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of Creative Industries and Master of Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commmerce, Business & Management",
      "CourseName": "Master of Management and Master of Marketing",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commmerce, Business & Management",
      "CourseName": "Master of International Business and Master of Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of International Relations and Master of Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of International Trade and Commerce Law and Master of Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Finance and Master of Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Arts & Humanities",
      "CourseName": "Master of Management and Master of Media and Communications",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Law",
      "CourseName": "Master of Management and Master of Public and Social Policy",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Arts & Humanities",
      "CourseName": "Bachelor of Commerce and Bachelor of Education (Secondary)",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Art & Humanities; Health & Medicine",
      "CourseName": "Bachelor of Education (Primary) and Bachelor of Psychology ",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 38400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Master of Business Analytics and Master of Information Systems Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Master of Business Analytics and Master of Information Technology in Cyber Security",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Master of Business Analytics and Master of Information Technology in Networking",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Medical Sciences",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Psychology (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 38100
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Social Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Applied Finance",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business Administration",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Clinical Audiology",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 58500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Clinical Neuropsychology",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 44100
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's ",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Chiropractic Science",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 45400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Health & Medicine",
      "CourseName": "Master of Organisational Psychology",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 44100
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Health & Medicine",
      "CourseName": "Master of Speech and Language Pathology",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 58500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of International Trade and Commerce Law",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's ",
      "Field": "IT & Computing",
      "CourseName": "Bachelor of Information Technology",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Laws",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's ",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's ",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of International Studies",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Arts & Humanities",
      "CourseName": "Master of Philosophy (Business and Economics)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy (Arts)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science; Engineering & Technology; Arts & Humanities",
      "CourseName": "Master of Philosophy (Science and Engineering)",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 51900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Birth to Five Years) ",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 35800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Planning",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Actuarial Studies",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Biodiversity and Conservation",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Environment",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Arts & Humanities",
      "CourseName": "Bachelor of Marketing and Media",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Bachelor of Business Analytics",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Ancient History",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Data Science (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Data Science (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 44500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Arts & Humanities",
      "CourseName": "Bachelor of Archaeology",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Arts & Humanities",
      "CourseName": "Bachelor of Security Studies",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Teaching (Early Childhood Education)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 36300
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Graduate Diploma",
      "Field": "Science",
      "CourseName": "Graduate Diploma of Environment",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Graduate Diploma",
      "Field": "Science",
      "CourseName": "Graduate Diploma of Translating and Interpreting",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Actuarial Practice",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Applied Finance (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 44900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Applied Finance (1.5 year)",
      "Duration (in Years)": 1.5,
      "Intake": "Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 44900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Applied Linguistics and TESOL (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Applied Linguistics and TESOL (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Applied Linguistics and TESOL (1.5 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Applied Statistics (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 44500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Applied Statistics (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 44500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Administration (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Administration (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Commerce (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Commerce (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Commerce (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Early Childhood (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 35800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Early Childhood (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 35800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Education (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 35800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Education (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 35800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of International Business (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of International Business (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of International Business (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Management (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Management (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Management (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Sustainable Development (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Sustainable Development (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Sustainable Development (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Translation and Interpreting Studies ",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science; Engineering & Technology",
      "CourseName": "Master of Biotechnology (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 44500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science; Engineering & Technology",
      "CourseName": "Master of Biotechnology (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 44500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Chiropractic (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 48800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Chiropractic (3 years)",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 48800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Environment (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Environment (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Environment (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine, Science, Arts & Humanities",
      "CourseName": "Master of Philosophy (Medicine, Health and Human Sciences)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 51900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Conservation Biology (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Conservation Biology (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Conservation Biology (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Graduate Diploma",
      "Field": "Science",
      "CourseName": "Graduate Diploma of Conservation Biology",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine; Science",
      "CourseName": "Bachelor of Clincal Science",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 45900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Banking and Finance (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Science",
      "CourseName": "Master of Banking and Finance (2 years) - with a specialisation in Environmental Finance ",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Banking and Finance (2 years) - with a specialisation in Financial Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Master of Banking and Finance (2 years) - with a specialisation in FinTech",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Research (Arts)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 37100
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Research (Business and Economics)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 34500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science; Engineering & Technology",
      "CourseName": "Master of Research (Science and Engineering)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine; Science",
      "CourseName": "Master of Research (Medicine, Health and Human Sciences) ",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Public Health (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 39400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Public Health (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 39400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Accounting (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Accounting (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Accounting (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Criminology (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Criminology (1.5 years)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Planning (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Planning (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Counter Terrorism (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Counter Terrorism (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Intelligence (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Intelligence (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Security and Strategic Studies (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Security and Strategic Studies (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Doctor of Medicine",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 83700
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Conference Interpreting",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Applied Economics (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Applied Economics (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Applied Economics (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Law",
      "CourseName": "Bachelor of Applied Finance and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 44000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanites; IT & Computing",
      "CourseName": "Bachelor of Arts and Bachelor of Cyber Security",
      "Duration (in Years)": 4.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanites; IT & Computing",
      "CourseName": "Bachelor of Arts and Bachelor of Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40300
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanites; Science",
      "CourseName": "Bachelor of Arts and Bachelor of Linguistics and Language Sciences",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 35000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanites; Health & Medicine; Science",
      "CourseName": "Bachelor of Arts and Bachelor of Medical Sciences",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 40000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Law",
      "CourseName": "Bachelor of Business Administration and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Health & Medicine; IT & Computing",
      "CourseName": "Bachelor of Cognitive and Brain Sciences and Bachelor of Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42300
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Health & Medicine",
      "CourseName": "Bachelor of Cognitive and Brain Sciences and Bachelor of Psychology",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 38900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing; Law",
      "CourseName": "Bachelor of Cyber Security and Bachelor of Laws",
      "Duration (in Years)": 5.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43700
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Bachelor of Economics and Bachelor of Business Analytics",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Law",
      "CourseName": "Bachelor of Environment and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing; Science; Arts & Humanities",
      "CourseName": "Bachelor of Information Technology and Bachelor of Actuarial Studies",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing; Law",
      "CourseName": "Bachelor of Information Technology and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Law",
      "CourseName": "Bachelor of International Studies and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Law",
      "CourseName": "Bachelor of Linguistics and Language Sciences and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 38900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Science; Commerce, Business & Management",
      "CourseName": "Bachelor of Linguistics and Language Sciences and Bachelor of Marketing and Media",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; IT & Computing",
      "CourseName": "Bachelor of Media and Communications and Bachelor of Cyber Security",
      "Duration (in Years)": 4.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Law",
      "CourseName": "Bachelor of Media and Communications and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Science",
      "CourseName": "Bachelor of Media and Communications and Bachelor of Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Health & Medicine",
      "CourseName": "Bachelor of Medical Sciences and Bachelor of Cognitive and Brain Sciences",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Health & Medicine; Law",
      "CourseName": "Bachelor of Medical Sciences and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Law",
      "CourseName": "Bachelor of Professional Accounting and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 44000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine; Law",
      "CourseName": "Bachelor of Psychology and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Health & Medicine",
      "CourseName": "Bachelor of Science and Bachelor of Cognitive and Brain Sciences",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; IT & Computing",
      "CourseName": "Bachelor of Science and Bachelor of Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42300
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Law",
      "CourseName": "Bachelor of Science and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Law; IT & Computing; Commerce, Business & Management",
      "CourseName": "Bachelor of Security Studies and Bachelor of Business Analytics",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Security Studies and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Arts & Humanities; Law",
      "CourseName": "Bachelor of Social Science and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Health & Medicine",
      "CourseName": "Bachelor of Speech and Hearing Science and Bachelor of Psychology",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43100
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Health & Medicine",
      "CourseName": "Bachelor of Cognitive and Brain Sciences",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing",
      "CourseName": "Bachelor of Cyber Security",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing",
      "CourseName": "Bachelor of Game Design and Development",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Arts & Humanities",
      "CourseName": "Bachelor of Linguistic and Language Sciences",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 34200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Media and Communications",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Music",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Professional Accounting",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Psychology",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 40400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Health & Medicine",
      "CourseName": "Bachelor of Speech and Hearing Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 45900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Graduate Diploma",
      "Field": "Commerce, Business & Management",
      "CourseName": "Graduate Diploma of Applied Finance",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 44900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Achitecture & Design",
      "CourseName": "Master of Creative Industries (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Achitecture & Design",
      "CourseName": "Master of Creative Industries (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Achitecture & Design",
      "CourseName": "Master of Creative Industries (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Creative Writing",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Engineering in Electronics Engineering (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Engineering in Electronics Engineering ( 2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology; Commerce, Business & Management",
      "CourseName": "Master of Engineering Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Finance",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Commerce, Business & Management",
      "CourseName": "Master of Information Systems Management (1 year)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Commerce, Business & Management",
      "CourseName": "Master of Information Systems Management (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Information Technology in Cyber Security (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Information Technology in Cyber Security (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Information Technology in Networking (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Information Technology in Networking (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of International Relations",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Marketing (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Professional Accounting (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Professional Accounting (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Professional Accounting (2 years) - with a specialisation in ACCA Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Professional Accounting (2 years) - with a specialisation in CA Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Professional Accounting (2 years) - with a specialisation in CPA Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Primary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 35800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Secondary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 35800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Science; Arts & Humanities",
      "CourseName": "Master of Translation and Interpreting Studies (Advanced)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Media and Communications (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Media and Communications (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Media and Communications (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Cyber Security Analysis (1 year)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Cyber Security Analysis (1.5 years)",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Public and Social Policy",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Arts & Humanities",
      "CourseName": "Bachelor of Science and Bachelor of Actuarial Studies",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Science; Arts & Humanities",
      "CourseName": "Bachelor of Applied Finance and Bachelor of Actuarial Studies",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Bachelor of Business Analytics and Bachelor of Applied Finance",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Economics and Bachelor of Applied Finance",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Applied Finance and Bachelor of Professional Accounting",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Health & Medicine",
      "CourseName": "Bachelor of Business Administration and Bachelor of Psychology",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 39500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Bachelor of Arts and Bachelor of Business and Administration",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 38300
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business Administration and Bachelor of Professional Accounting",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; IT & Computing",
      "CourseName": "Bachelor of Business Analytics and Bachelor of Professional Accounting",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Commerce, Business & Management",
      "CourseName": "Bachelor of Science and Bachelor of Commerce",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts and Bachelor of Education (Primary)",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 37100
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts and Bachelor of Education (Secondary)",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 37100
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Arts & Humanities",
      "CourseName": "Bachelor of Science and Bachelor of Education (Secondary)",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 36900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Law",
      "CourseName": "Bachelor of Arts and Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Law",
      "CourseName": "Bachelor of Arts and Bachelor of Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Arts & Humanities",
      "CourseName": "Master of Commerce and Master of International Relations",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Commerce and Master of Finance",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Finance and Master of International Business",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Finance and Master of Marketing",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Arts & Humanities",
      "CourseName": "Master of International Business and Master of International Relations",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Arts & Humanities",
      "CourseName": "Master of International Business and Master of Media and Communications",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law; Arts & Humanities",
      "CourseName": "Master of Counter Terrorism and Master of International Relations",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Arts & Humanities",
      "CourseName": "Master of Cyber Security Analysis and Master of International Relations",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law; Arts & Humanities",
      "CourseName": "Master of Intelligence and Master of International Relations",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities;  Law",
      "CourseName": "Master of International Relations and Master of Security and Strategic Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Law",
      "CourseName": "Master of Applied Economics and Master of International Trade and Commerce Law",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law; Commerce, Business & Management",
      "CourseName": "Master of Criminology and Master of International Trade and Commerce Law",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Criminology and Master of Laws",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Law",
      "CourseName": "Master of International Business and Master of International Trade and Commerce Law",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law; Commerce, Business & Management",
      "CourseName": "Master of Counter Terrorism and Master of International Trade and Commerce Law",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Commerce, Business & Management; Law",
      "CourseName": "Master of Cyber Security Analysis and Master of International Trade and Commerce Law",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law; Commerce, Business & Management",
      "CourseName": "Master of Intelligence and Master of International Trade and Commerce Law",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Law",
      "CourseName": "Master of International Trade and Commerce Law and Master of Security and Strategic Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Counter Terrorism  and Master of Laws",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Law",
      "CourseName": "Master of Cyber Security Analysis and Master of Laws",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Intelligence and Master of Laws",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Applied Linguistics and TESOL and Master of Translation and Interpreting Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of International Relations and Master of Translation and Interpreting Studies",
      "Duration (in Years)": 2.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 36900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Law",
      "CourseName": "Master of Cyber Security Analysis and Master of Intelligence",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Law",
      "CourseName": "Master of Cyber Security Analysis and Master of Security and Strategic Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Intelligence and Master of Security and Strategic Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law; IT & Computing",
      "CourseName": "Master of Criminology and Master of Cyber Security Analysis",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Criminology and Master of Intelligence",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Criminology and Master of Security and Strategic Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Counter Terrorism and Master of Criminology",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law; IT & Computing",
      "CourseName": "Master of Counter Terrorism and Master of Cyber Security Analysis",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Counter Terrorism and Master of Intelligence",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Counter Terrorism and Master of Security and Strategic Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's ",
      "Field": "Law",
      "CourseName": "Master of Laws and Master of Security and Strategic Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Architecture & Design; Commerce, Business & Management",
      "CourseName": "Master of Creative Industries and Master of Marketing",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of International Business and Master of Marketing",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of International Relations and Master of Marketing",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 42400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Com",
      "CourseName": "Master of Marketing and Master of Media and Communciations",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Architecture & Design; Commerce, Business & Management",
      "CourseName": "Master of Creative Industries and Master of Media and Communications",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 37900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of International Relations and Master of Media and Communications",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 39800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law; Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of Criminology and Master of Public and Social Policy",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 39500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of International Relations and Master of Public and Social Policy",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 39500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law; Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of International Trade and Commerce Law and Master of Public and Social Policy",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 40400
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law; Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of Counter Terrorism and Master of Public and Social Policy",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 39500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of Cyber Security Analysis and Master of Public and Soical Policy",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 39500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Law; Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of Intelligence and Master of Public and Social Policy",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 39500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of Public and Social Policy and Master of Security and Strategic Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 39500
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine; Science",
      "CourseName": "Bachelor of Exercise and Sports Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Information Technology in Artificial Intelligence",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Engineering & Technology",
      "CourseName": "Master of Information Technology in Internet of Things (2 years)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Cmmerce, Business & Management; Health & Medicine",
      "CourseName": "Bachelor of Business Administration and Bachelor of Psychology (Honours)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 39100
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Health & Medicine",
      "CourseName": "Bachelor of Cognitive and Brain Sciences and Bachelor of Psychology (Honours)",
      "Duration (in Years)": 5.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 38600
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Health & Medicine",
      "CourseName": "Bachelor of Commerce and Bachelor of Psychology (Honours)",
      "Duration (in Years)": 5.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 39100
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Engineering & Technology",
      "CourseName": "Bachelor of Environment and Bachelor of Engineering (Honours)",
      "Duration (in Years)": 5.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing; Engineering & Technology",
      "CourseName": "Bachelor of Informtion Technology and Bachelor of Engineering (Honours)",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Law; Engineering & Technology",
      "CourseName": "Bachelor of Laws and Bachelor of Engineering (Honours)",
      "Duration (in Years)": 6.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 43900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Law; Health & Medicine",
      "CourseName": "Bachelor of Laws and Bachelor of Psychology (Honours)",
      "Duration (in Years)": 6.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 39900
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Health & Medicine",
      "CourseName": "Bachelor of Speech and Hearing Science and Bachelor of Psychology (Honours)",
      "Duration (in Years)": 5.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "66; 66",
      "TOEFL": "94; R-24, W-27, L-24, S-23",
      "Fees": 40000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Graduate Diploma",
      "Field": "Commerce, Business & Management",
      "CourseName": "Graduate Diploma of Accounting Practice",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 42300
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 43200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Pre-Master's",
      "Field": "IT & Computing",
      "CourseName": "Masters Qualifying Program in Information Technology (Standard)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 26000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Pre-Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Masters Qualifying Program in Business (Standard)",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 26000
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "MBA",
      "Field": "Commerce, Business & Management",
      "CourseName": "Global Master of Business Administration",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 27200
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Graduate Diploma",
      "Field": "Arts & Humanities",
      "CourseName": "Graduate Diploma of Inclusive and Special Education",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 35800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Graduate Certificate",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Inclusive and Special Education",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 35800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Graduate Diploma",
      "Field": "Arts & Humanities",
      "CourseName": "Graduate Diploma of Inclusive and Special Education",
      "Duration (in Years)": 1,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 35800
    },
    {
      "Country": "Australia",
      "UniversityName": "Macquarie University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Graduate Certificate",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Inclusive and Special Education",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "85; 21",
      "Fees": 35800
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Advanced Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 39000
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Advanced Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 39000
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 39600
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 39600
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 4,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": "39, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 39600
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Bachelor of Arts - Bachelor of Business",
      "Duration (in Years)": 4,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Bachelor of Arts - Bachelor of Business",
      "Duration (in Years)": 4,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Bachelor of Arts - Bachelor of Business",
      "Duration (in Years)": 4,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Law",
      "CourseName": "Bachelor of Arts - Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Law",
      "CourseName": "Bachelor of Arts - Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Law",
      "CourseName": "Bachelor of Arts - Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Science",
      "CourseName": "Bachelor of Arts - Bachelor of Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Science",
      "CourseName": "Bachelor of Arts - Bachelor of Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Biomedical Sciences",
      "Duration (in Years)": 3,
      "Intake": "Jan",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business",
      "Duration (in Years)": 3,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Science",
      "CourseName": "Bachelor of Business and Environmental Science",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Science",
      "CourseName": "Bachelor of Business and Environmental Science",
      "Duration (in Years)": 3,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Law",
      "CourseName": "Bachelor of Business - Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Law",
      "CourseName": "Bachelor of Business - Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Law",
      "CourseName": "Bachelor of Business - Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management; Health & Medicine; Science",
      "CourseName": "Bachelor of Business - Bachelor of Psychological Science",
      "Duration (in Years)": 4,
      "Intake": "Jan",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce",
      "Duration (in Years)": 3,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Dental Surgery",
      "Duration (in Years)": 5,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0, 7.0, 7.0, 6.5",
      "PTE": "65; 65, 65, 65, 58",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 71960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Education (Primary)",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Education (Primary)",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Education (Secondary)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Education (Secondary)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology; IT & Computing",
      "CourseName": "Bachelor of Engineering (Honours) - Bachelor of Information Technology",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology; IT & Computing",
      "CourseName": "Bachelor of Engineering (Honours) - Bachelor of Information Technology",
      "Duration (in Years)": 5,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology; IT & Computing",
      "CourseName": "Bachelor of Engineering (Honours) - Bachelor of Science",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology; IT & Computing",
      "CourseName": "Bachelor of Engineering (Honours) - Bachelor of Science",
      "Duration (in Years)": 5,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Commerce, Business and Management",
      "CourseName": "Bachelor of Environmental Science and Management",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Commerce, Business and Management",
      "CourseName": "Bachelor of Environmental Science and Management",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Geology",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Geology",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing",
      "CourseName": "Bachelor of Information Technology",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing",
      "CourseName": "Bachelor of Information Technology",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing",
      "CourseName": "Bachelor of Information Technology",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing",
      "CourseName": "Bachelor of Information Technology",
      "Duration (in Years)": 3,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Marine Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 43960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Medicine, Bachelor of Surgery",
      "Duration (in Years)": 6,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0, 7.0, 7.0, 6.5",
      "PTE": "65; 65, 65, 65, 58",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 65960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Medicine, Bachelor of Surgery",
      "Duration (in Years)": 6,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0, 7.0, 7.0, 6.5",
      "PTE": "65; 65, 65, 65, 58",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 65960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Nursing Science (Pre-Registration)",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Nursing Science (Pre-Registration)",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Occupational Therapy (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94; 23",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Pharmacy (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94; 23",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Pharmacy (Honours) (Embedded)",
      "Duration (in Years)": 3,
      "Intake": "Jan",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94; 23",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Pharmacy (Honours) (Embedded)",
      "Duration (in Years)": 3,
      "Intake": "Jan",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94; 23",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Phisiotherapy",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94; 23",
      "Fees": 41200
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Planning",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Planning",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine; Science",
      "CourseName": "Bachelor of Psychological Science",
      "Duration (in Years)": 3,
      "Intake": "Jan",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine; Science",
      "CourseName": "Bachelor of Psychological Science",
      "Duration (in Years)": 3,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Science",
      "Duration (in Years)": 3,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Law",
      "CourseName": "Bachelor of Science - Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Science; Law",
      "CourseName": "Bachelor of Science - Bachelor of Laws",
      "Duration (in Years)": 5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Social Work",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Social Work",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities; Health & Medicine",
      "CourseName": "Bachelor of Speech Pathology",
      "Duration (in Years)": 4,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 41200
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Sport and Exercise Science",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Tourism, Hospitality and Events",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Tourism, Hospitality and Events",
      "Duration (in Years)": 3,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Veterinary Science (Honours)",
      "Duration (in Years)": 5,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 65960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Administration (2-Year)",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Administration (2-Year)",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Administration (2-Year)",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Administration - Master of Conflict Management and Resolution",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Administration - Master of Conflict Management and Resolution",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Clinical Exercise Physiology",
      "Duration (in Years)": 1.5,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94; 23",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Conflict Management and Resolution",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Conflict Management and Resolution",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Data Science (Professional)",
      "Duration (in Years)": 2,
      "Intake": "Mar, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Data Science (Professional)",
      "Duration (in Years)": 2,
      "Intake": "Jan, Mar, May, Jul, Sep, Oct",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Education",
      "Duration (in Years)": 1.5,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology; Commerce, Business & Management",
      "CourseName": "Master of Engineering Management",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Engineering (Professional)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Engineering (Professional)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Global Development",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Global Development",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 32960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of Governance and Leadership",
      "Duration (in Years)": 1.5,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business & Management",
      "CourseName": "Master of Governance and Leadership",
      "Duration (in Years)": 1.5,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Health & Medicine",
      "CourseName": "Master of Guidance and Counselling",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management; Health & Medicine",
      "CourseName": "Master of Guidance and Counselling",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Health Professional Education",
      "Duration (in Years)": 3,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94; 23",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Information Technology",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Information Technology",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Information Technology",
      "Duration (in Years)": 2,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Commerce, Business and Management",
      "CourseName": "Master of Information Technology - Master of Business Administration",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Commerce, Business and Management",
      "CourseName": "Master of Information Technology - Master of Business Administration",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Master's",
      "Field": "IT & Computing; Commerce, Business and Management",
      "CourseName": "Master of Information Technology - Master of Business Administration",
      "Duration (in Years)": 2,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business and Management",
      "CourseName": "Master of International Tourism and Hospitality Management",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business and Management",
      "CourseName": "Master of International Tourism and Hospitality Management",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business and Management",
      "CourseName": "Master of International Tourism and Hospitality Management",
      "Duration (in Years)": 2,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business and Management",
      "CourseName": "Master of International Tourism and Hospitality Management - Master of Business Administration",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business and Management",
      "CourseName": "Master of International Tourism and Hospitality Management - Master of Business Administration",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities; Commerce, Business and Management",
      "CourseName": "Master of International Tourism and Hospitality Management - Master of Business Administration",
      "Duration (in Years)": 2,
      "Intake": "Mar, Jul, Nov",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Lifestyle Medicine",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94; 23",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Marine Biology",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 41200
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Science; Health & Medicine",
      "CourseName": "Master of Medical Science",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 41200
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business and Management",
      "CourseName": "Master of Professional Accounting",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 29600
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business and Management",
      "CourseName": "Master of Professional Accounting - Master of Business Administration",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Brisbane",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business and Management",
      "CourseName": "Master of Professional Accounting - Master of Business Administration",
      "Duration (in Years)": 2,
      "Intake": "Jan, May, Sep",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 34960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Professional Psychology",
      "Duration (in Years)": 1,
      "Intake": "Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 41200
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Psychology (Clinical)",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 41200
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Psychology (Clinical)",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 41200
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Public Health",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Public Health",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Public Health and Tropical Medicine",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Public Health and Tropical Medicine",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine; Commerce, Business and Management",
      "CourseName": "Master of Public Health - Master of Business Administration",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine; Commerce, Business and Management",
      "CourseName": "Master of Public Health - Master of Business Administration",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine; Arts & Humanities",
      "CourseName": "Master of Public Health - Master of Global Development",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine; Arts & Humanities",
      "CourseName": "Master of Public Health - Master of Global Development",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 37960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Rehabilitation",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": 43960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Science",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 41200
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Science",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 41200
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Science (Professional)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 41200
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Science (Professional)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 41200
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Social Work (Professional Qualifiying)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Social Work (Professional Qualifiying)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "94; S-23, L&R-24, W-27",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching and Learning (Primary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching and Learning (Primary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching and Learning (Secondary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching and Learning (Secondary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, Jul",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "52; 52",
      "TOEFL": "74; 18",
      "Fees": 30960
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Townsville",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "all",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": "32,960 - 41,200"
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Cairns",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "all",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": "32,960 - 41,200"
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "all",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": "32,960 - 41,200"
    },
    {
      "Country": "Australia",
      "UniversityName": "James Cook University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "all",
      "AcademicRequirements": "successful completion of an equivalent bachelor's degree with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 54",
      "TOEFL": "86; 21",
      "Fees": "32,960 - 41,200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Archaeology",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 32600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 32600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 32600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Global Studies",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 33400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Languages & Linguistics",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 32600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Media and Communication",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 34800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Politics, Philosophy and Economics",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 33800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Visual Arts",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 34400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Archaeology",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 37400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of International Development",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 36800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of International Development",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 36800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of International Relations",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 36800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of International Relations",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 36800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Accounting",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 36600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Accounting",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 36600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Accounting",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 36600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Accounting",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 36600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 36800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 36800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 36800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 36800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business Analaytics",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 37800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 39400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce / Bachelor of Arts",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 39400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce / Bachelor of Biomedicine",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 39400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce / Bachelor of Global Studies",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 39400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce / Bachelor of Health Sciences",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 39400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce / Bachelor of Psycholoical Science",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 39400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce / Bachelor of Science",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 39400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce / Bachelor of Laws (Honours)",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Biotechnology Management",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree with specialization Science, Bioscience, Biotechnology, Pharmacy or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 39800
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Administration",
      "Duration (in Years)": 1.5,
      "Intake": "Jan, Feb, July, Sept, Nov",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 39000
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "City",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Analytics",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 37200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Information Systems",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 37200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Engineering Management",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Extended Professional Accounting",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Extended Professional Accounting",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Extended Professional Accounting",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Financial Analysis",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of International Business",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of International Business",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Logistics and Supply Chain Management",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 41400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Professional Accounting",
      "Duration (in Years)": 1.5,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Professional Accounting",
      "Duration (in Years)": 1.5,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Sydney",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Professional Accounting",
      "Duration (in Years)": 1.5,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Criminology",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 37200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Criminology",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 37200
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Criminology / Bachelor of Psychological Science",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 37600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Criminology / Bachelor of Psychological Science",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 37600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Cybersecurity / Bachelor of Criminology",
      "Duration (in Years)": 4,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 37400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Criminology / Bachelor of Laws (Honours)",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Criminology / Bachelor of Laws (Honours)",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 40600
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Associate Degree of Teacher Education",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "7.5; 7.0",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": 34000
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Albury-Wodonga",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Associate Degree of Teacher Education",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "7.5; 7.0",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": 34000
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Associate Degree of Teacher Education",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "7.5; 7.0",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": 34000
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Mildura",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Associate Degree of Teacher Education",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "7.5; 7.0",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": 34000
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Shepparton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Associate Degree of Teacher Education",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "7.5; 7.0",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": 34000
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Education",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "7.5; 7.0",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": 34000
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Albury-Wodonga",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Education",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "7.5; 7.0",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": 34000
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Education",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "7.5; 7.0",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": 34000
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Mildura",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Education",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "7.5; 7.0",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": 34000
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Shepparton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Education",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "7.5; 7.0",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": 34000
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Outdoor and Sustainability Education",
      "Duration (in Years)": 3,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": 33400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Education",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "34, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Education",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "34, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Primary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0;7.0; 7.5 S/R",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": "34, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Albury-Wodonga",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Primary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0;7.0; 7.5 S/R",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": "34, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Primary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0;7.0; 7.5 S/R",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": "34, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Mildura",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Primary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0;7.0; 7.5 S/R",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": "34, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Shepparton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Primary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0;7.0; 7.5 S/R",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": "34, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Secondary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0;7.0; 7.5 S/R",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": "34, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Albury-Wodonga",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Secondary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0;7.0; 7.5 S/R",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": "34, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Secondary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0;7.0; 7.5 S/R",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": "34, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Mildura",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Secondary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0;7.0; 7.5 S/R",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": "34, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Shepparton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching (Secondary)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0;7.0; 7.5 S/R",
      "PTE": "76; 66",
      "TOEFL": "102; 23",
      "Fees": "34, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Associate Degree in Engineering Technology",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "38, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Associate Degree in Engineering Technology",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 60%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "38, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Civil Engineering (Honours)",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "38, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Civil Engineering (Honours)",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "38, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering Honours (Industrial)",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "38, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering Honours (Industrial)",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "38, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Construction and Engineering Management",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Construction and Engineering Management",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Engineering",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Engineering Management",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Dental Science (Honours)",
      "Duration (in Years)": 5,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "76, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Health Sciences",
      "Duration (in Years)": 3,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 36400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Health Sciences",
      "Duration (in Years)": 3,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 36400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Health Sciences",
      "Duration (in Years)": 3,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": 36400
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Nursing",
      "Duration (in Years)": 3,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Albury-Wodonga",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Nursing",
      "Duration (in Years)": 3,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Nursing",
      "Duration (in Years)": 3,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Mildura",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Nursing",
      "Duration (in Years)": 3,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Shepparton",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Nursing",
      "Duration (in Years)": 3,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Physiotherapy (Honours)",
      "Duration (in Years)": 4,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Physiotherapy (Honours)",
      "Duration (in Years)": 4,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Psychological Science",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "37, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Albury-Wodonga",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Psychological Science",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "37, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Psychological Science",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "37, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Psychological Science",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "37, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Social Work (Honours)",
      "Duration (in Years)": 4,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "34, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Albury-Wodonga",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Social Work (Honours)",
      "Duration (in Years)": 4,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "34, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Social Work (Honours)",
      "Duration (in Years)": 4,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "34, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Mildura",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Social Work (Honours)",
      "Duration (in Years)": 4,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "34, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Shepparton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Social Work (Honours)",
      "Duration (in Years)": 4,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "7.0; 7.0",
      "PTE": "65; 65",
      "TOEFL": "98; 23",
      "Fees": "34, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Health & Medicine",
      "CourseName": "Bachelor of Sport and Exercise Science",
      "Duration (in Years)": 3,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "38, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Digital Health",
      "Duration (in Years)": 2,
      "Intake": "Feb, April, July, Sept, Nov",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "36, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Digital Health",
      "Duration (in Years)": 2,
      "Intake": "Feb, April, July, Sept, Nov",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "36, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "City",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Health Administration",
      "Duration (in Years)": 2,
      "Intake": "Feb, April, July, Sept, Nov",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Health Administration",
      "Duration (in Years)": 2,
      "Intake": "Feb, April, July, Sept, Nov",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Nursing",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "35, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Nursing",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "35, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "City",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Public Health",
      "Duration (in Years)": 2,
      "Intake": "May, Feb, July, Sept, Nov",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "38, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Public Health",
      "Duration (in Years)": 2,
      "Intake": "May, Feb, July, Sept, Nov",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "38, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "City",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Rehabilitation Counselling and Mental Health",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Rehabilitation Counselling and Mental Health",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Strength and Conditioning",
      "Duration (in Years)": 1.5,
      "Intake": "March",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "42, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Computer Science",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "37, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Cybersecurity   ",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "37, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Cybersecurity   ",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "37, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Cybersecurity / Bachelor of Psychological Science",
      "Duration (in Years)": 4,
      "Intake": "March",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "37, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Information Technology",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "37, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Information Technology",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "37, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Information Technology",
      "Duration (in Years)": 3,
      "Intake": "March, July, November",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "37, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Artificial Intelligence",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Artificial Intelligence",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Cybersecurity",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Cybersecurity",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Information and Communication Technology",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "29, 550"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Information and Communication Technology",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "29, 550"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Information Technology",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Information Technology",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Online",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Information Technology",
      "Duration (in Years)": 2,
      "Intake": "March, July, November",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Internet of Things",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)",
      "Duration (in Years)": 4,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Arts",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Arts",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Biomedicine",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Commerce",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Criminology",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Criminology",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Global Studies",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Media and Communication",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Politics, Philosophy and Economics",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Psychological Science",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Psychological Science",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours)/Bachelor of Science",
      "Duration (in Years)": 5,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 90%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "City",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Juris Doctor",
      "Duration (in Years)": 3,
      "Intake": "March",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "42, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Biological Sciences",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Albury-Wodonga",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Biological Sciences",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 65%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Science",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Bendigo",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Science",
      "Duration (in Years)": 3,
      "Intake": "March, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.0; 6.0",
      "PTE": "46; 46",
      "TOEFL": "64; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Biotechnology and Bioinformatics",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Biotechnology Management",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "39, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "La Trobe University",
      "CampusLocation": "Melbourne",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Chemical Sciences",
      "Duration (in Years)": 2,
      "Intake": "March, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "56; 46",
      "TOEFL": "79; 12",
      "Fees": "41, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Accounting",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Banking and Finance",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Finance",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Accounting, Bachelor of Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Banking and Finance, Bachelor of Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Actuarial Science, Bachelor of Commerce",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Arts, Bachelor of Commerce",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Bachelor of Biomedical Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Computer Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Economics",
      "Duration (in Years)": 4,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Finance",
      "Duration (in Years)": 4,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Global Studies",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Music",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Global Executive Master of Business Administration, Master of Business",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "73, 300"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Accounting",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Advanced Finance",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Advanced Finance, Master of Applied Econometrics",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Banking and Finance",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Commerce",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Accounting, Master of Global Business",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Advanced Finance, Master of Global Business",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Advanced Finance, Master of Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Professional Accounting",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Architecture & Design",
      "CourseName": "Bachelor of Architectural Design",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "48, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering (Honours) ",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 82%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering (Honours) ",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 82%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering (Honours) and Bachelor of Architectural Design",
      "Duration (in Years)": 5,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 82%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Architecture & Design",
      "CourseName": "Master of Architecture",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "47, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Architecture & Design",
      "CourseName": "Master of Urban Planning and Design",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "47, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Criminology",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Global Studies",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Politics, Philosophy and Economics",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "44, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Criminology",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts and Bachelor of Fine Arts",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Art and Bachelor of Global Studies",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Health Sciences",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Media Communication",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Music",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Commerce",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Bachelor of Global Studies",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Criminology and Bachelor of Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Global Studies and Bachelor of Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 80%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of International Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Marketing",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Politics, Philosophy and Economics",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "44, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Applied Linguistics",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Arts, Master of Arts (Creative Writing), Master of Arts (Music), Master of Arts (Theatre Performance)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Arts, Master of Arts (Creative Writing), Master of Arts (Music), Master of Arts (Theatre Performance)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Arts Research Training",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Arts Research Training",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Arts Research Training",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Cultural and Creative Industries",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Education",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 700"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Singapore",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Education",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 700"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of International Relations",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of International Relations",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of International Relations, Master of Journalism",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Interpreting and Translation Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Global Public Policy, Master of Public Policy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Public Policy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Banking and Finance",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business Administration",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 66%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "35, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of International Business",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Marketing",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Actuarial Science and Actuarial Studies",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Accounting, Bachelor of Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Arts, Bachelor of Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Banking and Finance, Bachelor of Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business, Bachelor of Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business, Bachelor of International Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business, Bachelor of Marketing",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business, Bachelor of Media Communication",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Actuarial Science, Bachelor of Commerce",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Arts, Bachelor of Commerce",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Biomedical Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Computer Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Economics",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Finance",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Global Studies",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce, Bachelor of Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Music",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce, Bachelor of Politics, Philosophy and Economics",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce, Bachelor of Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Architecture & Design",
      "CourseName": "Bachelor of Design and Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Education (Honours) and Bachelor of Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Fine Art and Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Arts, Bachelor of International Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Arts, Bachelor of Marketing",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Marketing, Bachelor of Media Communication",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Graduate Diploma of Business",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Graduate Diploma of Health Management",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Global Executive Master of Business Administration",
      "Duration (in Years)": 1.5,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "73, 300"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Actuarial Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Advanced Midwifery, Master of Advanced Nursing",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "41, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Advanced Midwifery, Master of Advanced Nursing",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "41, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Applied Econometrics",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Applied Marketing",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Analytics",
      "Duration (in Years)": 1.5,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Information Systems",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Business Information Systems",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Educational Leadership",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 300"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Global Business",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Accounting, Master of Global Business",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Advanced Finance, Master of Global Business",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Applied Econometrics, Master of Global Business",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Applied Marketing, Master of Global Business",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Global Business, Master of Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Global Business, Master of Regulation and Compliance",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Health Management",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Management",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Advanced Finance, Master of Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Applied Marketing, Master of Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Management, Master of Regulation and Compliance",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Regulation and Compliance",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Technology Commercialisation and Business",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "48, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Applied Data Scienc",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Applied Data Scienc",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Applied Data Science Advanced (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Computer Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Computer Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Computer Science (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Computer Science (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Computer Science Advanced (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing",
      "CourseName": "Bachelor of Information Technology",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business, Bachelor of Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Computer Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce, Bachelor of Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and computer science",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws and Computer Science",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Science and Computer Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "50, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Data Science",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Data Science",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Actuarial Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Economics",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of International Business",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Politics, Philosophy and Economics",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "44, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Actuarial Science and Actuarial Studies",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Criminology",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bacehlor of Arts and Fine Art",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Music",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Business, Bachelor of International Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Actuarial Science, Bachelor of Commerce",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Commerce",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Biomedical Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Computer Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Economics",
      "Duration (in Years)": 4,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Finance",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce and Global Studies",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce, Bachelor of Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce, Bachelor of Politics, Philosophy and Economics",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Commerce, Bachelor of Science",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of International Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws and Politics, Philosophy and Economics",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "48, 700"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Marketing",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts, Bachelor of Politics, Philosophy and Economics",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "44, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Graduate Diploma of Economic Analytics",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Global Executive Master of Business Administration, Master of Business",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "73, 300"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Actuarial Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Economics",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Public Health, (Epidemiology), (Global and Planetary Health), (Health Economics), (Health Policy), (Health Promotion), (Research)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Architecture & Design",
      "CourseName": "Bachelor of Design (Communication Design, Industrial Design, Spatial Design or Collaborative Design)",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 66%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering (Honours) (Aerospace, Biomedical, Chemical, Civil, Electrical and Computer Systems, Environmental, Materials, Mechanical, Robotics and Mechatronics, Software Engineering)",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering (Honours) (Aerospace, Biomedical, Chemical, Civil, Electrical and Computer Systems, Environmental, Materials, Mechanical, Robotics and Mechatronics, Software Engineering)",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and Architectural Design",
      "Duration (in Years)": 5,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and Arts",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and Biomedical science",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and Commerce",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and computer science",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and Design",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and Information Technology",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and Pharmaceutical science",
      "Duration (in Years)": 5,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and Science ",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws and Engineering",
      "Duration (in Years)": 6,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Biotechnology",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "48, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Engineering",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "55, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Professional Chemical Engineering,  Civil Engineering, Electrical Engineering, Materials Engineering, Mechanical Engineering",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Engineering & Technology",
      "CourseName": "Master of Technology Commercialisation and Business",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "48, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Architecture & Design",
      "CourseName": "Master of Transport and Mobility Planning",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "48, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Graduate Diploma of Occupational and Environmental Health",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 50%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "49, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Environment and Sustainability",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "51, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Food Science and Agribusiness",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "51, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Green Chemistry and Sustainable Technologies",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "51, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of International Development Practice",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of International Development Practice",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of International Sustainable Tourism Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "41, 700"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Occupational and Environmental Health",
      "Duration (in Years)": 1.5,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 50%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Global Public Policy, Master of Public Policy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Architecture & Design",
      "CourseName": "Master of Transport and Mobility Planning",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Architecture & Design",
      "CourseName": "Bachelor of Design",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 66%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Fine Art",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 66%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "38, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Fine Art (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "38, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts and Fine Art",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Architecture & Design",
      "CourseName": "Bachelor of Design and Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 72%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Architecture & Design",
      "CourseName": "Bachelor of Design and Media Communication",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "41, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Education (Honours) and Bachelor of Fine Art",
      "Duration (in Years)": 4.25,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 300"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and Architectural Design",
      "Duration (in Years)": 5,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Engineering & Technology",
      "CourseName": "Bachelor of Engineering and Design",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 81%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Fine Art and Business",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Fine Art and Information Technology",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 72%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Fine Art and Media Communication",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Arts",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Architecture & Design",
      "CourseName": "Master of Design",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "42, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Fine Art",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Biomedical Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Biomedical Science (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "49, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Health Sciences",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 66%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Health Sciences (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Medical Science (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "68, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Medical Science (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "68, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Nursing",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 67%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "68, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Nursing",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 67%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "68, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Nursing (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "41, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Nursing (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "41, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Nutrition Science, Bachelor of Nutrition Science (Scholars Program)",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "44, 300"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Occupational Therapy",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 700"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Paramedicine",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 67%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Paramedicine (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Pharmacy (Honours), Bachelor of Pharmacy (Honours) (Scholars Program)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Parkville",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Pharmacy (Honours), Bachelor of Pharmacy (Honours) (Scholars Program)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Physiotherapy (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "57, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Psychology (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "44, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Psychology (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "44, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Public Health",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 67%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Radiography and Medical Imaging (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "54, 300"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Advanced Midwifery, Master of Advanced Nursing",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "41, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Advanced Midwifery, Master of Advanced Nursing",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "41, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Biomedical and Health Science",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "48, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Clinical Embryology",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 50%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "50, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Clinical Research, Master of Clinical Research (Translational Medicine)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Monash University Law",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Forensic and Legal Studies",
      "Duration (in Years)": 5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "51, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Health Data Analytics",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Health Management",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 65%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Nutrition and Dietetics",
      "Duration (in Years)": 2,
      "Intake": "July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 70%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "52, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Occupational and Environmental Health",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "33, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Occupational Therapy Practice",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 70%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "55, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Public Health, (Epidemiology), (Global and Planetary Health), (Health Economics), (Health Policy), (Health Promotion), (Research)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Social Work",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "39, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Technology Commercialisation and Business",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "48, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Applied Data Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Oct",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Applied Data Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Oct",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Applied Data Science Advanced (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "53, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Computer Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Computer Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "IT & Computing",
      "CourseName": "Bachelor of Information Technology",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Artificial Intelligence",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Artificial Intelligence",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Business Information Systems",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Business Information Systems",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Cybersecurity",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Data Science",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Health Data Analytics",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Information Technology",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "IT & Computing",
      "CourseName": "Master of Technology Commercialisation and Business",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "48, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Law",
      "CourseName": "Bachelor of Laws (Honours) ",
      "Duration (in Years)": 4.25,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "48, 700"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Monash University Law",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Juris Doctor",
      "Duration (in Years)": 3,
      "Intake": "Jan, May, Aug",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "46, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Monash University Law",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Forensic and Legal Studies",
      "Duration (in Years)": 1.5,
      "Intake": "Jan, May, Aug",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 50%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": 51500
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Monash University Law",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Law",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": 51500
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Monash University Law",
      "CourseLevel": "Master's",
      "Field": "Law",
      "CourseName": "Master of Legal Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": 51500
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Philosophy",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 100"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Social Work",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 50%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "39, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Arts (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Bachelor of Marketing",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "52, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Media Communication",
      "Duration (in Years)": 3,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Arts, (Creative Writing), (Music), (Theatre Performance)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Arts, (Creative Writing), (Music), (Theatre Performance)",
      "Duration (in Years)": 2,
      "Intake": "Feb, July, Nov",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Arts Research Training",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Arts Research Training",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Arts Research Training",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Communications and Media Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Communications and Media Studies",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of International Relations, Master of Journalism",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Journalism",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Caulfield",
      "CourseLevel": "Master's",
      "Field": "Commerce, Business & Management",
      "CourseName": "Master of Strategic Communications Management",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Biomedical Science",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "50, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Biotechnology",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "48, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Clinical Embryology",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "50, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Monash University Law",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Forensic and Legal Studies",
      "Duration (in Years)": 1.5,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "51, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Genome Analytics",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "51, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Health Data Analytics",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Nursing Practice",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "43, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Surgery",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "50, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Alfred Hospital",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Surgery",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "50, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Box Hill",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Surgery",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "50, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Surgery",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "50, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Monash University Medical Centre",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Surgery",
      "Duration (in Years)": 1.5,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "50, 800"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Music",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 66%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "38, 700"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Music (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "38, 700"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Cultural and Creative Industries",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Parkville",
      "CourseLevel": "Master's",
      "Field": "Health & Medicine",
      "CourseName": "Master of Pharmaceutical Science",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "51, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Bioethics",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 200"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Applied Behaviour Analysis",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 700"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Science",
      "CourseName": "Master of Educational and Developmental Psychology",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "42, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Malaysia",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Pharmaceutical Science",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Parkville",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Pharmaceutical Science",
      "Duration (in Years)": 3,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 78%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "49, 600"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Science",
      "Duration (in Years)": 3,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 75%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "50, 400"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Science Advanced - Global Challenges (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 83%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "55, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Science",
      "CourseName": "Bachelor of Science Advanced - Research (Honours)",
      "Duration (in Years)": 4,
      "Intake": "Feb, July",
      "AcademicRequirements": "Successful completion of year 12 with minimum 85%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "51, 500"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Bachelor's",
      "Field": "Arts & Humanities",
      "CourseName": "Bachelor of Education (Honours)",
      "Duration (in Years)": 1,
      "Intake": "Feb",
      "AcademicRequirements": "Successful completion of year 12 with minimum 70%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 300"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Applied Behaviour Analysis",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 700"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Education",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "37, 700"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Educational and Developmental Psychology",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 70%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "42, 900"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Educational Leadership",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "39, 300"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "37, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Peninsula",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of Teaching",
      "Duration (in Years)": 2,
      "Intake": "Feb",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "7.0; 6.5",
      "PTE": "65; 58",
      "TOEFL": "94;19",
      "Fees": "37, 000"
    },
    {
      "Country": "Australia",
      "UniversityName": "Monash University",
      "CampusLocation": "Clayton",
      "CourseLevel": "Master's",
      "Field": "Arts & Humanities",
      "CourseName": "Master of TESOL",
      "Duration (in Years)": 2,
      "Intake": "Feb, July",
      "AcademicRequirements": "Bachelor degree or equivalent in same field with minimum 60%",
      "IELTS": "6.5; 6.0",
      "PTE": "58; 50",
      "TOEFL": "79; 12",
      "Fees": "40, 400"
    }
  ];
  export default courses;