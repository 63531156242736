import React from 'react';
import Slider from 'react-slick';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

const items = [
    {
        id:"1",
        title: 'University Of Manchester',
        numberOfCourses: '45 Courses',
        image: 'https://img.freepik.com/free-icon/shield-with-leaves_318-63691.jpg?size=338&ext=jpg&ga=GA1.2.1969068513.1678876008&semt=ais',
        shape: 'shape-bg-1',
        link: '#',
        score: '6',
        ranking: '28'
    },
    { 
        id:"2",
        title: 'University Of WarWick',
        numberOfCourses: '23 Courses',
        image: 'https://img.freepik.com/free-icon/group-users-with-wreath-border-silhouette_318-37692.jpg?size=338&ext=jpg&ga=GA1.2.1969068513.1678876008&semt=ais',
        shape: 'shape-bg-2',
        link: '#',
        score: '6',
        ranking: '28'
    },
    {
        id:"3",
        title: 'University Of St Andrews',
        numberOfCourses: '39 Courses',
        image: 'https://cdn-icons-png.flaticon.com/512/393/393553.png?w=826&t=st=1679289007~exp=1679289607~hmac=eb9c82348d73d111e1ab207653a5b96cf7be314996af741c1faa6289a5da5a87',
        shape: 'shape-bg-3',
        link: '#',
        score: '6',
        ranking: '28'
    },
    {
        id:"4",
        title: 'University  Of GlasGow',
        numberOfCourses: '25 Courses',
        image: 'https://img.freepik.com/free-icon/south-korea_318-683291.jpg?size=338&ext=jpg&ga=GA1.2.1969068513.1678876008&semt=ais',
        shape: 'shape-bg-4',
        link: '#',
        score: '6',
        ranking: '28'
    },
    {
        id:"5",
        title: 'University Of St Andrews',
        numberOfCourses: '30 Courses',
        image: 'https://img.freepik.com/free-icon/learning_318-912578.jpg?size=338&ext=jpg&ga=GA1.2.1969068513.1678876008&semt=ais',
        shape: 'shape-bg-5',
        link: '#',
        score: '6',
        ranking: '28'
    },
    {
        id:"6",
        title: 'University Of St Andrews',
        numberOfCourses: '15 Courses',
        image: 'https://img.freepik.com/free-icon/graduation_318-318570.jpg?size=338&ext=jpg&ga=GA1.2.1969068513.1678876008&semt=ais',
        shape: 'shape-bg-3',
        link: '#',
        score: '6',
        ranking: '28'
    }
];

const PrevArrow = (props) => {
    const { onClick } = props;
    return <button className="slide-arrow prev-arrow" onClick={onClick}><i className="icon-arrow-left-line"></i></button>
}

const NextArrow = (props) => {
    const { onClick } = props;
    return <button className="slide-arrow next-arrow" onClick={onClick}><i className="icon-arrow-right-line"></i></button>
}

const TopUniversitySlider = ({ wrapperClass, itemClass ,university }) => {
 console.log(university);
    const sliderSettings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 577,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (

        <div id="tipp1" style={{ fontWeight: "bolder", fontSize: "40px", color: "black", marginTop: "100px" }}>
            TOP UNIVERSITY
            <Slider
                className={wrapperClass || 'col-lg-12 mt--60 mb_dec--20 slick-activation-wrapper service-activation-item5 edu-slick-arrow-top'}
                {...sliderSettings}
            >
                {university.map((data, i) => (
                    
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        className={itemClass || 'single-slick-card'}
                        animateOnce={true}
                        key={i}
                    >
                        <div className={`service-card service-card-8 ${data
                            .shape}`}>
                            <div className="inner">
                                <div className="icon">
                                    <a href={data.link}>
                                        <img src={`${process.env.PUBLIC_URL}${data.image}`} alt="Category Thumb" />
                                    </a>
                                </div>
                                <div className="content">
                                    <h6 className="title"><a href={data.link}>{data.title}</a></h6>
                                    <h6 style={{ fontSize: "14px" }}><a href={data.link}>Minimum IELTS Score:-{data.score} bands</a></h6>
                                    <h6 style={{ fontSize: "14px" }}><a href={data.link}>Ranking:-{data.ranking}</a></h6>

                                    {/* <span>{ data.numberOfCourses }</span> */}


                                </div>
                                <div className="hover-action">
                                    <a className="read-more-btn">
                                        <Link to={process.env.PUBLIC_URL + `/Universities/${data.uniName}`} state={{ data: data }}>
                                        <i className="icon-arrow-right-line-right">
                                        </i>
                                        </Link>
                                      
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                ))}
            </Slider>
        </div>
    )
}

export default TopUniversitySlider;