import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ParallaxProvider } from "react-scroll-parallax";
import { Provider } from "react-redux";
import { store }  from './StudentApplicationFlow/AddnewStudentApplication/store/store.js';
import { StyledEngineProvider } from "@mui/material";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Provider store={store}>
      <ParallaxProvider>
        <StyledEngineProvider injectFirst>
        <App />
        </StyledEngineProvider>
      </ParallaxProvider>
    </Provider>
  </StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
