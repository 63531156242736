import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';
import  TestPrep from '../../sections/TestPrep.json'
const FooterOne = () => {
    return (
        <>
            <footer className="eduvibe-footer-one edu-footer footer-style-default">
           
                <div className="footer-top">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="edu-footer-widget">
                                    <div className="logo">
                                        <Link to={process.env.PUBLIC_URL + "/"}>
                                            <img className="logo-light" src="/images/logo/msa_logo.png" alt="Footer Logo" style={{ width : "130px" }}/>
                                        </Link>
                                    </div>
                                    <p className="description" style={{fontSize:"19px"}}>End-to-end support for your study abroad journey</p>
                                    <ul className="social-share">
                                        <li><a href="#"><i className="icon-Fb"></i></a></li>
                                        <li><a href="#"><i className="icon-linkedin"></i></a></li>
                                        <li><a href="#"><i className="icon-Pinterest"></i></a></li>
                                        <li><a href="#"><i className="icon-Twitter"></i></a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget explore-widget">
                                    <h5 className="widget-title">Study-Abroad</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to="/Destination/UK"><i className="icon-Double-arrow"></i>Study in UK</Link></li>
                                            <li><Link to="/Destination/Australia"><i className="icon-Double-arrow"></i>Study in Australia</Link></li>
                                            <li><Link to="/Destination/Canada"><i className="icon-Double-arrow"></i>Study in Canada</Link></li>
                                            <li><Link to="/Destination/NewZealand"><i className="icon-Double-arrow"></i>Study in NewZealand</Link></li>
                                            <li><Link to="/Destination/USA"><i className="icon-Double-arrow"></i>Study in USA</Link></li>
                                            <li><Link to="/Destination/Germany"><i className="icon-Double-arrow"></i>Study in Germany</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                                 
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget quick-link-widget">
                                    <h5 className="widget-title">TestPrep</h5>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><Link to={process.env.PUBLIC_URL + `/IELTS`}><i className="icon-Double-arrow"></i>IELTS</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/GMAT`}><i className="icon-Double-arrow"></i>GMAT</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/GRE`}><i className="icon-Double-arrow"></i>GRE</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/SAT`}><i className="icon-Double-arrow"></i>SAT</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/TOEFL`}><i className="icon-Double-arrow"></i>TOEFL</Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/PTE`}><i className="icon-Double-arrow"></i>PTE</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="edu-footer-widget">
                                    <h5 className="widget-title">Contact Info</h5>
                                    <div className="inner">
                                        <div className="widget-information">
                                            <ul className="information-list">
                                                <li><i className="icon-map-pin-line"></i>275 Quadra Street Victoria Road, India
                                                </li>
                                                <li><i className="icon-phone-fill"></i><a href="tel: + 1 (237) 382-2839">+ 91 7898456521</a></li>
                                                <li><i className="icon-phone-fill"></i><a href="tel: + 1 (237) 382-2840">+ 91 7845961236</a></li>
                                                <li><i className="icon-mail-line-2"></i><a target="_blank" href="mailto:yourmailaddress@example.com">msatoabroad@gmail.com.com</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>
     
                <div className="copyright-area copyright-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <p>Copyright 2023 Designed By <a href="http://www.myrestica.com/">Myrestica Technologies Pvt. Ltd</a>. All Rights
                                        Reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTopButton />
        </>
    )
}

export default FooterOne;