import {React, useState} from 'react';
import { Link } from 'react-router-dom';

const BreadcrumbOne = ({ title , rootUrl , parentUrl, currentUrl,description,btn}) => {

    const [noMore, setNoMore] = useState( false ); 
    

    const handleLoadMoreBtn = (e) => {
        e.preventDefault();
        setNoMore(!noMore)
        console.log("Read more button click!");
        console.log(noMore);
        //document.getElementsByClassName('.edu-btn').dangerouslySetInnerHTML

    }
    return (
        <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="breadcrumb-inner text-start">
                            <div className="page-title">
                                <h3 className="title" dangerouslySetInnerHTML={{__html: title}}></h3>
                            </div>
                            <nav className="edu-breadcrumb-nav">
                                <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                                    <li className="breadcrumb-item"><Link to={`${rootUrl}`} dangerouslySetInnerHTML={{__html: parentUrl}}></Link></li>
                                    <li className="separator"><i className="ri-arrow-drop-right-line"></i></li>
                                    <li className="breadcrumb-item active" dangerouslySetInnerHTML={{__html: currentUrl}}></li>
                                </ol>
                            </nav>
                            {description &&
                            <div className='edu-breadcrumb-nav'>
                                <p dangerouslySetInnerHTML={{__html: description}}></p>
                            </div>
                            }
                        </div>
                    </div>
                    {noMore && 
                        <div>
                        <table>
                            <tbody>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Number of universities in Canada</span></td><td class="ck-editor__editable ck-				editor__nested-editable" contenteditable="true"><span>233</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Number of international students in Canada</span></td><td class="ck-editor__editable ck-		editor__nested-editable" contenteditable="true"><span>6,21,565</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Average cost of study in Canada</span></td><td class="ck-editor__editable ck-				editor__nested-editable" contenteditable="true"><span>CAD 20K to CAD 30K (INR 1.2 lacs- 1.8 lacs)</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Average cost of living in Canada</span></td><td class="ck-editor__editable ck-				editor__nested-editable" contenteditable="true"><span>CAD 20K to CAD 25K Annually (INR 1.2 lacs- 1.5 lacs)</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Top courses to study in Canada</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>MBA, Hospitality, Pharmacy, Finance, Information Technology</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Intakes in the Canada</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><p>Fall (September)</p><p>Winter (January)</p><p>February (April-May)</p></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Top universities in Canada for MBA</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>University of Western Ontario, Schulich School of Business- York University, University of 		Toronto, McGill University, Queen’s University</span></td>
                            </tr>
                            </tbody>
                        </table>

                        <h4>Why Study in USA?</h4>
                        <p>The growing number of students studying in Canada shows that Canada is attracting the&nbsp; 
                            attention of a maximum number of international students. Over the last decade number of 
                            <a href="https://leverageedu.com/blog/best-forex-cards-for-students/">international</a> 
                            students moving to Canada has increased dramatically. There are the top 5 reasons to study in Canada mentioned below:
                        </p>
                        <ul>
                            <li>Canada is home to the world’s best academic institutions as per the latest world universities ranking.</li>
                            <li>&nbsp;Canada is a bilingual country people speak both<a href="https://leverageedu.com/blog/toeic-test/"> English</a> and <a href="https://leverageedu.com/blog/french-education-system/">French</a> throughout the country.&nbsp;</li>
                            <li>International students get good immigration opportunities after graduation.</li>
                            <li>&nbsp;Canada offers a healthy, safe and vibrant environment to the students.&nbsp;</li>
                            <li>Canada is known for having some of the lowest university tuition fees among English-speaking countries. Whatever your budget may be, you’ll find something just right for you in our diverse range of education options.</li>
                        </ul>

                        <h4>Intakes to Study in Canada</h4>
                        <p>Canadian universities offer admission opportunities to international students through three popular admission cycles or 
                            <a href="https://leverageedu.com/blog/february-intake-in-canada/">intakes</a>. 
                            These are Fall Intake, Winter Intake, and Summer Intake. The Fall intake or Major Intake is the most popular among applicants. 
                            Maximum universities with hundreds of courses available for students during fall intake. Fall intake is followed by Winter and Summer intake.
                            <a href="https://leverageedu.com/blog/summer-intake-in-canada/"> Summer intake</a> is the least popular among students because of fewer admission options.
                        </p>

                        <table>
                            <tbody>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Intakes&nbsp;</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Application Begin</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Classes Start</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Fall</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>June</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>September</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Winter</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>November</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>January</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Summer</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>February</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>April-May</span></td>
                            </tr>
                            </tbody>
                        </table>

                        <h4>Top Universities in Canada in 2023</h4>
                        <p>Canada is the home to the world’s best academic institutions. Here is the list of top universities in 
                            <a href="https://leverageedu.com/blog/mba-courses-in-canada/">Canada</a> as per QS world universities ranking 2023:
                        </p>
                        <table>
                            <tbody>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Name of Institutions</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>World University Ranking 2023</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>McGill University</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>#31</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>University of Toronto</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>#34</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>University of British Columbia</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>#47</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>University of Alberta</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>#110</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Universite de Montreal</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>#116</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>McMaster University</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>#152</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>University of Waterloo</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>#154</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Western University</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>#172</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>University of Ottawa</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>#237</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>University of Calgary</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>#242</span></td>
                            </tr>
                            </tbody>
                        </table>

                        <h4>Top Universities in Canada for Bachelors</h4>
                        <p>Top universities in Canada to study bachelor degree program are mentioned below:</p>
                        <ul>
                            <li>Kings University&nbsp;</li>
                            <li>Lakehead University</li>
                            <li>Brock University</li>
                            <li>The University of Winnipeg</li>
                            <li>University of Alberta</li>
                            <li>Trent University</li>
                            <li>The University of Winnipeg</li>
                            <li>Queens University</li>
                            <li><a href="https://leverageedu.com/blog/university-of-waterloo-computer-science/">University of Waterloo</a></li>
                            <li>University of Calgary</li>
                        </ul>

                        <h4>Top Universities in Canada for Masters</h4>
                        <p>Top universities in Canada to study master degree program are mentioned below:</p>
                        <ul>
                            <li>University of British Columbia</li>
                            <li>University of Toronto</li>
                            <li>McGill University</li>
                            <li><a href="https://leverageedu.com/blog/university-of-alberta-acceptance-rate/">University of Alberta</a></li>
                            <li>University of Waterloo</li><li>University of Ottawa</li>
                            <li>University of Calgary</li>
                            <li>Universite de Montreal</li>
                            <li>Queen’s University</li>
                            <li>Thompson Rivers University</li>
                        </ul>

                        <h4>Top Public Universities in Canada</h4>
                        <p>Public universities and colleges in Canada are less expensive than private institutions. Here is the list of the top public universities in Canada:</p>
                        <ul>
                            <li>University of Toronto</li>
                            <li>The University of British Columbia</li>
                            <li>University of Waterloo</li>
                            <li><a href="https://leverageedu.com/blog/mcgill-university-courses/">McGill University</a></li>
                            <li>York University</li>
                            <li>Thompson Rivers University</li>
                            <li>Vancouver Island University</li>
                            <li>University of Montreal</li>
                            <li>University of Alberta</li>
                            <li>University of Calgary</li>
                        </ul>

                        <h4>Top Courses Offered by Universities in Canada</h4>
                        <p>Education opportunities for students are limitless in Canada. Out of hundreds of courses offered by Canadian universities, there are some top courses considered as first choice among students.</p>
                        <ul>
                            <li><a href="https://leverageedu.com/blog/types-of-mba-in-canada/">Master of Business Administration</a></li>
                            <li>Computer Science and IT</li>
                            <li>Core Engineering and Engineering Management</li>
                            <li>Business and Finance</li>
                            <li>Media and Journalism</li>
                            <li>Medicine and Healthcare</li>
                            <li>Agricultural Science and Forestry</li>
                            <li>Architecture</li><li>Maths &amp; Statistics</li>
                            <li><a href="https://leverageedu.com/blog/colleges-for-psychology/">Psychology</a></li>
                            <li>Human Resources</li>
                        </ul>

                        <h4>Top Student Cities in Canada</h4>
                        <p>Canada value essential services like education and healthcare. This is the main reason there are colleges in every city in Canada. Here is the list of the top student cities in Canada:&nbsp;</p>
                        <ul>
                            <li><a href="https://leverageedu.com/blog/university-of-toronto-courses/">Toronto</a></li>
                            <li>Montreal</li>
                            <li>Vancouver</li>
                            <li>Ottawa</li>
                            <li><a href="https://leverageedu.com/blog/universities-in-quebec/">Quebec</a></li>
                            <li>Edmonton</li>
                        </ul>

                        <h4>Admission Process for Top Universities in Canada</h4>
                        <p>To study in Canada international students, admission requirements vary among courses and colleges/ universities.&nbsp; The basic general academic and non-academic requirements should be fulfilled before applying for admission.</p>
                        <h5>Eligibility Criteria</h5>
                        <ul>
                            <li>To apply for <a href="https://leverageedu.com/blog/ugat/">undergraduate</a> courses applicants must pass higher secondary with 50-60% from a recognised education board.</li>
                            <li>To apply for post graduate program applicants must pass a bachelor degree with 50-60% from a recognized university.</li>
                        </ul>

                        <h4>Documents Required</h4>
                        <ul>
                            <li>Proof of language proficiency</li>
                            <li><a href="https://leverageedu.com/blog/student-passport-in-india/">Scanned copy of passport</a></li>
                            <li><a href="https://leverageedu.com/blog/sop-statement-of-purpose/">Statement of Purpose</a></li>
                            <li><a href="https://leverageedu.com/blog/is-lors-important-for-study-abroad-application/">Letter of Recommendation</a></li>
                            <li>An updated CV/<a href="https://leverageedu.com/blog/top-technical-skills-to-enhance-your-resume/"> Resume</a></li>
                            <li>Certificates of Extracurricular activities/merits (if required)</li><li>Proof of sufficient funds to study in Canada</li>
                        </ul>

                        <h4>English Language Score Requirements</h4>
                        <p>Canadian academic institutions accept test scores of IELTS, TOEFL and PTE:</p>
                        <table>
                            <tbody>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>English Proficiency Exam</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Minimum Score Required</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span><a href="https://leverageedu.com/blog/ielts-letter-writing-samples/">IELTS</a></span></td>		<td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Overall 6.5 out of 9 with no band below 6.0</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span><a href="https://leverageedu.com/blog/pte/">PTE</a></span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>60-65</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span><a href="https://leverageedu.com/blog/toefl-exam-pattern/">TOEFL iBT</a></span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Overall 90</span></td>
                            </tr>
                            </tbody>
                        </table>

                        <h4>Application Process</h4>
                        <p>Admission process to get enrolled into a university requires following a certain step-by-step procedure to ensure that the applicant doesn’t 
                            miss anything while submitting an application. The following pointers elaborate upon the major steps you should keep in mind while planning to study in Canada:
                        </p>
                        <ul>
                            <li>Find a Suitable Course and University</li>
                        </ul>
                        <p>
                            <br/>Thoroughly research the available diploma and degree programs in your field of interest as well as the universities offering them. Canada is globally famous for its prospective courses in varied specialisations, be it 
                            <a href="https://leverageedu.com/blog/syllabus-of-data-science/">Science</a>, Technology, Humanities or Commerce.
                        </p>
                        <ul>
                            <li>Get the Paperwork Ready</li>
                        </ul>
                        <p>
                            <br/>Applying to study in Canada can be a lengthy procedure and it’s important to start your application as early as possible. 
                            Some of the basic application essentials include transcripts and mark sheets for your academic records, language proficiency scores 
                            (<a href="https://leverageedu.com/blog/ielts-score-for-canada/">IELTS Score for Canada</a>), letters of motivation, letters of recommendation 
                            (<a href="https://leverageedu.com/blog/lor/">LOR</a>), and Statement of Purpose (<a href="https://leverageedu.com/blog/sop/">SOP</a>), 
                            amongst others. We have also given a rundown of the different documents you need in the next section.
                        </p>
                        <ul>
                            <li>Familiarize yourself with Admission Requirements</li>
                        </ul>
                        <p>Once you have selected the right course, check its admission requirements given on the university’s official website. 
                            Some of the prominent things you need to ensure are academic-related specifications, criteria related to 
                            <a href="https://leverageedu.com/blog/how-to-calculate-work-experience/">work experience</a>, information about the intake, etc.&nbsp;
                        </p>
                        <ul>
                            <li>Filling the Application Form &amp; Submission</li>
                        </ul>
                        <p>Major universities in <a href="https://leverageedu.com/blog/canada-student-visa-for-indians/">Canada</a> conduct the application procedure through an 
                            online medium where the candidates need to fill their personal and academic details, submit the necessary documents as well as the required application fee. 
                            Make sure that you carefully add all your details and go through them once again before the final submission.&nbsp;
                        </p>
                        <ul>
                            <li>Gear Up for Visa Application</li>
                        </ul>
                        <p>
                            <br/>Once your candidature has been successfully accepted by the university, you can start preparing your visa application. 
                            It generally needs around 60 days as well as demands many documents, so it becomes important that you commence your visa application as 
                            early as you can.
                        </p>


                        <h4>Cost of Studying in Canada</h4>
                        <p>The total cost of studying in <a href="https://leverageedu.com/blog/universities-in-canada-with-no-application-fees/">Canada</a> 
                            is mainly constituted by different factors such as the selected course, the living standard of the city and accommodation facilities etc.
                        </p>

                        <h4>Cost of Education in Canada</h4>
                        <table>
                            <tbody>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Study Program</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Average Annual Fee (CAD)</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Average Annual Fee (INR)</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Undergraduate Degree</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>13K to 20K</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>8-12 Lakhs</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Postgraduate Degree</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>17K to 25K</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>10-15 Lakhs</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>MBA Program</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>30K to 40K</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>18-24 Lakhs</span></td>
                            </tr>
                            <tr>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>Doctoral Degree</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>7K to 15K</span></td>
                                <td class="ck-editor__editable ck-editor__nested-editable" contenteditable="true"><span>4-9 Lakhs</span></td>
                            </tr>
                            </tbody>
                        </table>

                        <h4>Cost of Living in Canada</h4>
                        <p>The cost of living can vary from city to city like off-campus <a href="https://leverageedu.com/blog/on-campus-vs-off-campus/">accommodation </a>charges travel cost etc.</p>
                        <ul>
                            <li>On-Campus Accommodation: It varies from one university to another</li>
                            <li>Off-Campus Accommodation: The average cost of off-campus accommodation per month is around CAD 400 - CAD 3000 per month (INR 24K- 1 lakh).</li>
                            <li>Travel Costs: The average cost of transportation in the Canadian city range from CAD 80 - CAD 110 per month (INR 4K-6K)&nbsp;</li>
                        </ul>

                        <h4>Scholarships at Top Universities in Canada</h4>
                        <p>For undergraduate and postgraduate students there are numerous 
                            <a href="https://leverageedu.com/blog/leverage-edu-scholarship/">scholarships</a> offered by both the Canadian government and Canadian universities. 
                            Some of the top <a href="https://leverageedu.com/blog/medical-scholarships-in-canada-for-international-students/">scholarships</a> 
                            offered to students are mentioned below:
                        </p>
                        <ul>
                            <li>York University - International Students Scholarship</li>
                            <li>University of Waterloo Graduate Scholarship</li>
                            <li>University of Calgary International Entrance Scholarship</li>
                            <li>Ontario Graduate Scholarship</li>
                            <li>Canadian Commonwealth Scholarship and Fellowship Plan</li>
                            <li>Vanier Canada Graduate Scholarship</li>
                            <li>Shastri Indo- Canadian Institute Scholarship</li>
                            <li>Quebec Provincial Government Scholarship</li>
                            <li>University of Toronto International Scholarship Program</li>
                            <li>Ontario Trillium Scholarship</li>
                        </ul>

                        <h4>FAQs</h4>
                        <p>Q What is the minimum account balance required for a Canadian Student Visa?</p>
                        <p>To study in Canada, students have to give proof of funds. Currently, the minimum bank balance which you need to have is CAD 10,000 [INR&nbsp; 5.40 lacs]. 
                            Furthermore, to study in Quebec, you need to have a minimum of CAD 11,000 [Rs 5.96 lacs].
                        </p>
                        <p>Q Are medical examinations required to study in Canada?&nbsp;</p>
                        <p>A medical examination might be compulsory in case you are planning to pursue certain kinds of courses in which case the university will 
                            specify the same or plan to take up a job that requires a medical examination. In this scenario, the medical examination will be conducted 
                            before issuing a visa for Canada. Courses that last 6 months or less, do not require a medical examination of any kind.
                        </p>
                        <p>Q Is it possible to study in Canada without IELTS?</p>
                        <p>Canadian universities adopt varied factors to assess the English language proficiency of the candidates seeking admission. 
                            It is possible to study in Canada without IELTS given that you qualify for the alternative language proficiency requirements specified by your 
                            chosen university. Further, Canadian academic institutions also readily accept English language proficiency tests other than IELTS which can you 
                            can submit during the admission process.
                        </p>
                        <p><br data-cke-filler="true"/></p>

                        </div>
                    }
                    {btn && 
                    <div className="row text-center mt--60">
                        <div className="col-lg-12">
                    
                            <button 
                                className="edu-btn"
                                onClick={ handleLoadMoreBtn }
                                //disabled={ noMorePost ? 'disabled' : null }
                            >
                            <span>Read More</span>
                            </button>
                        
                        </div>
                    </div>
                    }
                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-11-07.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-03.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-4">
                        <img src="/images/shapes/shape-13-12.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-5">
                        <img src="/images/shapes/shape-36.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-6">
                        <img src="/images/shapes/shape-05-07.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default BreadcrumbOne;