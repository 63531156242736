import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Checkbox,
  TextField,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  Slider,
  InputLabel,
  InputAdornment,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetAllMajors } from "./store/GetMajorSlice";
import { GetAllCourse } from "./store/GetCourseSlice";
import { GetUniversityAndCourse } from "./store/GetUniversityAndCourseSlice";
import SearchIcon from "@mui/icons-material/Search";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDown from "@mui/icons-material/KeyboardArrowDownRounded";
import CoursesInUniversityPopup from "./util/CoursesInUniversityPopup";
import "./AddNewApplicationCourseFinder.css"
const AddNewApplicationCourseFinder = () => {
  const [containerHeight, setContainerHeight] = useState(window.innerHeight);
  const [containerWidth, setContainerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateBackgroundSize = () => {
      setContainerHeight(window.innerHeight);
      setContainerWidth(window.innerWidth);
    };

    // Listen for the 'resize' event to update container size
    window.addEventListener("resize", updateBackgroundSize);

    // Cleanup: remove event listener
    return () => {
      window.removeEventListener("resize", updateBackgroundSize);
    };
  }, []);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [expandedIndex, setExpandedIndex] = useState("");
  const [MajorSearch, setMajorSearch] = useState("");
  const [UniversitySearch, setUniversitySearch] = useState();
  const [UniversitiesSelected, setUniversitiesSelected] = useState(true);
  const [UniversityType, setUniversityType] = useState("");
  const [CoursesSelected, setCoursesSelected] = useState(false);
  const [budgetvalue, setbudgetvalue] = useState();
  const [coursedurationvalue, setcoursedurationvalue] = useState();
  const [workexpvalue, setworkexpvalue] = useState();
  const [IntakeSelected, setIntakeSelected] = useState([]);
  const [MajorSelected, setMajorSelected] = useState([]);
  const [CountrySelected, setCountrySelected] = useState([]);
  const [UniveristyTypeSelected, setUniveristyTypeSelected] = useState([]);
  const [ScholarshipSelected, setScholarshipSelected] = useState([]);
  const [EnglishProficiencySelected, setEnglishProficiencySelected] = useState(
    []
  );

  const defaultValues = {
    Country_Name: "",
    Area_of_interest: "",
    University_Type: "",
    Course_Intake: "",
    University_Scholarship: "",
    University_Tution_Fees: "",
    University_Course_Duration: "",
    University_English_Proficiency_Exam: "",
    Work_Experience: "",
  };
  // console.log(labelValue)
  const {
    handleSubmit,
    setValue,
    register,
    reset,
    control,
    watch,
    formState,
    unregister,
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const state = useLocation();
  const data = watch();
  const dispatch = useDispatch();
  const mydata = state.state;
  console.log("this is state data in course finder", mydata);
  const majordata = useSelector((state) => state.getmajors);
  const coursedata = useSelector((state) => state.getcourse);
  const universityandcoursedata = useSelector(
    (state) => state.getuniversityandcourse
  );

  const [isMajorCheck, setisMajorCheck] = useState(mydata.majors);

  console.log("This is uni and course data: ", universityandcoursedata);

  const universityandcourse = universityandcoursedata.data;

  const [filteredData, setFilteredData] = useState(universityandcourse);

  const handleExpansion = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleUniversityTypeChange = (event) => {
    setUniversityType(event.target.value);
  };

  const handlebudgetchange = (event) => {
    setbudgetvalue(event.target.value);
  };

  const handlecoursedurationchange = (event) => {
    setcoursedurationvalue(event.target.value);
  };

  const handleworkexpchange = (event) => {
    setworkexpvalue(event.target.value);
  };

  // Filter Logic
  const handleApplyFilter = () => {
    const filterdata = {
      Country_Name: CountrySelected || "",
      Area_of_interest: MajorSelected || "",
      University_Type: UniveristyTypeSelected || "",
      Course_Intake: IntakeSelected || "",
      University_Scholarship: ScholarshipSelected || "",
      University_English_Proficiency_Exam: EnglishProficiencySelected || "",
      University_Tution_Fees: budgetvalue || "",
      University_Course_Duration: data.University_Course_Duration || "",
      Work_Experience: data.Work_Experience || "",
    };

    console.log("Filter Data: ", filterdata);

    const updatedata = universityandcourse.filter((dt) => {
      if (
        !filterdata.Country_Name &&
        !filterdata.Area_of_interest &&
        !filterdata.Course_Intake &&
        !filterdata.University_Tution_Fees &&
        !filterdata.University_Course_Duration
      ) {
        return true;
      }

      // Apply filters
      return (
        (!filterdata.Country_Name ||
          filterdata.Country_Name === dt.university_id.country) &&
        (!filterdata.Area_of_interest ||
          filterdata.Area_of_interest === dt.course_id.major.name) &&
        (!filterdata.Course_Intake ||
          filterdata.Course_Intake === dt.intake_month) &&
        (!filterdata.University_Tution_Fees ||
          parseInt(filterdata.University_Tution_Fees) === dt.fee) && // convert to int for comparison
        (!filterdata.University_Course_Duration ||
          filterdata.University_Course_Duration ===
            dt.course_id.course_duration)
      );
    });

    console.log("Updated Data: ", updatedata);
};


  const handleUniversitiesSelected = () => {
    setUniversitiesSelected(true);
    setCoursesSelected(false);
  };

  const handleCoursesSelected = () => {
    setCoursesSelected(true);
    setUniversitiesSelected(false);
  };

  useEffect(() => {
    dispatch(GetAllMajors());
    dispatch(GetAllCourse());
    dispatch(GetUniversityAndCourse());
    setFilteredData(universityandcourse);
  }, []);

  const Course = coursedata.data;
  const Majors = majordata.data;
  const uniqueUniversityNamesSet = new Array();
  const Country = ["India", "USA", "UK", "Japan"];

  // Tution Budget Slider
  const Budget_MIN = 5;
  const Budget_MAX = 50;
  const Budget = [
    {
      value: Budget_MIN,
      label: "",
    },
    {
      value: Budget_MAX,
      label: "",
    },
  ];

  // Course Duration Slider
  const course_MIN = 3;
  const course_MAX = 60;
  const courseduration = [
    {
      value: course_MIN,
      label: "",
    },
    {
      value: course_MAX,
      label: "",
    },
  ];

  // Work Exp Slider
  const workexp_MIN = 0;
  const workexp_MAX = 60;
  const workexp = [
    {
      value: workexp_MIN,
      label: "",
    },
    {
      value: workexp_MAX,
      label: "",
    },
  ];

  // Handle Multiple Country Select
  const handleCountrySelected = (name) => {
    setCountrySelected((prev) =>
      prev.includes(name)
        ? prev.filter((item) => item !== name)
        : [...prev, name]
    );
  };

  // Handle Multiple Intake Select
  const handleIntakeSelect = (name) => {
    setIntakeSelected((prev) =>
      prev.includes(name)
        ? prev.filter((item) => item !== name)
        : [...prev, name]
    );
    //     if(who==="add"){
    //       setIntakeSelected((prev) =>[...prev, name])

    //     }
    //     else {
    //  setIntakeSelected((prev)=>prev.filter((item) => item !== name) )
    //     }
  };

  // Handle Multiple Major Select
  const handleMajorSelect = (name) => {
    setMajorSelected((prev) =>
      prev.includes(name)
        ? prev.filter((item) => item !== name)
        : [...prev, name]
    );
  };

  // Handle Multiple University Select
  const handleUniversityTypeSelect = (name) => {
    setUniveristyTypeSelected((prev) =>
      prev.includes(name)
        ? prev.filter((item) => item !== name)
        : [...prev, name]
    );
  };

  // Handle Scholarship Type
  const handleScholarshipSelected = (name) => {
    setScholarshipSelected((prev) =>
      prev.includes(name)
        ? prev.filter((item) => item !== name)
        : [...prev, name]
    );
  };

  // Hanlde English Proficiency Test Select
  const handleEnglishProficiencySelect = (name) => {
    setEnglishProficiencySelected((prev) =>
      prev.includes(name)
        ? prev.filter((item) => item !== name)
        : [...prev, name]
    );
  };

  const handleBudgetChange = (newValue) => {
    handleBudgetFilter(newValue);
  };

  const handleBudgetFilter = (newValue) => {
    console.log("Filtering with value:", newValue);
  };

  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const handleUpdateTrue = (name) => {
    console.log("you said yes");
    // if (name == "password") {
    //   setPasswordUpdate(true);
    //   console.log("password", name);
    //   setOpenPopupFor("");
    //   handleUpdatePassword();
    // } else if (name == "username") {
    //   console.log("password", name);
    //   setOpenPopupFor("");
    //   handleUpdateUsername();
    // } else if (name === "profile_pic") {
    //   setprofile_picCounter((prev) => (prev == 1 ? prev + 1 : 2));
    //   setOpenUpdatePopup(false);
    //   setprofile_picImages([]);
    // } else if (name === "Student_Profile") {
    //   setOpenUpdatePopup(false);
    //   handleProfileUpdate();
    // } else {
    //   return false;
    // }
    setOpenUpdatePopup(false);
  };

  const handleUpdateFalse = () => {
    console.log("you said No");
    setOpenUpdatePopup(false);
  };
  const [openPopupFor, setOpenPopupFor] = useState();

  const handleCoursesOfUniversity = () => {
    setOpenUpdatePopup(true);
    setOpenPopupFor("Student_Profile");
  };
  return (
    <div
      id="background-container"
      // className={`bg-cover bg-gray-200`}
      style={
        {
          // backgroundColor:"grey"
          // background: `url("/images/applicationflow/bg-gray.png") repeat`,
          //  backgroundSize: "cover",
          //  minHeight: `${document.body.scrollHeight}px`,
          // minWidth: `${containerWidth}px`,
        }
      }
    >
      <CoursesInUniversityPopup
        openDeletePopup={openUpdatePopup}
        setOpenDeletePopup={setOpenUpdatePopup}
        handleDeleteTrue={() => handleUpdateTrue(openPopupFor)}
        handleDeleteFalse={() => handleUpdateFalse()}
        name={openPopupFor}
        data={universityandcourse}
      />
      <div className=" w-full h-fit bg-gray-400 backdrop-blur-md shadow-lg bg-opacity-25 text-green-700 font-extrabold text-[35px] px-20 py-10">
        <img
          src="/images/applicationflow/coursefinderimages/celebration.png"
          className=" h-24 w-24"
        />
        2 Courses in 1 universities found
      </div>
      <div className="bg-transparent w-full h-screen lg:h-screen ">
        {/* <img
          src="/images/applicationflow/countrybg.jpg"
          className="
            xxl:w-[1920px] xxl:h-fit
            lg:w-[1024px] lg:h-full
            md:w-[425px] md:h-screen
            sm:w-full sm:h-full
            xs:w-full xs:h-screen"
        /> */}
        <Grid
          container
          textAlign={"center"}
          className="h-screen lg:h-screen"
          style={{ cursor: "pointer" }}
        >
          <Grid item xs={3.5} className=" h-screen ">
            <div className="justify-center bg-blue-300 px-3 py-5 bg-opacity-50 flex mb-24 mt-11  absolute mx-12  w-[350px] h-fit rounded-[20px] backdrop-filter  ">
              <div className=" w-full">
                <div className="w-full text-[25px] flex justify-start mx-8 my-4 items-center">
                  <FilterAltIcon sx={{ fontSize: "30px", color: "#8888ee" }} />
                  Filters*
                  <Grid item xs={5} className="ml-16 w-full ">
                    <div className=" w-full flex justify-center items-center  ">
                      <button
                        onClick={handleApplyFilter}
                        className="justify-center  w-full bg-blue-500 rounded-3xl relative  sparkle-button flex items-center gap-1  font-semibold  bg-gradient-to-r from-blue-500 to-blue-700 text-white text-[16px] py-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        Apply
                      </button>
                    </div>
                  </Grid>
                </div>
                <hr className=" border-black mb-6" />
                {/*--------------------------------------------------Country Accordion-------------------------------------------------*/}
                <Accordion
                  className="mx-3 mt-7 border-b-4 border-solid my-0"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 0}
                  onChange={() => handleExpansion(0)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className="text-[15px] font-bold text-black">
                      Country
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Controller
                      name="Country_Name"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            sx={{
                              "& input": {
                                fontSize: "1.2rem",
                              },
                            }}
                            variant="outlined"
                            id="search_country"
                            value={selectedCountry}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    sx={{ fontSize: "2em", color: "blue" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSelectedCountry(e.target.value)}
                            placeholder="Search"
                            fullWidth
                            className="w-full border-none rounded-[17px] text-2xl"
                          />
                          <RadioGroup
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            className="ml-2 mt-2"
                          >
                            <FormGroup>
                              {(selectedCountry === "" ||
                                selectedCountry === undefined) &&
                                Country &&
                                Country.map((name, index) => (
                                  <FormControlLabel
                                    key={index}
                                    value={name}
                                    control={
                                      <Radio
                                        {...field} // Pass field props to Checkbox
                                        value={name} // Set value of Checkbox
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 15,
                                          },
                                        }}
                                        onChange={(e) => {
                                          // Update the labelValue when the checkbox is checked
                                          if (e.target.checked) {
                                            handleCountrySelected(name, "add");
                                          } else {
                                            handleCountrySelected(name, "bad");
                                          }
                                        }}
                                      />
                                    }
                                    label={
                                      <span className="tracking-widest font-bold text-[13px]">
                                        {name}
                                      </span>
                                    }
                                  />
                                ))}
                            </FormGroup>
                            <FormGroup>
                              {!(
                                selectedCountry === "" ||
                                selectedCountry === undefined
                              ) &&
                                selectedCountry &&
                                Country &&
                                Country.filter(
                                  (name) =>
                                    name
                                      .toLowerCase()
                                      .includes(
                                        selectedCountry.toLowerCase()
                                      ) ||
                                    name
                                      .toUpperCase()
                                      .includes(selectedCountry.toUpperCase())
                                ).map((name, index) => (
                                  <FormControlLabel
                                    key={index}
                                    value={name}
                                    control={
                                      <Radio
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 15,
                                          },
                                        }}
                                        onChange={(e) => {
                                          // Update the labelValue when the checkbox is checked
                                          if (e.target.checked) {
                                            handleCountrySelected(
                                              name?.name,
                                              "add"
                                            );
                                          } else {
                                            handleCountrySelected(
                                              name?.name,
                                              "bad"
                                            );
                                          }
                                        }}
                                      />
                                    }
                                    label={
                                      <span className="tracking-widest font-bold text-[15px]">
                                        {name}
                                      </span>
                                    }
                                  />
                                ))}
                            </FormGroup>
                          </RadioGroup>
                        </>
                      )}
                    />

                    {/* <FormControl fullWidth>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                      >
                        <FormControlLabel
                          value="India"
                          control={<Radio />}
                          label="India"
                        />

                        <FormControlLabel
                          value="USA"
                          control={<Radio />}
                          label="USA"
                        />

                        <FormControlLabel
                          value="UK"
                          control={<Radio />}
                          label="UK"
                        />
                      </RadioGroup>
                    </FormControl> */}

                    {/* <FormControl fullWidth>
                      <Select
                        id="selectcountry"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        className="tracking-wider text-[15px]"
                      >
                        <MenuItem value={"India"}>India</MenuItem>
                        <MenuItem value={"USA"}>USA</MenuItem>
                        <MenuItem value={"UK"}>UK</MenuItem>
                        <MenuItem value={"Japan"}>Japan</MenuItem>
                      </Select>
                    </FormControl> */}
                  </AccordionDetails>
                </Accordion>

                {/* -----------------------------------------------------Area of Interest--------------------------------------------- */}
                <Accordion
                  className="mx-3  border-b-4 border-solid my-0"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 1}
                  onChange={() => handleExpansion(1)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDown />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography className=" text-[15px] font-bold text-black">
                      Area Of Interest
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      // style={{ marginBottom: "16px" }}
                      className="flex w-full justify-center text-3xl"
                    >
                      <TextField
                        sx={{
                          "& input": {
                            fontSize: "1.2rem",
                          },
                        }}
                        variant="outlined"
                        id="user_name"
                        value={MajorSearch}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon
                                sx={{ fontSize: "2em", color: "blue" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setMajorSearch(e.target.value)}
                        placeholder="Search"
                        fullWidth
                        className="w-full border-none rounded-[17px] text-2xl "
                      />
                    </div>

                    {/* <div className="relative">
  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
    <i className="fa fa-user" />
  </div>
  <input
  style={{ borderColor: "black !important" }}
    type="text"
    placeholder="Username"
    className="pl-10 pr-4 py-2 rounded-lg border-transparent focus:border-black focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-full focus:outline-none"
  />
</div> */}
                    <Controller
                      name="Area_of_interest"
                      control={control}
                      render={({ field }) => (
                        <Typography>
                          <FormGroup className="ml-2 mt-2">
                            {(MajorSearch === "" ||
                              MajorSearch === undefined) &&
                              Majors &&
                              Majors.map((name) => (
                                <FormControlLabel
                                  key={name.id}
                                  className=" "
                                  control={
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 20 },
                                      }}
                                      {...field} // Pass field props to Checkbox
                                      value={name.name} // Set value of Checkbox
                                      onChange={(e) => {
                                        // Update the labelValue when the checkbox is checked
                                        if (e.target.checked) {
                                          handleMajorSelect(name?.name, "add");
                                        } else {
                                          handleMajorSelect(name?.name, "bad");
                                        }
                                      }}
                                      checked={isMajorCheck.includes(name.name)} // Check if name is in isMajorCheck array
                                    />
                                  }
                                  label={
                                    <label className=" tracking-widest font-bold text-[13px]">
                                      {name.name}
                                    </label>
                                  }
                                />
                              ))}
                          </FormGroup>

                          <FormGroup>
                            {!(
                              MajorSearch === "" || MajorSearch === undefined
                            ) &&
                              MajorSearch &&
                              Majors &&
                              Majors.filter(
                                (name) =>
                                  name.name
                                    .toLowerCase()
                                    .includes(MajorSearch.toLowerCase()) ||
                                  name.name
                                    .toUpperCase()
                                    .includes(MajorSearch.toUpperCase())
                              ).map((name) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 28 },
                                      }}
                                      {...field} // Pass field props to Checkbox
                                      value={name.name} // Set value of Checkbox
                                      onChange={(e) => {
                                        // Update the labelValue when the checkbox is checked
                                        if (e.target.checked) {
                                          handleMajorSelect(name?.name, "add");
                                        } else {
                                          handleMajorSelect(name?.name, "bad");
                                        }
                                      }}
                                      checked={isMajorCheck.includes(name.name)} // Check if name is in isMajorCheck array
                                    />
                                  }
                                  label={
                                    <label className=" tracking-widest font-bold text-[15px]">
                                      {name.name}
                                    </label>
                                  }
                                />
                              ))}
                          </FormGroup>
                        </Typography>
                      )}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* -----------------------------------------------------University Type---------------------------------------------- */}
                <Accordion
                  className=" mx-3  border-b-4 border-solid my-0"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 2}
                  onChange={() => handleExpansion(2)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className=" text-[15px] font-bold text-black">
                      University Type
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Controller
                      name="University_Type"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth className="ml-2">
                          <RadioGroup
                            aria-labelledby="university_type"
                            name="university_type"
                            value={UniversityType}
                            onChange={handleUniversityTypeChange}
                          >
                            <FormControlLabel
                              value="Fast Track"
                              control={
                                <Radio
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 20 },
                                  }}
                                  
                                />
                              }
                              label={
                                <span className="tracking-widest text-[15px]">
                                  Fast Track
                                </span>
                              }
                            />

                            <FormControlLabel
                              value="Normal"
                              control={
                                <Radio
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 20 },
                                  }}
                                />
                              }
                              label={
                                <span className="tracking-widest text-[15px]">
                                  Normal
                                </span>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* ------------------------------------------------------Intake------------------------------------------------------ */}

                <Accordion
                  className="mx-3 border-b-4 border-solid my-0"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 3}
                  onChange={() => handleExpansion(3)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDown />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                    className="text-black "
                    sx={{
                      borderRadius: "120px",
                      borderColor: "black",
                      ".Mui-expanded": {
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <Typography className=" text-[15px] font-bold text-black">
                      Intake
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {universityandcourse &&
                      universityandcourse.map((name) => (
                        <Controller
                          name="Course_Intake"
                          control={control}
                          render={({ field }) => (
                            <FormGroup
                              className="ml-2"
                              key={name?.intake_month}
                              {...field}
                            >
                              <FormControlLabel
                                className=" "
                                control={
                                  <Checkbox
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 20 },
                                    }}
                                    // Associate the checkbox with the field name "Course_Intake"
                                    {...field}
                                    value="checkedA"
                                    onChange={(e) => {
                                      // Update the labelValue when the checkbox is checked
                                      if (e.target.checked) {
                                        handleIntakeSelect(
                                          name?.intake_month,
                                          "add"
                                        );
                                      } else {
                                        handleIntakeSelect(
                                          name?.intake_month,
                                          "bad"
                                        );
                                      }
                                    }}
                                  />
                                }
                                label={
                                  <label className=" tracking-wider font-bold text-[15px] text-gray-500">
                                    {name?.intake_month}
                                  </label>
                                }
                              />
                            </FormGroup>
                          )}
                        />
                      ))}
                  </AccordionDetails>
                </Accordion>

                {/* -----------------------------------------------------Scholarship-------------------------------------------------- */}
                <Accordion
                  className=" mx-3 border-b-4 border-solid my-0"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 4}
                  onChange={() => handleExpansion(4)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className=" text-[15px] font-bold text-black">
                      Scholarship
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Controller
                      name="University_Scholarship"
                      control={control}
                      render={({ field }) => (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 20 },
                                }}
                              />
                            }
                            label={
                              <label className=" tracking-widest font-bold text-[13px]">
                                Available
                              </label>
                            }
                          />
                        </FormGroup>
                      )}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* -----------------------------------------------------Tuition Budget----------------------------------------------- */}
                <Accordion
                  className=" mx-3 border-b-4 border-solid my-0"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 5}
                  onChange={() => handleExpansion(5)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className=" text-[15px] font-bold text-black">
                      Tution Budget
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Controller
                      name="University_Tuition_Fees"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Typography variant="h6" gutterBottom>
                            Budget: {budgetvalue} Lacs
                          </Typography>
                          <Slider
                            min={Budget_MIN}
                            max={Budget_MAX}
                            marks={Budget}
                            value={budgetvalue}
                            onChange={(e, newValue) =>
                              handleBudgetChange(newValue)
                            }
                            aria-label="Default"
                            valueLabelDisplay="auto"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="h5"
                              onClick={() => setbudgetvalue(Budget_MIN)}
                              sx={{ cursor: "pointer" }}
                            >
                              {Budget_MIN} Lacs
                            </Typography>

                            <Typography
                              variant="h5"
                              onClick={() => setbudgetvalue(Budget_MAX)}
                              sx={{ cursor: "pointer" }}
                            >
                              {Budget_MAX} Lacs
                            </Typography>
                          </Box>
                        </>
                      )}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* -----------------------------------------------------Course Duration---------------------------------------------- */}
                <Accordion
                  className=" mx-3 border-b-4 border-solid my-0"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 6}
                  onChange={() => handleExpansion(6)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className=" text-[15px] font-bold text-black">
                      Course Duration
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Controller
                      name="University_Course_Duration"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Typography variant="h6" gutterBottom>
                            Months: {coursedurationvalue}
                          </Typography>
                          <Slider
                            min={course_MIN}
                            max={course_MAX}
                            marks={courseduration}
                            value={coursedurationvalue}
                            onChange={handlecoursedurationchange}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="h5"
                              onClick={() => setcoursedurationvalue(course_MIN)}
                              sx={{ cursor: "pointer" }}
                            >
                              {course_MIN} Months
                            </Typography>

                            <Typography
                              variant="h5"
                              onClick={() => setcoursedurationvalue(course_MAX)}
                              sx={{ cursor: "pointer" }}
                            >
                              {course_MAX} Months
                            </Typography>
                          </Box>
                        </>
                      )}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* -----------------------------------------------------English Proficiency------------------------------------------ */}
                <Accordion
                  className=" mx-3 border-b-4 border-solid my-0"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 7}
                  onChange={() => handleExpansion(7)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className=" text-[15px] font-bold text-black">
                      English Proficiency Exams
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Controller
                      name="University_English_Proficiency_Exam"
                      control={control}
                      render={({ field }) => (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 20 },
                                }}
                              />
                            }
                            label={
                              <label className=" tracking-widest font-bold text-[13px]">
                                IELTS Score
                              </label>
                            }
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 20 },
                                }}
                              />
                            }
                            label={
                              <label className=" tracking-widest font-bold text-[13px]">
                                IELTS Waiver
                              </label>
                            }
                          />
                        </FormGroup>
                      )}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* ------------------------------------------------------UG UNI------------------------------------------------------ */}
                {/* <Accordion
                  className=" mx-3 mb-8 border-b-4 border-solid my-0"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 8}
                  onChange={() => handleExpansion(8)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className=" text-[15px] font-bold text-black">
                      Undergradudate University
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    
                  </AccordionDetails>
                </Accordion> */}

                {/* -----------------------------------------------------Work Exp----------------------------------------------------- */}
                <Accordion
                  className=" mx-3 mb-8 border-b-4 border-solid my-0"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 8}
                  onChange={() => handleExpansion(8)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className=" text-[15px] font-bold text-black">
                      Work Expirence
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Controller
                      name="Work_Experience"
                      control={control}
                      render={({ field }) => (
                        <>
                          <Typography variant="h6" gutterBottom>
                            Months: {workexpvalue}
                          </Typography>
                          <Slider
                            min={workexp_MIN}
                            max={workexp_MAX}
                            marks={workexp}
                            value={workexpvalue}
                            onChange={handleworkexpchange}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                          />
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="h5"
                              onClick={() => setworkexpvalue(workexp_MIN)}
                              sx={{ cursor: "pointer" }}
                            >
                              {workexp_MIN} Months
                            </Typography>

                            <Typography
                              variant="h5"
                              onClick={() => setworkexpvalue(workexp_MAX)}
                              sx={{ cursor: "pointer" }}
                            >
                              {workexp_MAX} Months
                            </Typography>
                          </Box>
                        </>
                      )}
                    />
                  </AccordionDetails>
                </Accordion>

                {/* ----------------------------------------------------NGO----------------------------------------------------------- */}
                {/* <Accordion
                  className=" mx-3 mb-8 border-b-4 border-solid"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 8}
                  onChange={() => handleExpansion(8)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className=" text-[15px] font-bold text-black">
                      University Type
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}

                {/* ---------------------------------------------------Extraculicular------------------------------------------------- */}
                {/* <Accordion
                  className=" mx-3 mb-8 border-b-4 border-solid"
                  TransitionProps={{
                    initial: "0.3s ease-out",
                    expanded: "0.2s ease",
                  }}
                  expanded={expandedIndex === 8}
                  onChange={() => handleExpansion(8)}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className=" text-[15px] font-bold text-black">
                      University Type
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
              </div>
            </div>
          </Grid>

          <Grid item xs={8} className=" h-screen">
            <div className=" w-full h-fit justify-center flex my-11 items-center">
              <Grid
                container
                textAlign={"center"}
                style={{ cursor: "pointer" }}
              >
                <Grid item xs={6} className=" ">
                  <button
                    onClick={() => handleUniversitiesSelected()}
                    className=" w-full h-fit  transition-all ease-in"
                  >
                    {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                    <label
                      className={`${
                        UniversitiesSelected ? "bg-blue-400" : "bg-gray-200"
                      } border-blue-500 border-solid border-l-2 border-y-2  w-full ease-in-out transition-all bg-opacity-50 items-center   px-10 flex rounded-xl rounded-r-none h-fit `}
                    >
                      <div className=" h-24 pb-4 pl-1" />
                      <label className="text-black flex items-center justify-center font-extrabold tracking-wider text-[15px]   w-full pr-5">
                        Universities
                      </label>
                    </label>
                    {/* </div> */}
                  </button>
                </Grid>
                <Grid item xs={6}>
                  <button
                    onClick={() => handleCoursesSelected()}
                    className=" w-full h-fit rounded-xl rounded-l-none transition-all ease-in"
                  >
                    {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                    <label
                      className={`${
                        CoursesSelected ? "bg-blue-400" : "bg-gray-200"
                      } ease-in-out border-blue-500 border-r-2 border-y-2 transition-all bg-opacity-50 items-center   px-10 flex   rounded-xl rounded-l-none h-fit w-full `}
                    >
                      <div className=" h-24 pb-4 pl-1" />
                      <label className="text-black flex items-center justify-center font-extrabold tracking-wider text-[15px]   w-full pr-5">
                        Courses
                      </label>
                    </label>
                    {/* </div> */}
                  </button>
                </Grid>

                {UniversitiesSelected && !CoursesSelected ? (
                  <>
                    <Grid item xs={12} className=" mt-10 w-full">
                      <TextField
                        sx={{
                          "& input": {
                            fontSize: "1.5rem",
                          },
                        }}
                        variant="outlined"
                        id="user_name"
                        value={UniversitySearch}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon
                                sx={{ fontSize: "2.4em", color: "blue" }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => setUniversitySearch(e.target.value)}
                        placeholder="Search"
                        fullWidth
                        className="w-full border-none rounded-[20px] text-2xl "
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className="w-full h-96 mt-8">
                        {/* <Swiper
                  className=' w-full'
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log("slide change")}
                    // autoplay={{
                    //   delay: 2500,
                    //   disableOnInteraction: false,
                    // }}
                    // loop={true}
                  >
                    <SwiperSlide>
                      <img className="h-96 w-full" src={"/images/applicationflow/hero.png"} alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                      <img className="h-96 w-full" src={"/images/applicationflow/hero.png"} alt="" />
                    </SwiperSlide>
                  </Swiper> */}
                        <div class="w-full items-center justify-center h-fit flex rounded-xl ">
                          <Grid
                            container
                            className=" flex items-center justify-center"
                          >
                            <Grid item xs={4}>
                              <hr className=" border-solid border-2 border-blue-500 " />
                            </Grid>
                            <Grid item xs={4}>
                              {/* <button className=' w-full text-black font-serif font-extrabold bg-blue-500 rounded-3xl py-7 bg-opacity-50 backdrop-blur-sm'>
                            
                            <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "2em", color: "limegreen" }}/>&nbsp;Our Most Trusted Universities
                              </button> */}

                              {/* <button class="relative w-full sparkle-button flex items-center gap-1 text-lg font-semibold rounded-full bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
    <svg class="absolute w-full top-0 left-0  h-10 transform -translate-x-1/2 -translate-y-1/2 text-blue-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z" fill="currentColor"></path>
      <path d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z" fill="currentColor"></path>
      <path d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z" fill="currentColor"></path>
    </svg>
    <span>Click me!</span>
  </button> */}

                              <button class="relative w-full sparkle-button flex items-center gap-1 text-lg font-semibold rounded-full bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-4 ">
                                <svg
                                  class="absolute w-10 h-10 top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 text-blue-500"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                                {/*
    <svg class="absolute w-10 h-10 top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-blue-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z" fill="currentColor"></path>
      <path d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z" fill="currentColor"></path>
      <path d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z" fill="currentColor"></path>
    </svg>

    <svg class="absolute w-10 h-10 bottom-0 left-0 transform -translate-x-1/2 translate-y-1/2 text-blue-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z" fill="currentColor"></path>
      <path d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z" fill="currentColor"></path>
      <path d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z" fill="currentColor"></path>
    </svg>
*/}
                                <svg
                                  class="absolute w-10 h-10 bottom-0 right-0 transform translate-x-1/2 translate-y-1/2 text-blue-500"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                                <span>
                                  <label className=" bg-white rounded-full w-fit p-1">
                                    {" "}
                                    <CheckCircleOutlineOutlinedIcon
                                      sx={{
                                        fontSize: "2em",
                                        color: "limegreen",
                                      }}
                                    />
                                  </label>
                                  &nbsp; &nbsp;Our Most Trusted Universities
                                </span>
                              </button>
                            </Grid>
                            <Grid item xs={4}>
                              <hr className=" border-solid border-2 border-blue-500 " />
                            </Grid>
                          </Grid>
                        </div>

                        <Swiper
                          pagination={{ clickable: true }}
                          modules={[Pagination]}
                          slidesPerView={2}
                          className="w-full h-[400px] px-2  mt-16 bg-blue-200 overflow-hidden justify-center items-center flex rounded-[20px] backdrop-blur-md shadow-md bg-opacity-30"
                        >
                          {universityandcourse &&
                            universityandcourse.map((name) => {
                              if (
                                !uniqueUniversityNamesSet.includes(
                                  name.university_id.university_name
                                )
                              ) {
                                uniqueUniversityNamesSet.push(
                                  name.university_id.university_name
                                );
                                return (
                                  <SwiperSlide className="text-center w-fit mt-16 text-lg bg-transparent flex justify-center items-center">
                                    <div className="justify-center   absolute  h-[300px] flex mx-8   w-fit bg-grey-50   rounded-[20px] backdrop-filter bg-white backdrop-blur-md bg-opacity-30 hover:shadow-gray-500 shadow-xl  border-gray-600">
                                      {/* <img classNitems-centerame="h-12 w-12 " src="https://images.pexels.com/photos/968299/pexels-photo-968299.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Country" /> */}
                                      <Grid
                                        container
                                        textAlign={"center"}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Grid
                                          item
                                          xs={12}
                                          className="flex justify-center mx-12 my-4 items-center"
                                        >
                                          <div className=" w-24 h-24 bg-white rounded-full">
                                            <img
                                              className="h-12 w-12  "
                                              src="/images/applicationflow/hero.png"
                                              alt="Country"
                                            />
                                          </div>
                                          <Grid container>
                                            <Grid item xs={12}>
                                              <Typography className=" font-bold text-[20px] flex justify-start ml-7 w-full items-center ">
                                                {
                                                  name.university_id
                                                    .university_name
                                                }
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <label className=" font-bold text-[15px] flex justify-start ml-16 items-center ">
                                                Gujrat,India
                                              </label>
                                            </Grid>
                                          </Grid>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={12}
                                          className="flex justify-center mx-5  items-center"
                                        >
                                          <Typography className="text-[20px]">
                                            <h2>
                                              <b>Trusted University</b>
                                            </h2>
                                          </Typography>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={12}
                                          className="flex justify-center mx-5  items-center"
                                        >
                                          <Typography color="text-[40px]">
                                            Located in Nadiad
                                          </Typography>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={12}
                                          className="flex justify-center mx-5 items-center"
                                        >
                                          <h2>
                                            <Typography color="text-[20px]">
                                              <b>
                                                Recommended To Be admited in BCA
                                              </b>{" "}
                                            </Typography>
                                          </h2>
                                        </Grid>

                                        <Grid item xs={12} className=" w-full ">
                                          <div className=" w-full flex justify-center items-center px-9 ">
                                            <button
                                              onClick={
                                                handleCoursesOfUniversity
                                              }
                                              className="justify-center  w-full p-8 bg-blue-500 rounded-3xl relative  sparkle-button flex items-center gap-1 text-lg font-semibold  bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-4 "
                                            >
                                              Recommended Courses (3)
                                            </button>
                                          </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                          <div class="flex items-center gap-6 justify-center w-full">
                                            <a
                                              class="text-gray-700 hover:text-blue-800"
                                              aria-label="Visit TrendyMinds LinkedIn"
                                              href=""
                                              target="_blank"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                class="h-8"
                                              >
                                                <path
                                                  fill="currentColor"
                                                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                                ></path>
                                              </svg>
                                            </a>

                                            <a
                                              class="text-gray-700 hover:text-black"
                                              aria-label="Visit Twitter"
                                              href="#"
                                              target="_blank"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 72 72"
                                                class="h-8 align-middle"
                                              >
                                                <path
                                                  fill="currentColor"
                                                  d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66 h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"
                                                ></path>
                                              </svg>
                                            </a>

                                            <a
                                              class="text-gray-700 hover:text-orange-600"
                                              aria-label="Visit TrendyMinds YouTube"
                                              href=""
                                              target="_blank"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 576 512"
                                                class="h-8"
                                              >
                                                <path
                                                  fill="currentColor"
                                                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                                                ></path>
                                              </svg>
                                            </a>
                                            <a
                                              class="text-gray-700 hover:text-blue-600"
                                              aria-label="Visit TrendyMinds Facebook"
                                              href=""
                                              target="_blank"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 320 512"
                                                class="h-8"
                                              >
                                                <path
                                                  fill="currentColor"
                                                  d="m279.14 288 14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                                                ></path>
                                              </svg>
                                            </a>
                                            <a
                                              class="text-gray-700 hover:text-pink-500"
                                              aria-label="Visit TrendyMinds Instagram"
                                              href=""
                                              target="_blank"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                class="h-8"
                                              >
                                                <path
                                                  fill="currentColor"
                                                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                                ></path>
                                              </svg>
                                            </a>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </SwiperSlide>
                                );
                              }
                            })}

                          {/* <SwiperSlide className="text-center w-fit mt-16 text-lg bg-transparent flex justify-center items-center">
                            <div className="justify-center   absolute  h-[300px] flex mx-8   w-fit bg-grey-50   rounded-[20px] backdrop-filter bg-white backdrop-blur-md bg-opacity-30 hover:shadow-gray-500 shadow-xl  border-gray-600">

                              {/* <img classNitems-centerame="h-12 w-12 " src="https://images.pexels.com/photos/968299/pexels-photo-968299.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="Country" /> */}
                          {/*<Grid container textAlign={"center"} style={{ cursor: "pointer" }}>
                                <Grid item xs={12} className='flex justify-center mx-12 my-4 items-center'>
                                  <div className=' w-24 h-24 bg-white rounded-full'><img className="h-12 w-12  " src="/images/applicationflow/hero.png" alt="Country" /></div>
                                  <Grid container >
                                    <Grid item xs={12}>
                                      <Typography className=' font-bold text-[20px] flex justify-start ml-7 w-full items-center '>
                                        Dharmsinh Desai University
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <label className=' font-bold text-[15px] flex justify-start ml-16 items-center '>
                                        Gujrat,India
                                      </label>
                                    </Grid>
                                  </Grid>
                                </Grid>


                                <Grid item xs={12} className='flex justify-center mx-5  items-center'>
                                  <Typography className="text-[20px]"><h2><b>Trusted University</b></h2></Typography>
                                </Grid>

                                <Grid item xs={12} className='flex justify-center mx-5  items-center'>
                                  <Typography color="text-[40px]">Located in Nadiad</Typography>
                                </Grid>

                                <Grid item xs={12} className='flex justify-center mx-5 items-center'>
                                  <h2><Typography color="text-[20px]"><b>Recommended To Be admited in BCA</b> </Typography></h2>
                                </Grid>


                                <Grid item xs={12} className=' w-full '>
                                  <div className=' w-full flex justify-center items-center px-9 '>
                                    <button className="justify-center  w-full p-8 bg-blue-500 rounded-3xl relative  sparkle-button flex items-center gap-1 text-lg font-semibold  bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" >
                                      Recommended Courses (3)
                                    </button>
                                  </div>

                                </Grid>

                                <Grid item xs={12}>

                                  <div class="flex items-center gap-6 justify-center w-full">
                                    <a class="text-gray-700 hover:text-blue-800" aria-label="Visit TrendyMinds LinkedIn" href="" target="_blank"><svg
                                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-8">
                                      <path fill="currentColor"
                                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                                      </path>
                                    </svg>
                                    </a>


                                    <a class="text-gray-700 hover:text-black" aria-label="Visit Twitter" href="#" target="_blank">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" class="h-8 align-middle">
                                        <path fill="currentColor" d="M42.5,31.2L66,6h-6L39.8,27.6L24,6H4l24.6,33.6L4,66 h6l21.3-22.8L48,66h20L42.5,31.2z M12.9,10h8l38.1,52h-8L12.9,10z"></path>
                                      </svg>
                                    </a>



                                    <a class="text-gray-700 hover:text-orange-600" aria-label="Visit TrendyMinds YouTube" href="" target="_blank"><svg
                                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="h-8">
                                      <path fill="currentColor"
                                        d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z">
                                      </path>
                                    </svg>
                                    </a>
                                    <a class="text-gray-700 hover:text-blue-600" aria-label="Visit TrendyMinds Facebook" href="" target="_blank"><svg
                                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="h-8">
                                      <path fill="currentColor"
                                        d="m279.14 288 14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                                      </path>
                                    </svg>
                                    </a>
                                    <a class="text-gray-700 hover:text-pink-500" aria-label="Visit TrendyMinds Instagram" href="" target="_blank"><svg
                                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-8">
                                      <path fill="currentColor"
                                        d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                                      </path>
                                    </svg>
                                    </a>

                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                          </SwiperSlide> */}
                        </Swiper>
                      </div>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      container
                      textAlign={"center"}
                      style={{ cursor: "pointer" }}
                    >
                      <div class="w-full items-center mt-16 mb-8 justify-center h-fit flex rounded-xl ">
                        <Grid
                          container
                          className=" flex items-center justify-center"
                        >
                          <Grid item xs={4}>
                            <hr className=" border-solid border-2 border-blue-500 " />
                          </Grid>
                          <Grid item xs={4}>
                            {/* <button className=' w-full text-black font-serif font-extrabold bg-blue-500 rounded-3xl py-7 bg-opacity-50 backdrop-blur-sm'>
                            
                            <CheckCircleOutlineOutlinedIcon sx={{ fontSize: "2em", color: "limegreen" }}/>&nbsp;Our Most Trusted Universities
                              </button> */}

                            {/* <button class="relative w-full sparkle-button flex items-center gap-1 text-lg font-semibold rounded-full bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
    <svg class="absolute w-full top-0 left-0  h-10 transform -translate-x-1/2 -translate-y-1/2 text-blue-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z" fill="currentColor"></path>
      <path d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z" fill="currentColor"></path>
      <path d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z" fill="currentColor"></path>
    </svg>
    <span>Click me!</span>
  </button> */}

                            <button class="relative w-full sparkle-button flex items-center gap-1 text-lg font-semibold rounded-full bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                              <svg
                                class="absolute w-10 h-10 top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 text-blue-500"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                              {/*
    <svg class="absolute w-10 h-10 top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-blue-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z" fill="currentColor"></path>
      <path d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z" fill="currentColor"></path>
      <path d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z" fill="currentColor"></path>
    </svg>

    <svg class="absolute w-10 h-10 bottom-0 left-0 transform -translate-x-1/2 translate-y-1/2 text-blue-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z" fill="currentColor"></path>
      <path d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z" fill="currentColor"></path>
      <path d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z" fill="currentColor"></path>
    </svg>
*/}
                              <svg
                                class="absolute w-10 h-10 bottom-0 right-0 transform translate-x-1/2 translate-y-1/2 text-blue-500"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                              <span>
                                <label className=" bg-white rounded-full w-fit p-1">
                                  <CheckCircleOutlineOutlinedIcon
                                    sx={{ fontSize: "2em", color: "limegreen" }}
                                  />
                                </label>
                                <label className=" text-[13px]">
                                  &nbsp; &nbsp;Our Hand Picked Courses For You
                                </label>
                              </span>
                            </button>
                          </Grid>
                          <Grid item xs={4}>
                            <hr className=" border-solid border-2 border-blue-500 " />
                          </Grid>
                        </Grid>
                      </div>
                      {universityandcourse &&
                        universityandcourse.map((name) => (
                          <div className=" w-full h-fit justify-center flex mt-12 mb-12  items-center">
                            <Grid item xs={12}>
                              <div
                                className=" text-black 
    justify-center items-center  w-full  h-fit rounded-[30px] 
    backdrop-filter bg-blue-200 backdrop-blur-md bg-opacity-20 shadow-gray-500 
    shadow-xl border-gray-600"
                              >
                                {/* <Grid container textAlign={"center"} style={{ cursor: "pointer" }} className=''>
                                  <Grid item xs={11}> */}
                                <div className=" w-full h-fit ">
                                  <div
                                    className="
          justify-center items-center w-full text-black  h-fit rounded-[30px] mt-0 rounded-b-none py-7 
          backdrop-filter bg-blue-300 backdrop-blur-md bg-opacity-50   tracking-wide
           "
                                  >
                                    <label className=" text-[30px] font-extrabold">
                                      {name?.course_id?.course_short_name}-(
                                      {name?.course_id?.course_name})
                                    </label>
                                  </div>
                                  <div className="w-full">
                                    <div className="w-full flex justify-between  items-center px-32  tracking-wide">
                                      <div className=" justify-center flex  items-center">
                                        <div className="w-fit mt-9 rounded-full overflow-hidden">
                                          <img
                                            alt="image"
                                            className=" h-40 w-40"
                                            src={name?.university_id?.image}
                                          />
                                        </div>
                                        <div className="grid grid-rows-2 justify-start items-center tracking-wide ml-10 mt-5">
                                          <label className="flex justify-start text-[30px] tracking-wide">
                                            &nbsp;&nbsp;
                                            {
                                              name?.university_id
                                                ?.university_name
                                            }
                                          </label>
                                          <label className="flex justify-start text-[20px] tracking-wide">
                                            {" "}
                                            &nbsp;&nbsp; &nbsp;&nbsp;St.
                                            Andrews, United Kingdom
                                          </label>
                                        </div>
                                      </div>
                                      <div class="rounded-[20px] mt-9 ml-14 flex justify-center items-center bg-transparent rounded-l-none rounded-t-none w-fit h-fit  overflow-hidden  ">
                                        <button class="rounded-[20px]  justify-center px-16 py-7   border-transparent bg-blue-300 backdrop-blur-md bg-opacity-50 overflow-hidden  relative  w-full cursor-pointer flex items-center border-blue-300 group">
                                          <span class="text-black flex h-full justify-center items-center text-[20px] rounded-[20px] border-transparent rounded-l-none font-semibold  transform transition-all duration-300 ">
                                            Apply
                                          </span>
                                          <span class="absolute right-0 h-full w-0 rounded-lg bg-blue-300 flex items-center justify-center transition-all duration-300 group-hover:w-full">
                                            <span class="text-blue-300 first:opacity-0 group-hover:opacity-100 group-hover:text-black w-full h-full justify-center items-center flex text-[30px] font-semibold">
                                              <ArrowForwardIcon
                                                sx={{ fontSize: "3rem" }}
                                              />
                                            </span>
                                          </span>
                                        </button>
                                      </div>
                                    </div>

                                    <div className="w-full justify-center items-center flex mt-20 mb-4 tracking-wider">
                                      <div className="flex justify-center items-center mx-5 tracking-wider">
                                        <label>
                                          <img
                                            src="/images/applicationflow/coursefinderimages/creditcard.svg"
                                            className=" h-24 w-20"
                                          />
                                        </label>
                                        <label className=" mt-2  ml-3">
                                          &nbsp; Tution Fees(in {name?.currency}
                                          ) :- <br /> {name.fee}/-
                                        </label>
                                      </div>
                                      <div className="flex justify-center items-center mx-5 tracking-wider">
                                        <label>
                                          <img
                                            src="/images/applicationflow/coursefinderimages/duration.png"
                                            className=" h-20 w-20"
                                          />
                                        </label>
                                        <label className=" ml-3">
                                          &nbsp;Course Duration:- <br />{" "}
                                          {name?.course_id?.course_duration}{" "}
                                          Months
                                        </label>
                                      </div>
                                      <div className="flex justify-center items-center mx-5 tracking-wider">
                                        <label>
                                          <img
                                            src="/images/applicationflow/coursefinderimages/qulification.png"
                                            className=" h-20 w-20"
                                          />
                                        </label>
                                        <label className=" ml-3">
                                          Qualification(Min):- <br />{" "}
                                          {name?.qualification}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* </Grid> */}
                                {/* <Grid item xs={0.5}> */}
                                {/* <button className='     justify-center items-center w-full text-black  h-full rounded-[30px] mt-0  rounded-l-none py-7 
          backdrop-filter bg-teal-500 backdrop-blur-md bg-opacity-20  tracking-wide
          shadow-xl '> Apply </button> */}
                                {/* <div className='rounded-[30px] rounded-l-none w-full h-full overflow-hidden col-span-1 '>
                                <button
                                  class="rounded-[30px] rounded-l-none  border-transparent bg-teal-500 backdrop-blur-md bg-opacity-20 overflow-hidden shadow-md  rounded-r-none relative w-full h-full cursor-pointer flex items-center border-teal-500  group hover:bg-teal-500 active:bg-teal-500 active:border-teal-500"
                                >
                                  <span class="text-gray-200 rounded-[30px] border-transparent  rounded-l-none font-semibold ml-8 transform group-hover:translate-x-20 transition-all duration-300 ">
                                    Apply
                                  </span>
                                  <span class="absolute right-0 h-full w-0 hover:w-10 rounded-lg bg-teal-500   flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                                    <span class="text-teal-500 first:opacity-0 hover:opacity-100 hover:text-white w-full h-full justify-center items-center flex text-[30px] font-semibold">
                                      <ArrowForwardIcon sx={{fontSize:"3rem"}}/>
                                    </span>
                                  </span>
                                </button>
                                </div> */}

                                {/* </Grid> */}
                                {/* </Grid> */}
                              </div>
                            </Grid>{" "}
                          </div>
                        ))}
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AddNewApplicationCourseFinder;
