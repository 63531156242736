import React, { useState, useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

import SEO from '../../common/SEO';
// import SEO from '../../common/SEO';
// import Layout from '../../common/Layout';
// import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
 


const onlineEducation = {
    leftColumn: [
        {
            id: 1,
            title: 'Which organisations accept IELTS?',
            body: 'IELTS is accepted by more than 11,000 organisations worldwide. These include universities, immigration departments, government agencies, professional bodies and multinational companies.'
        },
        {
            id: 2,
            title: 'Why are there two versions of IELTS?',
            body: 'There are two types of the IELTS test: IELTS Academic and IELTS General Training. The IELTS Academic test is for people applying for higher education or professional registration in an English-speaking environment. It reflects some of the features of academic language and assesses whether you are ready to begin studying or training.The IELTS General Training test is for those who are going to English speaking countries for secondary education, work experience or training programs. It is also a requirement for migration to Australia, Canada, New Zealand and the UK.The test focuses on basic survival skills in broad social and workplace contexts. Listening and Speaking are the same for both tests, but the subject matter of the Reading and Writing sections differs depending on which test you take.'
        },
        {
            id: 3,
            title: 'What is a good score for IELTS?',
            body: 'As per British Council, scoring a 9 in the exam means that you are an “expert” in English, while getting an 8 shows that you are “very good” and so on. Generally, the test score varies from 1 to 9, i.e. non-user to expert and 0 for “did not attempt”. Register for our Leverage Live classes and get an IELTS score between 6-9.'
        },
        {
            id: 4,
            title: 'How long is the IELTS test score valid?',
            body: 'The IELTS results are valid for two years from the result declaration date, but it also depends on the organisation to set their validity for different purposes.'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'Which IELTS test should I take?',
            body: 'Individual organisations have different requirements. Make sure you check which IELTS test the organisation you are applying to recognises. Note that you must know which version to take when you complete .'
        },
        {
            id: 2,
            title: 'How many times can you take the IELTS?',
            body: 'There is no certain time limit to retaking the test, and it can be taken numerous times if the candidate wants to get the best score.'
        },
        {
            id: 3,
            title: 'How do I redeem a coupon?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 4,
            title: 'What does it take become an author?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        }
    ]
}

const paymentMethod = {
    leftColumn: [
        {
            id: 1,
            title: 'Is There Any One To One Support System?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 2,
            title: 'Do You Provide Career Guideline?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 3,
            title: 'How do I redeem a coupon?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 4,
            title: 'How do I get a refund?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'How do I get a refund?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 2,
            title: 'How do I redeem a coupon?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 3,
            title: 'How to Change my Plan using PayPal?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 4,
            title: 'Is There Any Prerequisites To Learn Python?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        }
    ]
}

const pricingPlan = {
    leftColumn: [
        {
            id: 1,
            title: 'Is There Any Prerequisites To Learn Python?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 2,
            title: 'How do I redeem a coupon?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 3,
            title: 'How to Change my Plan using PayPal?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 4,
            title: 'How do I get a refund?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        }
    ],
    rightColumn: [
        {
            id: 1,
            title: 'Do You Provide Career Guideline?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 2,
            title: 'How long it take to create a video course?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 3,
            title: 'Am I eligible for admission?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        },
        {
            id: 4,
            title: 'How long is my personal free trial?',
            body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
        }
    ]
}
        
const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext( AccordionContext );
    const decoratedOnClick = useAccordionButton( eventKey );
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={ decoratedOnClick } aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{ children }</button>
}

const FaqIELTS = ( { wrapperClass ,datas} ) => {
    const [selectedItem, setSelectedItem] = useState( 'online-education' );
    const [activeId1, setActiveId1] = useState( '0' );
    const [activeId2, setActiveId2] = useState( '0' );
    const [activeId3, setActiveId3] = useState( '0' );
    const [activeId4, setActiveId4] = useState( '0' );
    const [activeId5, setActiveId5] = useState( '0' );
    const [activeId6, setActiveId6] = useState( '0' );

    function toggleActive1( id ) {
        if (activeId1 === id) {
            setActiveId1(null);
        } else {
            setActiveId1(id);
        }
    }
    function toggleActive2( id ) {
        if (activeId2 === id) {
            setActiveId2(null);
        } else {
            setActiveId2(id);
        }
    }
    function toggleActive3( id ) {
        if (activeId3 === id) {
            setActiveId3(null);
        } else {
            setActiveId3(id);
        }
    }
    function toggleActive4( id ) {
        if (activeId4 === id) {
            setActiveId4(null);
        } else {
            setActiveId4(id);
        }
    }
    function toggleActive5( id ) {
        if (activeId5 === id) {
            setActiveId5(null);
        } else {
            setActiveId5(id);
        }
    }
    function toggleActive6( id ) {
        if (activeId6 === id) {
            setActiveId6(null);
        } else {
            setActiveId6(id);
        }
    }

    return (
        <>
            <SEO title="Frequently Asked Questions" />
            
                
                <div  style={{margin:"30px",marginBottom:"80px"}}  className={ `edu-accordion-area accordion-shape-1  bg-color-white ${ wrapperClass ? wrapperClass : '' } ` }>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="pre-title" style={{fontSize:"30px"}}>FaQ Questions</span>
                                    {/* <h3 className="title">Get Every General Answers <br /> From Here</h3> */}
                                </div>
                            </div>
                        </div>

                       
                        {/* { selectedItem === 'online-education' &&  */}
                            <div className="row g-5 align-items-center mt--20">
                                <div className="col-lg-12">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                datas.leftColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive1(i.toString())} bsPrefix={`edu-accordion-item ${activeId1 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6">
                                    <div className="accordion-style-1">
                                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                                            {
                                                datas.rightColumn.map( ( accordion, i ) => (
                                                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive2(i.toString())} bsPrefix={`edu-accordion-item ${activeId2 === i.toString() ? 'active-item' : ''}`}>
                                                        <div className="edu-accordion-header">
                                                            <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                                        </div>
                                                        <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                                    </Accordion.Item>
                                                ) )
                                            }
                                        </Accordion>
                                    </div>
                                </div> */}
                            </div>
                        {/* } */}

                        

                        
                    </div>
                </div>
            
        </>
    )
}

export default FaqIELTS;