import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import CourseFilterSidebar from './pages/course/CourseFilterSidebar';
import AboutUsOne from './pages/innerpages/AboutUsOne';
import AboutUsTwo from './pages/innerpages/AboutUsTwo';
import AboutUsThree from './pages/innerpages/AboutUsThree';
import BlogGridOne from './pages/blog/BlogGridOne';
import BlogGridTwo from './pages/blog/BlogGridTwo';
import BlogGridThree from './pages/blog/BlogGridThree';
import BlogCarousel from './pages/blog/BlogCarousel';
import BlogLoadMore from './pages/blog/BlogLoadMore';
import BlogStandard from './pages/blog/BlogStandard';
import BlogGridRightSidebar from './pages/blog/BlogGridRightSidebar';
import BlogGridLeftSidebar from './pages/blog/BlogGridLeftSidebar';
import BlogDetailsOne from './sections/Destination/UK/BlogDetailsOne';
import BlogDetailsTwo from './pages/detailspages/BlogDetailsTwo';
import BlogDetailsThree from './pages/detailspages/BlogDetailsThree';
import CategoryArchive from './pages/archive/CategoryArchive';
import TagArchive from './pages/archive/TagArchive';
import AuthorArchive from './pages/archive/AuthorArchive';
import ComingSoon from './pages/innerpages/ComingSoon';
import ContactMe from './pages/innerpages/ContactMe';
import ContactUs from './pages/innerpages/ContactUs';
import CourseOne from './pages/course/CourseOne';
import CourseTwo from './pages/course/CourseTwo';
import CourseThree from './pages/course/CourseThree';
import CourseFour from './pages/course/CourseFour';
import CourseFive from './pages/course/CourseFive';
import CourseFilterOne from './pages/course/CourseFilterOne';
import CourseFilterTwo from './pages/course/CourseFilterTwo';
import CourseCarousel from './pages/course/CourseCarousel';
import CourseLoadMore from './pages/course/CourseLoadMore';
import CourseCategoryArchive from './pages/archive/CourseCategoryArchive';
import CourseDetails from './pages/detailspages/CourseDetails';
import CourseDetailsTwo from './pages/detailspages/CourseDetailsTwo';
import EventGrid from './pages/innerpages/EventGrid';
import EventList from './pages/innerpages/EventList';
import EventLoadMore from './pages/innerpages/EventLoadMore';
import EventCarousel from './pages/innerpages/EventCarousel';
import EventDetails from './pages/detailspages/EventDetails';
import Faq from './pages/innerpages/Faq';
import GalleryGrid from './pages/innerpages/GalleryGrid';
import GalleryMasonry from './pages/innerpages/GalleryMasonry';
import GalleryLoadMore from './pages/innerpages/GalleryLoadMore';
import HomeOne from './pages/homepages/HomeOne';
import HomeTwo from './pages/homepages/HomeTwo';
import HomeThree from './pages/homepages/HomeThree';
import HomeFour from './pages/homepages/HomeFour';
import HomeFive from './pages/homepages/HomeFive';
import InstructorPageOne from './pages/innerpages/InstructorPageOne';
import InstructorPageTwo from './pages/innerpages/InstructorPageTwo';
import InstructorPageThree from './pages/innerpages/InstructorPageThree';
import LoginRegister from './pages/innerpages/LoginRegister';
import Pricing from './pages/innerpages/Pricing';
import PrivacyPolicy from './pages/innerpages/PrivacyPolicy';
import PurchaseGuide from './pages/innerpages/PurchaseGuide';
import Testimonial from './pages/innerpages/Testimonial';
import InstructorDetails from './pages/detailspages/InstructorDetails';
import Error from './pages/innerpages/Error';
import USAUniversities from './sections/UniversityPage/USA/Universities';
import NZUniversities from './sections/UniversityPage/NewZealand/Universities';
import CADUniversities from './sections/UniversityPage/Canada/Universities';
import UKUniversities from './sections/UniversityPage/UK/Universities';
import AUSUniversities from './sections/UniversityPage/Australia/Universities';
import Uni_Details from './sections/UniversityPage/UK/Uni_Details';
import Home from "./sections/Home/Home";
import './assets/scss/style.scss';
import EBook from './sections/ebook/eBook';
import Ielts from './sections/ielts/ielts';
import Destination from './sections/Destination/UK/destination';
import Content from './sections/Content/Content';
import AboutUs from './sections/About/About';
import { ForUniversity } from './sections/ForUniversity/ForUniversity';
import Course from './sections/Course/Course';
import { Scholarship } from './sections/ScholarShip/Scholarship';
import IeltsInnerr from './sections/ieltsInner/IeltsInnerr';
import GmatInnerBlog from './sections/Gmat/GmatInnerBlog';
import  IeltsInnerBlog from './sections/ieltsInnerBlog/ieltsInnerBlog';
import StudyAbroad from './sections/Community/StudyAbroad';
import CommunityIELTS from './sections/Community/CommunityIELTS';
import EbookkInner from './sections/ebookinner/EbookkInner';
import Gre from './sections/Gre/gre';
import GreInnerr from './sections/Gre/GreInnerr';
import GmatInnerr from './sections/Gmat/GmatInnerr';
import GreInnerBlog from './sections/Gre/GreInnerBlog';
import Gmat from './sections/Gmat/Gmat';
import Sat from './sections/Sat/Sat';
import SatInnerBlog from './sections/Sat/SatInnerBlog';
import SatInnerr from './sections/Sat/SatInnerr';
import Toefl from './sections/TOEFL/Toefl';
import ToeflInnerr from './sections/TOEFL/ToeflInnerr';
import ToeflInnerBlog from './sections/TOEFL/ToeflInnerBlog';
import Pte from './sections/PTE/Pte';
import PteInnerr from './sections/PTE/PteInnerr';
import USABlogDetailsOne from './sections/Destination/USA/DetailUsa';
import AusBlogDetailsOne from './sections/Destination/Australia/DetailAus';
import NZBlogDetailsOne from './sections/Destination//NewZealand/DetailNZ';
import GERBlogDetailsOne from './sections/Destination/Germany/DetailGer';
import CanBlogDetailsOne from './sections/Destination/Canada/DetailCan';
import Australia from './sections/Destination/Australia/Australia';
import Germany from './sections/Destination/Germany/Germany';
import NewZealand from './sections/Destination/NewZealand/NewZealand';
import USA from './sections/Destination/USA/USA';
import Canada from './sections/Destination/Canada/Canada';
import UniversityDetails from './sections/UniversityPage/UK/UniversityDetails';
import AddNewStudentApplicationApp from './StudentApplicationFlow/AddnewStudentApplication/AddNewStudentApplicationApp';
import AddNewStudentApplicationSelectCourseType from './StudentApplicationFlow/AddnewStudentApplication/AddNewStudentApplicationSelectCourseType';
import AddNewStudentApplicationSelectEducationLevel from './StudentApplicationFlow/AddnewStudentApplication/AddNewStudentApplicationSelectEducationLevel';
import AddNewStudentApplicationSelectCountry from './StudentApplicationFlow/AddnewStudentApplication/AddNewStudentApplicationSelectCountry';
import AddNewStudentApplicationSelectMajor from './StudentApplicationFlow/AddnewStudentApplication/AddNewStudentApplicationSelectMajor';
import { createBrowserRouter,createRoutesFromElements,RouterProvider } from 'react-router-dom';
import AddNewApplicationLayout from './StudentApplicationFlow/AddnewStudentApplication/AddNewApplicationLayout';
import AddNewStudentApplicationSelectLanguageTest from './StudentApplicationFlow/AddnewStudentApplication/AddNewStudentApplicationSelectLanguageTest';
import 'tailwindcss/tailwind.css'
import AddNewStudentApplicationSelectAcademicTest from './StudentApplicationFlow/AddnewStudentApplication/AddNewStudentApplicationSelectAcademicTest';
import AddNewApplicationCourseFinder from './StudentApplicationFlow/AddnewStudentApplication/AddNewApplicationCourseFinder';

function App() {
    // const router=createBrowserRouter(
    //     createRoutesFromElements(
    //       <Route path='/' element={<AddNewApplicationLayout/>}>
    //         <Route path='' element={<AddNewStudentApplicationApp/>}/>
    //           <Route path='country' element={<AddNewStudentApplicationSelectCountry/>}/>
    //           <Route path='coursetype' element={<AddNewStudentApplicationSelectCourseType/>}/>
    //           {/* <Route path='user/:userid' element={<User/>}/> */}
    //       </Route>
    //     )
    //   )
    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route exact path={`${process.env.PUBLIC_URL + '/'}`} element={<Home/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/home-1'}`} element={<HomeOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/home-2'}`} element={<HomeTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/home-3'}`} element={<HomeThree/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/home-4'}`} element={<HomeFour/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/home-5'}`} element={<HomeFive/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/StudyAbroad'}`} element={<StudyAbroad/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/CommunityIELTS'}`} element={<CommunityIELTS/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/about-us-1'}`} element={<AboutUsOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/about-us-2'}`} element={<AboutUsTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/about-us-3'}`} element={<AboutUsThree/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/coming-soon'}`} element={<ComingSoon/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/contact-me'}`} element={<ContactMe/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/contact-us'}`} element={<ContactUs/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-1'}`} element={<CourseOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-2'}`} element={<CourseTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-3'}`} element={<CourseThree/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-4'}`} element={<CourseFour/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-5'}`} element={<CourseFive/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-filter-1'}`} element={<CourseFilterOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-filter-2'}`} element={<CourseFilterTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-carousel'}`} element={<CourseCarousel/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-load-more'}`} element={<CourseLoadMore/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-category/:slug'}`} element={<CourseCategoryArchive/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-details/:id'}`} element={<CourseDetails/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-details-two/:id'}`} element={<CourseDetailsTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/course-filter-sidebar'}`} element={<CourseFilterSidebar />}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/event-grid'}`} element={<EventGrid/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/event-list'}`} element={<EventList/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/event-load-more'}`} element={<EventLoadMore/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/event-carousel'}`} element={<EventCarousel/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/event-details/:id'}`} element={<EventDetails/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/faq'}`} element={<Faq/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/gallery-grid'}`} element={<GalleryGrid/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/gallery-masonry'}`} element={<GalleryMasonry/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/gallery-load-more'}`} element={<GalleryLoadMore/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-one'}`} element={<InstructorPageOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-two'}`} element={<InstructorPageTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-three'}`} element={<InstructorPageThree/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/pricing'}`} element={<Pricing/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/privacy-policy'}`} element={<PrivacyPolicy/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/purchase-guide'}`} element={<PurchaseGuide/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/testimonial'}`} element={<Testimonial/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/instructor-details/:slug'}`} element={<InstructorDetails/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-1'}`} element={<BlogGridOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-2'}`} element={<BlogGridTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-3'}`} element={<BlogGridThree/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-carousel'}`} element={<BlogCarousel/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-load-more'}`} element={<BlogLoadMore/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-standard'}`} element={<BlogStandard/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-right-sidebar'}`} element={<BlogGridRightSidebar/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-grid-left-sidebar'}`} element={<BlogGridLeftSidebar/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/login-register'}`} element={<LoginRegister/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-details/:id'}`} element={<BlogDetailsOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-detailsUSA/:id'}`} element={<USABlogDetailsOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-detailsAus/:id'}`} element={<AusBlogDetailsOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-detailsNZ/:id'}`} element={<NZBlogDetailsOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-detailsGER/:id'}`} element={<GERBlogDetailsOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-detailsCanada/:id'}`} element={<CanBlogDetailsOne/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-details-left-sidebar/:id'}`} element={<BlogDetailsTwo/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/blog-details-right-sidebar/:id'}`} element={<BlogDetailsThree/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/category/:slug'}`} element={<CategoryArchive/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/tag/:slug'}`} element={<TagArchive/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/author/:slug'}`} element={<AuthorArchive/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/eBook'}`} element={<EBook/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/EbookkInner/:id'}`} element={<EbookkInner/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/:name'}`} element={<Ielts/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/ieltsInnerr'}`} element={<IeltsInnerr/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/GreInnerr'}`} element={<GreInnerr/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/GmatInnerr'}`} element={<GmatInnerr/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/ToeflInnerr'}`} element={<ToeflInnerr/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/SatInnerr'}`} element={<SatInnerr/>}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL + '/Gre'}`} element={<Gre/>}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL + '/Gmat'}`} element={<Gmat/>}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL + '/Pte'}`} element={<Pte/>}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL + '/PteInner'}`} element={<PteInnerr/>}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL + '/Sat'}`} element={<Sat/>}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL + '/Toefl'}`} element={<Toefl/>}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL + '/InnerBlog/GRE/:id'}`} element={<GreInnerBlog/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/InnerBlog/GMAT/:id'}`} element={<GmatInnerBlog/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/InnerBlog/SAT/:id'}`} element={<SatInnerBlog/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/InnerBlog/TOEFL/:id'}`} element={<ToeflInnerBlog/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/destination/:name'}`} element ={<Destination/>}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL + '/destination/Australia'}`} element ={<Australia/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/destination/USA'}`} element ={<USA/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/destination/NZ'}`} element ={<NewZealand/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/destination/Germany'}`} element ={<Germany/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/destination/Canada'}`} element ={<Canada/>}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL + '/Content/:id'}`} element ={<Content/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/about-us'}`} element={<AboutUs/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/For-University'}`} element={<ForUniversity/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/Course'}`} element={<Course/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/scholarship'}`} element={<Scholarship/>}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL + '/universities/USA'}`} element={<USAUniversities/>}/> */}
                    <Route exact path={`${process.env.PUBLIC_URL + '/universities/:name'}`} element={<UKUniversities/>}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL + '/universities/Canada'}`} element={<CADUniversities/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/universities/Australia'}`} element={<AUSUniversities/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/universities/NewZealand'}`} element={<NZUniversities/>}/> */}

                    <Route exact path={`${process.env.PUBLIC_URL + '/universities/:id'}`} element={<Uni_Details/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/About'}`} element={<AboutUs/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/InnerBlog/IELTS/:id'}`} element={<IeltsInnerBlog/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/universities/:id'}`} element={<Uni_Details/>}/>                
                    <Route exact path={`${process.env.PUBLIC_URL + '/University/:name'}`} element={<UniversityDetails/>}/>
                    {/* <Route exact path={`${process.env.PUBLIC_URL + '/application'}`} element={<AddNewStudentApplicationApp/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/application/select-country'}`} element={<AddNewStudentApplicationSelectCountry/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/application/select-coursetype'}`} element={<AddNewStudentApplicationSelectCourseType/>}/>
                    <Route exact path={`${process.env.PUBLIC_URL + '/application/select-youreducation'}`} element={<AddNewStudentApplicationSelectEducationLevel/>}/> */}
                    {/* <Route exact path={`${process.env.PUBLIC_URL + '/application/select-major'}`} element={<AddNewStudentApplicationSelectMajor/>}>
                    
                    </Route> */}
                    <Route exact path={`${process.env.PUBLIC_URL}`+'/application'} element={<AddNewApplicationLayout />}>
                        <Route exact path='' element={<AddNewStudentApplicationApp />} />
                        <Route exact path='select-country' element={<AddNewStudentApplicationSelectCountry />} />
                        <Route exact  path='select-coursetype' element={<AddNewStudentApplicationSelectCourseType />} />
                        <Route exact path='select-youreducation' element={<AddNewStudentApplicationSelectEducationLevel />} />
                        <Route exact path='select-major' element={<AddNewStudentApplicationSelectMajor />} />
                        <Route exact path='select-youreducation' element={<AddNewStudentApplicationSelectEducationLevel/>}/>
                        <Route exact path='select-languagetest' element={<AddNewStudentApplicationSelectLanguageTest/>}/>
                        <Route exact path='select-academictest' element={<AddNewStudentApplicationSelectAcademicTest/>}/>
                        <Route exact path='course-finder' element={<AddNewApplicationCourseFinder/>}/>
                        {/* Add more nested routes as needed */}
                    </Route>
                    
                    <Route path='*' element={<Error/>}/>
                </Routes>
                
            </ScrollToTop>
        </Router>
        
    );
}

export default App;