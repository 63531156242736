import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';



const IeltsMidPart = ( { wrapperClass, styleClass, datas } ) => {
    return (
        <div className={ `row ${ wrapperClass || 'g-5 mt--25' }` }>
            { datas.map( ( data , i) => (
                // <ScrollAnimation 
                //     animateIn="fadeInUp"
                //     animateOut="fadeInOut"
                //     className={ `col-lg-4 col-md-6 col-12 ${ styleClass ? styleClass : '' }` }
                //     animateOnce={ true }
                //     key={ i }
                // >
                <div className='col-lg-4 col-md-6 col-12'>
                    <div className="service-card service-card-5">
                        <div className="inner">
                            {/* <div className="icon">
                                <i className={ data.icon }></i>
                            </div> */}
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.numberOfCourses }</p>
                            </div>
                        </div>
                    </div>
                    </div>
                // </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default IeltsMidPart;