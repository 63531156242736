import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants";  

export const GetUniversityAndCourse = createAsyncThunk('GetUniversityAndCourseSlice', async() => {
  const response = await publicRequest.get('/universityandcourse/GetUniversityAndCourseSlice');
//   console.log('This is response ', response)
  return response.data;
});

const GetUniversityAndCourseSlice = createSlice({
  name: 'GetUniversityAndCourse',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(GetUniversityAndCourse.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default GetUniversityAndCourseSlice.reducer;