import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const BannerFive = ( props ) => {
    const [toggler, setToggler] = useState(false);
    const videoLink = [
        props.videoLink || 'https://www.youtube.com/watch?v=pNje3bWz7V8'
    ];
    return (
        <>
        
        <Carousel showArrows={true} autoPlay={true} showThumbs={false} showStatus={false}>
            <div className="slider-area banner-slider bg-image d-flex align-items-center">
                <img src="/images/slider/canada-slide-1.jpeg" alt="Banner Images" />
            </div>
            <div className="slider-area banner-slider bg-image d-flex align-items-center">
                <img src="/images/slider/dubai-slide-1.jpeg" alt="Banner Images" />
            </div>
        </Carousel>
        </>
    )
}

export default BannerFive;  