import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants";  

export const GetAllMajors = createAsyncThunk('GetAllMajors', async() => {
  const response = await publicRequest.get('/major/getallmajors');
//   console.log('This is response ', response)
  return response.data;
});

const getallmajors = createSlice({
  name: 'GetAllMajors',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(GetAllMajors.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default getallmajors.reducer;