import axios from 'axios';

//export const BASE_URL = "https://msa.gtmfederation.com/api/v1/";
export const BASE_URL = "https://msa.gtmfederation.com/api/v1/";
//export const Main_URL = "https://www.test.vhospitality.in/";
// export const BASE_URL = "https://demo.3ipointofsale.com/travelapi/travel/";
//export const BASE_URL_IMAGE = "https://www.test.vhospitality.in/uploads/images/";

export const publicRequest = axios.create({
    baseURL:BASE_URL,
    headers:{
        // "x-api-key":"e387ef6beb3e8ed4e96c0096"
    }
});
