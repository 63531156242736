import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { slugify } from '../../../utils';
import emailjs from 'emailjs-com';
import Form from 'react-bootstrap/Form';

const CourseOne = () => {
    const { state } = useLocation();
    const data = state.data;
    const [show, setShow] = useState(false);
    const [reveal, setReveal] = useState(false);
    const handleClose = () =>{
        setShow(false);
        setReveal(true);
    }
    const handleShow = () => setShow(true);
    const loginShow = () =>{ setReveal(true);}
    const [result, setResult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_bxh6md3',
                'template_1g7v07n',
                e.target,
                'user_8Lx0gfI1ktOoeEN8DTV10'
            )
            .then((result) => {
                console.log(result.text);
            },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
        setResult(true);
    };

    setTimeout(() => {
        setResult(false);
    }, 5000);

    console.log(data)
    return ( 
        <div className={`edu-blog blog-type-2 mt--20 radius-small `}>
            <div className="inner ">
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>
                        <img src="https://images.leverageedu.com/university/courses.png" alt="Blog Thumb" />
                    </Link>
                </div>
                <div className="content">
                    <h6 className="title">
                        <Link to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>{data.course}</Link>
                    </h6>
                    <div className="status-group col-lg-12">
                        {/* <Link className="eduvibe-status status-05" to={process.env.PUBLIC_URL + `/category/${ slugify( data.categories.slice( 0, 1 ) ) }`}> */}
                            {/* <i className="icon-Double-arrow">{data.uniCourse}</i> */}
                            <h6>Course: {data.uniCourse}</h6>
                            {console.log(data.uniCourse)}

                            {/* </Link> */}
                        <ul className="blog-meta">
                            <li><i className="icon-Double-arrow"></i>Location: { data.uniLocation }</li>
                            <li><i className="icon-Double-arrow"></i>Qualification: { data.uniUrl }</li>
                            <li><i className="icon-Double-arrow"></i>Mode of Degree: { data.uniName }</li>
                            <li><i className="icon-Double-arrow"></i>Intake: { data.uniIntakes }</li>
                            <li><i className="icon-Double-arrow"></i>Fee: USD { data.uniName }</li>

                        </ul>
                    </div>
                    
                    <div className="blog-card-bottom mt--20">
                        {/* <ul className="blog-meta">
                            <li><i className="icon-calendar-2-line"></i>{ data.duration } duration</li>
                        </ul> */}
                        {/* original button  */}
                        {/* <div className="read-more-btn">
                            <Link className="btn-transparent" to={process.env.PUBLIC_URL + `/blog-details/${data.id}`}>Apply Now<i className="icon-arrow-right-line-right"></i></Link>
                        </div> */}
                        {/* my own button  */}

                        <div className="card-bottom">
                        <div className="price-list price-style-03">
                            <button className="rn-btn edu-btn" onClick={handleShow}>
                                <span>Apply now </span><i className="icon-arrow-right-line-right"></i>
                            </button>
                            <Modal show={show} centered onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ marginBottom: "5px" }}>Sign Up</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <form action="" onSubmit={sendEmail} style={{ margin: "30px" }}>
                                        <div className="col-lg-12" >
                                            <div className="form-group">
                                                <Form.Label><b>Full Name* </b></Form.Label>
                                                <input type="text" className="form-control form-control-lg" name="fullname" placeholder="Name*" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <Form.Label><b>Email Address*</b></Form.Label>
                                                <input type="email" className="form-control form-control-lg" name="email" placeholder="Email*" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <Form.Label><b> Mobile Number*</b></Form.Label>
                                                <input type="text" className="form-control form-control-lg" name="phone" placeholder="Phone" />
                                            </div>
                                        </div>


                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                {/* <textarea className="form-control" name="message" placeholder="Your Message"></textarea> */}
                                                <p>By continuing, you agree to msa Edu’s<a href='' style={{ color: "blue" }}> Terms & Conditions </a> and <a href='' style={{ color: "blue" }}>Privacy Policy </a>  </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <button className="rn-btn edu-btn w-100" type="submit">
                                                <span>Submit Now</span><i className="icon-arrow-right-line-right"></i></button>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <p></p>
                                                {/* <textarea className="form-control" name="message" placeholder="Your Message"></textarea> */}
                                                <p style={{ paddingLeft: "55px" }}>Already have an account? <a href=''onClick={handleClose} style={{ color: 'blue' }}>Login </a> </p>
                                            </div>
                                        </div>

                                        {/* { result ? <div className="form-group"><Result /></div>  : null } */}
                                    </form>
                                </Modal.Body>

                            </Modal>

                            {/* {
                                data.price === '0' ?
                                    <div className="price current-price">Free</div>
                                :
                                    <div className="price current-price">${data.price}</div>
                            }
                            { 
                                data.oldPrice && <div className="price old-price">${data.oldPrice}</div> 
                            } */}
                        </div>
                        {/* <ul className="edu-meta meta-01">
                            <li><i className="icon-account-circle-line"></i>{data.student} Students</li>
                        </ul> */}
                    </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default CourseOne;