import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Grid, IconButton, FormHelperText, TextField } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";

const schema = yup.object().shape({
  act: yup
    .string()
    .required("Please enter score")
    .matches("^(?:[1-9]|[1-2][0-9]|3[0-6])$", "Score must be between 1-36"),
  sat: yup
    .string()
    .required("Please enter score")
    .matches(
      "^([4-5][0-9]{2}|1[0-5][0-9]{2}|1600)$",
      "Score must be between 400-1600"
    ),
  gmat: yup
    .string()
    .required("Please enter score")
    .matches("^(5[4-8][0-9]|800)$", "Score must be in between 540-800"),
  gre_verbal: yup
    .string()
    .required("Please enter score")
    .matches("^(1[3-6][0-9]|170)$", "Verbal score must be in between 130-170"),
  gre_quant: yup
    .string()
    .required("Please enter score")
    .matches("^(1[3-6][0-9]|170)$", "Quant score must be in between 130-170"),
});

const defaultValues = {
  act: "",
  sat: "",
  gmat: "",
  gre_verbal: "",
  gre_quant: "",
};

const AddNewStudentApplicationSelectAcademicTest = () => {
  const { control, formState, handleSubmit, setError, setValue, watch } =
    useForm({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema),
    });
  const data = watch();
  const { isValid, dirtyFields, errors } = formState;

  const navigate = useNavigate();
  const state = useLocation();
  const mydata = state.state;
  const [SelectedOption, setSelectedOption] = useState();
  const [openCard, setOpenCard] = useState("");

  const handleOnBack = () => {
    navigate("/application/select-languagetest", { state: {educationallevel:mydata.educationallevel,majors:mydata.majors,track:mydata.track} });
  };
  console.log("this is state in select academic test",mydata)
  const handleOnContinue = () => {
    let academictest;
    if(mydata.track==="Bachelors")
    {
      academictest=data.sat!==""?{"sat":data.sat}:{"act":data.act}
    }
    else{
      academictest=data.gmat!==""?{"gmat":data.gmat}:{"gre":{"verbal":data.gre_verbal,"quant":data.gre_quant}}
    }
    
    navigate("/application/course-finder", { state: {academictest,...mydata} });
  };

  const handleOnACT = () => {
    console.log("button click");
    if (errors.act) {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else if (data.act.trim() == "") {
      console.log("inside else if");
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else {
      console.log("This is the data ", data);
      handleOnContinue();
    }
  };

  const handleOnSAT = () => {
    console.log("button click");
    if (errors.sat) {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else if (data.sat.trim() == "") {
      console.log("inside else if");
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else {
      console.log("This is the data ", data);
      handleOnContinue();
    }
  };

  const handleOnGMAT = () => {
    console.log("button click");
    if (errors.gmat) {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          Score
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else if (data.gmat.trim() == "") {
      console.log("inside else if");
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          Score
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else {
      console.log("This is the data ", data);
      handleOnContinue();
    }
  };

  const handleOnGRE = () => {
    console.log("button click");
    if (errors.gre_verbal || errors.gre_quant) {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          score
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else if (data.gre_verbal.trim() == "") {
      console.log("inside else if");
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          GRE verbal score
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else if (data.gre_quant.trim() == "") {
      console.log("inside else if");
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          GRE quant score
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else {
      console.log("This is the data ", data);
      handleOnContinue();
    }
  };

  const handleOnOptionSelected = (option) => {
    setSelectedOption(option);
    // Toggle openCard based on the selected option
    setOpenCard(option === "No Preference" ? "" : option);
  };

  return (
    <>
      <IconButton
        className="absolute z-10 text-black xl:flex mmd:flex md:hidden sm:hidden xs:hidden"
        onClick={handleOnBack}
      >
        <ArrowBackRoundedIcon style={{ fontSize: "32px" }} />
      </IconButton>

      <ToastContainer />

      <div
        className="
        flex w-full h-screen justify-center items-center fixed
        xxl:w-full xxl:h-screen xxl:fixed xxl:mt-0
        xl:w-full xl:h-screen xl:fixed xl:mt-10
        lg:w-full lg:h-screen lg:fixed lg:mt-5
        mmd:pb-64 mmd:w-screen mmd:min-h-screen mmd:absolute
        md:pb-0 md:w-screen md:min-h-screen md:absolute
        sm:pb-0 sm:w-screen sm:min-h-screen sm:absolute
        xs:pb-0 xs:w-screen xs:min-h-screen xs:absolute"
      >
        <img
          src="/images/applicationflow/countrybg.jpg"
          className="
            xxl:w-screen xxl:h-screen
            xl: w-screen xl:h-screen
            lg:w-screen lg:h-screen
            md:w-screen md:h-screen
            sm:w-screen sm:h-screen
            xs:w-screen xs:h-screen"
        />

        <div
          className={`justify-center items-center mb-28 absolute w-[800px] ${
            openCard === "ACT" ? "h-auto mt-24" : "h-[500px]"
          } ${openCard === "SAT" ? "h-auto mt-24" : "h-[500px]"} 
          ${openCard === "GMAT" ? "h-auto mt-24" : "h-[500px]"} 
          ${openCard === "GRE" ? "h-auto mt-24" : "h-[500px]"} 
           rounded-[30px] backdrop-filter bg-blue-700 backdrop-blur-md bg-opacity-10 hover:shadow-gray-500 shadow-xl  
           border-gray-600
          xxl:rounded-[30px] xxl:h-[490px]
          xl:w-[800px] xl:h-[300px] xl:mb-32 
          lg:w-[800px] lg:h-[490px] lg:mb-0 lg:mt-44
          mmd:w-fit mmd:min-h-[490px] mmd:mb-0
          md:w-fit md:min-h-[490px md:mb-0
          sm:w-fit sm:min-h-full sm:h-auto sm:mb-0
          xs:w-screen xs:min-h-full xs:h-auto xs:mb-0
          `}
        >
          {/* Heading */}
          <Grid textAlign={"center"}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label
                  className="
                  text-2.5xl mb-2 text-[#181a1b] tracking-wide font-bold mt-7 
                bg-slate-200 p-4 rounded-full bg-opacity-30
                  md:text-md text-center"
                >
                  Current Track : {mydata.track}
                </label>
              </Grid>

              <Grid item xs={12}>
                <label
                  className="
                  text-5xl mt-3 mb-10 text-black tracking-wide font-bold px-11
                  md:text-[20px] md:text-center
                  xs:text-[20px]"
                >
                  Which academic test have you taken OR are planning to take?
                </label>
                <hr className="border-black"></hr>
              </Grid>
            </Grid>
          </Grid>

          {/* Main Grid Div */}
          <div
            className="
              mb-12 
              lg:w-full lg:grid lg:grid-cols-3 
              md:w-full md:h-auto md:grid md:grid-cols-2 md:place-items-center md:mb-0
              mmd:w-full mmd:h-auto mmd:grid mmd:grid-cols-2 mmd:place-items-center mmd:mb-0
              sm:w-full sm:h-auto sm:grid sm:grid-cols-2 sm:place-items-center
              xs:w-full xs:h-auto xs:grid xs:grid-cols-2 xs:place-items-center"
          >
            {/* CONDITIONAL RENDERING IF MYDATA === BACHELORS */}

            {mydata.track === "Bachelors" && (
              <>
                {/* ACT */}
                <Grid item xs={4}>
                  <button
                    onClick={() => handleOnOptionSelected("ACT")}
                    className="mt-20 w-fit h-fit rounded-3xl hover:scale-105 transition-all ease-in"
                  >
                    {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                    <label
                      className={`${
                        SelectedOption === "ACT"
                          ? " bg-white"
                          : " bg-[#196be8] "
                      } ease-in-out transition-all bg-opacity-50 items-center py-3 pl-3 flex mt-10 rounded-full h-fit w-fit 
                      xs:py-0 xs:pl-0 xs:w-[150px] xs:h-[80px]`}
                    >
                      {SelectedOption === "ACT" && (
                        <div className=" w-24 h-24 rounded-full p-[3.5px] bg-blue-700 ml-2">
                          <CheckIcon
                            sx={{ fontSize: "2em", color: "white" }}
                            className=" h-20 pt-2 "
                          />
                        </div>
                      )}
                      {SelectedOption !== "ACT" && (
                        <div className=" w-24 h-24 rounded-full p-[3.5px] bg-white xs:p-0 xs:ml-3">
                          <img
                            src="/images/applicationflow/AcademicTest/ACT.png"
                            className=" h-20 pt-2 pl-2"
                          />
                        </div>
                      )}

                      <label className="text-black flex items-start justify-start font-extrabold tracking-wide text-[13px]  ml-5 w-36">
                        ACT
                      </label>
                    </label>
                  </button>
                </Grid>

                {/* SAT */}
                <Grid item xs={4}>
                  <button
                    onClick={() => handleOnOptionSelected("SAT")}
                    className="mt-20 w-fit h-fit rounded-3xl hover:scale-105 transition-all ease-in"
                  >
                    {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                    <label
                      className={`${
                        SelectedOption === "SAT"
                          ? " bg-white"
                          : " bg-[#196be8] "
                      } ease-in-out transition-all bg-opacity-50 items-center py-3 pl-3 flex mt-10 rounded-full h-fit w-fit 
                      xs:py-0 xs:pl-0 xs:w-[150px] xs:h-[80px]`}
                    >
                      {SelectedOption === "SAT" && (
                        <div className=" w-24 h-24 rounded-full p-[3.5px] bg-blue-700 ml-2">
                          <CheckIcon
                            sx={{ fontSize: "2em", color: "white" }}
                            className=" h-20 pt-2 "
                          />
                        </div>
                      )}
                      {SelectedOption !== "SAT" && (
                        <div className=" w-24 h-24 rounded-full p-[3.5px] bg-white xs:p-0 xs:ml-3">
                          <img
                            src="/images/applicationflow/AcademicTest/SAT.png"
                            className=" h-20 pt-2 pl-2"
                          />
                        </div>
                      )}

                      <label className="text-black flex items-start justify-start font-extrabold tracking-wide text-[13px]  ml-5 w-36">
                        SAT
                      </label>
                    </label>
                  </button>
                </Grid>

                {/* No preference */}
                <Grid item xs={4}>
                  <button
                    onClick={() => handleOnContinue()}
                    className="mt-20 w-fit h-fit rounded-3xl hover:scale-105 transition-all ease-in"
                  >
                    <label
                      className={` bg-cyan-600 ease-in-out transition-all bg-opacity-50 items-center py-3 px-10 flex mt-10 rounded-full h-fit w-fit `}
                    >
                      <div className=" h-24 pb-4 pl-1" />
                      <label className="text-black flex items-start justify-start font-extrabold tracking-wider text-[15px]   w-44 pr-5">
                        No Preference
                      </label>
                    </label>
                  </button>
                </Grid>
              </>
            )}

            {/* CONDITIONAL RENDERING IF MYDATA === BACHELORS */}
            {mydata.track === "Masters" && (
              <>
                {/* GMAT */}
                <Grid item xs={4}>
                  <button
                    onClick={() => handleOnOptionSelected("GMAT")}
                    className="mt-40 w-fit h-fit rounded-3xl hover:scale-105 transition-all ease-in"
                  >
                    {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                    <label
                      className={`${
                        SelectedOption === "GMAT"
                          ? " bg-white"
                          : " bg-[#196be8] "
                      } ease-in-out transition-all bg-opacity-50 items-center py-3 pl-3 flex  mt-10 rounded-full h-fit w-fit `}
                    >
                      {SelectedOption === "GMAT" && (
                        <div className=" w-24 h-24 rounded-full p-[3.5px] bg-blue-700">
                          <CheckIcon
                            sx={{ fontSize: "2em", color: "white" }}
                            className=" h-20 pt-2 "
                          />
                        </div>
                      )}
                      {SelectedOption !== "GMAT" && (
                        <div className=" w-24 h-24 rounded-full p-[3.5px] bg-white">
                          <img
                            src="/images/applicationflow/AcademicTest/GMAT.png"
                            className=" h-20 pt-2 pl-2"
                          />
                        </div>
                      )}

                      <label className="text-black flex items-start justify-start font-extrabold tracking-wide text-[13px]  ml-5 w-36">
                        GMAT
                      </label>
                    </label>
                  </button>
                </Grid>

                {/* GRE */}
                <Grid item xs={4}>
                  <button
                    onClick={() => handleOnOptionSelected("GRE")}
                    className="mt-40 w-fit h-fit rounded-3xl hover:scale-105 transition-all ease-in"
                  >
                    {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                    <label
                      className={`${
                        SelectedOption === "GRE"
                          ? " bg-white"
                          : " bg-[#196be8] "
                      } ease-in-out transition-all bg-opacity-50 items-center py-3 pl-3 flex  mt-10 rounded-full h-fit w-fit `}
                    >
                      {SelectedOption === "GRE" && (
                        <div className=" w-24 h-24 rounded-full p-[3.5px] bg-blue-700">
                          <CheckIcon
                            sx={{ fontSize: "2em", color: "white" }}
                            className=" h-20 pt-2 "
                          />
                        </div>
                      )}
                      {SelectedOption !== "GRE" && (
                        <div className="w-24 h-24 rounded-full p-[3.5px] bg-white overflow-hidden">
                          <img
                            src="/images/applicationflow/AcademicTest/GRE.jpg"
                            className="h-16 pt-3"
                          />
                        </div>
                      )}

                      <label className="text-black flex items-start justify-start font-extrabold tracking-wide text-[13px]  ml-5 w-36">
                        GRE
                      </label>
                    </label>
                  </button>
                </Grid>

                <Grid item xs={4}>
                  <button
                    onClick={() => handleOnContinue()}
                    className="mt-36 w-fit h-fit rounded-3xl hover:scale-105 transition-all ease-in"
                  >
                    <label
                      className={` bg-cyan-600 ease-in-out transition-all bg-opacity-50 items-center py-3  px-10 flex mt-10 rounded-full h-fit w-fit `}
                    >
                      <div className=" h-24 pb-4 pl-1" />
                      <label className="text-black flex items-start justify-start font-extrabold tracking-wider text-[15px]   w-44 pr-5">
                        No Preference
                      </label>
                    </label>
                  </button>
                </Grid>
              </>
            )}

            {/* No Preference */}
          </div>

          {/* Form Div */}
          <div className="flex w-full justify-center">
            {/*------------------------------------------------------------FOR ACT------------------------------------------------------------------*/}
            {/* Render input field if openCard is not empty */}
            {openCard === "ACT" && (
              <Grid item xs={12}>
                <div
                  className="mt-10"
                  style={{ display: openCard === "ACT" ? "flex" : "none" }} // Check if the openCard state is set to "Diploma"
                >
                  <div className="w-full justify-center">
                    <label
                      className="
                    flex justify-center items-center text-3xl text-black tracking-wider font-bold
                    mmd:text-[20px]
                    md:text-[15px]
                    sm:text-[15px]
                    xs:text-[15px]"
                    >
                      Enter your score: &nbsp;
                      <Controller
                        name="act"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              sx={{
                                "& input": {
                                  fontSize: "1.5rem",
                                },
                                "& .MuiFormHelperText-root": {
                                  fontSize: "1rem", // Adjust the font size here as needed
                                },
                              }}
                              {...field}
                              type="text"
                              id="act"
                              className="w-fit md:p-3 sm:p-3 xs:p-3"
                              error={errors.act}
                              helperText={
                                <>
                                  {errors?.act?.message && (
                                    <span className="text-red-700">
                                      {errors?.act?.message}
                                    </span>
                                  )}
                                </>
                              }
                              onKeyPress={(event) => {
                                // Prevent non-numeric characters
                                const charCode = event.which
                                  ? event.which
                                  : event.keyCode;
                                if (
                                  charCode > 31 &&
                                  (charCode < 48 || charCode > 57)
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              inputMode="numeric"
                              placeholder="Score"
                              required
                            />
                          </>
                        )}
                      />
                    </label>
                    <div className="flex justify-center">
                      <button
                        onClick={handleOnACT}
                        className="font-extrabold w-fit h-fit mt-10 mb-10 px-40 py-4 text-[20px] rounded-full bg-[#02133e] text-white"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            )}

            {/*------------------------------------------------------------FOR SAT------------------------------------------------------------------*/}
            {/* Render input field if openCard is not empty */}
            {openCard === "SAT" && (
              <Grid item xs={12}>
                <div
                  className="mt-10"
                  style={{ display: openCard === "SAT" ? "flex" : "none" }} // Check if the openCard state is set to "Diploma"
                >
                  <div className="w-full justify-center">
                    <label
                      className="
                    flex justify-center items-center text-3xl text-black tracking-wider font-bold
                    mmd:text-[20px]
                    md:text-[15px]
                    sm:text-[15px]
                    xs:text-[15px]"
                    >
                      Enter your score: &nbsp;
                      <Controller
                        name="sat"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              sx={{
                                "& input": {
                                  fontSize: "1.5rem",
                                },
                                "& .MuiFormHelperText-root": {
                                  fontSize: "1rem", // Adjust the font size here as needed
                                },
                              }}
                              {...field}
                              type="text"
                              id="act"
                              className="w-fit md:p-3 sm:p-3 xs:p-3"
                              error={errors.sat}
                              helperText={
                                <>
                                  {errors?.sat?.message && (
                                    <span className="text-red-700">
                                      {errors?.sat?.message}
                                    </span>
                                  )}
                                </>
                              }
                              onKeyPress={(event) => {
                                // Prevent non-numeric characters
                                const charCode = event.which
                                  ? event.which
                                  : event.keyCode;
                                if (
                                  charCode > 31 &&
                                  (charCode < 48 || charCode > 57)
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              inputMode="numeric"
                              placeholder="Score"
                              required
                            />
                          </>
                        )}
                      />
                    </label>
                    <div className="flex justify-center">
                      <button
                        onClick={handleOnSAT}
                        className="font-extrabold w-fit h-fit mt-10 mb-10 px-40 py-4 text-[20px] rounded-full bg-[#02133e] text-white"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            )}

            {/*------------------------------------------------------------FOR GMAT------------------------------------------------------------------*/}
            {/* Render input field if openCard is not empty */}
            {openCard === "GMAT" && (
              <Grid item xs={12}>
                <div
                  className="mt-10"
                  style={{ display: openCard === "GMAT" ? "flex" : "none" }} // Check if the openCard state is set to "Diploma"
                >
                  <div className="w-full justify-center">
                    <label
                      className="
                    flex justify-center items-center text-3xl text-black tracking-wider font-bold
                    mmd:text-[20px]
                    md:text-[15px]
                    sm:text-[15px]
                    xs:text-[15px]"
                    >
                      Enter your score: &nbsp;
                      <Controller
                        name="sat"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              sx={{
                                "& input": {
                                  fontSize: "1.5rem",
                                },
                                "& .MuiFormHelperText-root": {
                                  fontSize: "1rem", // Adjust the font size here as needed
                                },
                              }}
                              {...field}
                              type="text"
                              id="gmat"
                              className="w-fit md:p-3 sm:p-3 xs:p-3"
                              error={errors.gmat}
                              helperText={
                                <>
                                  {errors?.gmat?.message && (
                                    <span className="text-red-700">
                                      {errors?.gmat?.message}
                                    </span>
                                  )}
                                </>
                              }
                              onKeyPress={(event) => {
                                // Prevent non-numeric characters
                                const charCode = event.which
                                  ? event.which
                                  : event.keyCode;
                                if (
                                  charCode > 31 &&
                                  (charCode < 48 || charCode > 57)
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              inputMode="numeric"
                              placeholder="Score"
                              required
                            />
                          </>
                        )}
                      />
                    </label>
                    <div className="flex justify-center">
                      <button
                        onClick={handleOnGMAT}
                        className="font-extrabold w-fit h-fit mt-10 mb-10 px-40 py-4 text-[20px] rounded-full bg-[#02133e] text-white"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            )}

            {/*------------------------------------------------------------FOR GRE------------------------------------------------------------------*/}
            {/* Render input field if openCard is not empty */}
            {openCard === "GRE" && (
              <Grid item xs={12}>
                <div
                  className="mt-10"
                  style={{
                    display: openCard === "GRE" ? "flex" : "none",
                    justifyContent: "center",
                  }}
                >
                  <div className="w-full justify-center flex flex-col items-center">
                    <label className="text-3xl text-black tracking-wider font-bold mt-6">
                      Enter your score &nbsp;
                    </label>
                    <div className="flex">
                      <div className="mr-4">
                        <Grid item xs={12}>
                          <Controller
                            name="gre_quant"
                            control={control}
                            render={({ field }) => (
                              <>
                                <TextField
                                  sx={{
                                    "& input": {
                                      fontSize: "1.5rem",
                                    },
                                    "& .MuiFormHelperText-root": {
                                      fontSize: "1rem", // Adjust the font size here as needed
                                    },
                                  }}
                                  {...field}
                                  type="text"
                                  id="gre"
                                  className="w-fit md:p-3 sm:p-3 xs:p-3"
                                  error={errors.gre_quant}
                                  helperText={
                                    <>
                                      {errors?.gre_quant?.message && (
                                        <span className="text-red-700">
                                          {errors?.gre_quant?.message}
                                        </span>
                                      )}
                                    </>
                                  }
                                  onKeyPress={(event) => {
                                    // Prevent non-numeric characters
                                    const charCode = event.which
                                      ? event.which
                                      : event.keyCode;
                                    if (
                                      charCode > 31 &&
                                      (charCode < 48 || charCode > 57)
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                  inputMode="numeric"
                                  placeholder="Score"
                                  required
                                />
                              </>
                            )}
                          />
                        </Grid>
                      </div>

                      <div>
                        <Grid item xs={12}>
                          <Controller
                            name="gre_verbal"
                            control={control}
                            render={({ field }) => (
                              <>
                                <TextField
                                  sx={{
                                    "& input": {
                                      fontSize: "1.5rem",
                                    },
                                    "& .MuiFormHelperText-root": {
                                      fontSize: "1rem", // Adjust the font size here as needed
                                    },
                                  }}
                                  {...field}
                                  type="text"
                                  id="gre_verbal"
                                  className="w-fit md:p-3 sm:p-3 xs:p-3"
                                  error={errors.gre_verbal}
                                  helperText={
                                    <>
                                      {errors?.gre_verbal?.message && (
                                        <span className="text-red-700">
                                          {errors?.gre_verbal?.message}
                                        </span>
                                      )}
                                    </>
                                  }
                                  onKeyPress={(event) => {
                                    // Prevent non-numeric characters
                                    const charCode = event.which
                                      ? event.which
                                      : event.keyCode;
                                    if (
                                      charCode > 31 &&
                                      (charCode < 48 || charCode > 57)
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                  inputMode="numeric"
                                  placeholder="Score"
                                  required
                                />
                              </>
                            )}
                          />
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    onClick={handleOnGRE}
                    className=" font-extrabold w-fit h-fit px-40 py-4 mb-8 text-[20px] rounded-full bg-[#02133e] text-white"
                  >
                    Continue
                  </button>
                </div>
              </Grid>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewStudentApplicationSelectAcademicTest;
