import React from 'react';

const BannerOne = ( props ) => {
    return (
        <div className={`edu-blog-widget-2 widget-banner ${ props.extraClass || '' }`}>
            <div className="inner">
                <div className="content">
                    <div className="thumbnail">
                        <img className="w-100" src="https://img.freepik.com/free-photo/high-angle-shot-city-buildings-new-york-manhattan_181624-24684.jpg?size=626&ext=jpg&ga=GA1.2.1969068513.1678876008&semt=sph" alt="Banner Images" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerOne;