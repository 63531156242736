import React from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import BreadcrumbOne from '../../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../../common/Layout';
import SEO from '../../../common/SEO';
import Comment from '../../../pages/blog/Comment';
import { slugify } from '../../../utils';

import GERData from './GERData.json';

const GERBlogDetailsOne = () => {
    const { id } = useParams();
    const blogId = parseInt(id, 10);
    const dataItem =GERData.filter(blog => blog.id === blogId);
    const data = dataItem[0];
    return (
        <>
            <SEO title={ data.title } />
            <Layout>
                <BreadcrumbOne 
                    title={ data.title }
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog Details"
                />
                <div className="edu-blog-details-area  bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="blog-details-1 style-variation3">

                                    <div className="content-blog-top">
                                    {/* <h4 className="title" style={{fontSize:"40px"}}>{ data.title }</h4> */}
                                        <div className="content-status-top d-flex justify-content-between  align-items-center">
                                            {/* <div className="status-group">
                                                <Link className="text-uppercase eduvibe-status status-05 color-primary w-600" to={process.env.PUBLIC_URL + `/category/${ slugify( data.categories.slice( 0, 1 ) ) }`}>
                                                    {data.categories.slice(0, 1)}
                                                </Link>
                                            </div> */}
                                            
                                            <ul className="blog-meta">
                                                <li className="blog-author">
                                                    {/* <img src="/images/blog/author/author-small/author.png" alt="Blog Images" /> */}
                                                     <FaUserAlt/>
                                                {data.author}</li>
                                                <li><i className="icon-calendar-2-line"></i>{ data.date }</li>
                                                <li><i className="icon-discuss-line"></i>{ data.view }</li>
                                                <li><i className="icon-time-line"></i>{ data.readingTime }</li>
                                            </ul>
                                        </div>

                                        {/* <h4 className="title">{ data.title }</h4> */}

                                        <div className="thumbnail block-alignwide">
                                            <img className="radius-small w-100 mb--30" src={`${process.env.PUBLIC_URL}${data.image}`} alt="Blog Thumb" />
                                        </div>
                                    </div>

                                    <div className="blog-main-content">
                                        <p>
                                            {data.fullDetail}
                                        </p>
                                        {/* <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec the odio aea the dumm ipsumm ipsum that dolocons rsus mal suada and fadolorit to the consectetur elit. All the Lorem Ipsum generators on the Internet tend to repeat that predefined chunks as necessary making this the first true dummy generator on the Internet.Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec odio aea the dumm ipsumm ipsum that dolocons rsus mal suada and fadolorit to the consectetur elit.</p> */}
                                        {/* <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec at odio aea the dumm ipsumm ipsum that dolocons rsus mal suada and fadolorit to the consectetur elit. All the Lorem Ipsum generators on the Internet tend.</p> */}
                                        {/* <blockquote className="blockquote-style-1">“ This is a Quote quis eleifend quam adipiscing vitae aliquet bibendum enim facilisis gravida neque velit euismod in pellentesque massa placerat volutpat lacus laoreet non curabitured gravida odio aenean sed adipiscing.”
                                            <img className="quote-image" src="/images/icons/quote-2.png" alt="Quote Images" />
                                        </blockquote> */}

                                        <h5 className="title">{data.title1}</h5>
                                      <p> {data.detail1}</p>
                                      <h5 className="title">{data.title2}</h5>
                                      <p> {data.detail2}</p>
                                      <h5 className="title">{data.title3}</h5>
                                      <p> {data.detail3}</p>
                                      <h5 className="title">{data.title4}</h5>
                                      <p> {data.detail4}</p>
                                      <h5 className="title">{data.title5}</h5>
                                      <p> {data.detail5}</p>
                                   
                                     {data.dataa.map((list)=>{

 return(
    <li>{list}</li>
 )
                                     }
                                   
                                   
                                     )}
                                  
                                    

                                        <ul className="gallery gallery-column-2 mt--20">
                                            <li><img className="w-100" src={`${process.env.PUBLIC_URL}${data.image1}`} alt="Gallery Images" /></li>
                                            <li><img className="w-100"src={`${process.env.PUBLIC_URL}${data.image2}`} alt="Gallery Images" /></li>
                                        </ul>
                                        <h5>{data.title6}</h5>
                                        <p>
                                            {data.detail6}
                                        </p>
                                        <h5>{data.title7}</h5>
                                        <p>
                                            {data.detail7}
                                        </p>
                                        {data.dataa2.map((list2)=>{
                                            return(
                                                <li>{list2}</li>
                                            )
                                        })}
                                        <br></br>
                                          <h5>{data.title8}</h5>
                                        <p>
                                            {data.detail8}
                                        </p>
                                        <h5>{data.title9}</h5>
                                        <p>{data.detail9}</p>
                                        <h6 style={{color:"black",fontWeight:"bold"}}>  {data.faq1} </h6>
                                        {data.ans1.map((ans)=>{
                                            return(
                                                <li>{ans}</li>
                                            )
                                        })}
                                        <br></br>
                                            <h6 style={{color:"black",fontWeight:"bold"}}>  {data.faq2} </h6>
                                            <p>{data.ans2}</p>
                                            <h6 style={{color:"black",fontWeight:"bold"}}>  {data.faq3} </h6>
                                            <p>{data.ans3}</p>



                                        {/* <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec the odio aea the dumm ipsumm ipsum that dolocons rsus mal suada and fadolorit to the consectetur elit. All the Lorem Ipsum generators on the Internet tend to repeat that predefined chunks as necessary making this the first true dummy generator on the Internet.Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit.</p> */}

                                    </div>

                                    <div className="blog-tag-and-share mt--50">
                                        {/* { data.tags && data.tags.length > 0 &&
                                            <div className="blog-tag">
                                                <div className="tag-list bg-shade">
                                                    {
                                                        data.tags.map( ( tag, i ) => {
                                                            return (
                                                                <Link key={ i } to={process.env.PUBLIC_URL + `/tag/${ slugify( tag ) }`}>
                                                                    { tag }
                                                                </Link>
                                                            )
                                                        } )
                                                    }
                                                </div>
                                            </div>
                                        } */}
                                        <div className="eduvibe-post-share">
                                            <span>Share: </span>
                                            <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                            <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                            <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                            <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                        </div>
                                    </div>

                                    {/* <div className="blog-author-wrapper">
                                        <div className="thumbnail">
                                            <img src="/images/blog/author/author-medium/author-02.jpg" alt="Author Images" />
                                        </div>
                                        <div className="author-content">
                                            <h6 className="title">John Smith</h6>
                                            <p>Jhon Smith is an author, blogger, and designer living in a suburb of Washington, DC. When she’s not designing, blogging, or writing, Owen can be found with her head in a book or pinning like a madman.</p>
                                            <ul className="social-share icon-transparent">
                                                <li><a href="#"><i className="icon-Fb"></i></a></li>
                                                <li><a href="#"><i className="icon-linkedin"></i></a></li>
                                                <li><a href="#"><i className="icon-Pinterest"></i></a></li>
                                                <li><a href="#"><i className="icon-Twitter"></i></a></li>
                                            </ul>
                                        </div>
                                    </div> */}

                                    {/* <div className="blog-pagination">
                                        <div className="row g-5">
                                            <div className="col-lg-6">
                                                <div className="blog-pagination-list style-variation-2">
                                                    <a href="#">
                                                        <i className="ri-arrow-left-s-line"></i>
                                                        <span>Nam libero justo laoreet sit amet. Lacus sed viverra tellus in hac</span>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="blog-pagination-list style-variation-2 next-post">
                                                    <a href="#">
                                                        <span>Tempus imperdiet nulla malesuada pellentesque elit eget gravida</span>
                                                        <i className="ri-arrow-right-s-line"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="edu-comment-form mt--50">
                                        <Comment 
                                            url=""
                                            id={data.id}
                                            title={data.title}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default GERBlogDetailsOne;