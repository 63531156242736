import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Grid,
  IconButton,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  TextField,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
const schema = yup.object().shape({
  toefl: yup
    .string()
    .required("Please enter score")
    .matches(
      "^(0|[1-9][0-9]?|1[01][0-9]|120)$",
      "Score should be between 0-120"
    ),
  ilets: yup
    .string()
    .required("Please enter score")
    .matches("^(10|[0-9])$", "Score should be between 0-10"),
  pte: yup
    .string()
    .required("Please enter score")
    .matches("^(1[0-9]|[2-8][0-9]|90)$", "Score should be between 10-90"),
});

const defaultValues = {
  toefl: "",
  ilets: "",
  pte: "",
};

const AddNewStudentApplicationSelectLanguageTest = () => {
  const { control, formState, handleSubmit, setError, setValue, watch } =
    useForm({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema),
    });
  const data = watch();
  const { isValid, dirtyFields, errors } = formState;

  const navigate = useNavigate();
  const state = useLocation();
  const mydata = state.state;
  const [SelectedOption, setSelectedOption] = useState("");
  const [openCard, setOpenCard] = useState("");
console.log("this is state in select language test",mydata)
  const handleOnBack = () => {
    navigate("/application/select-major", { state: {educationallevel:mydata.educationallevel,track:mydata.track} });
  };

  const handleOnContinue = () => {
    let languagetest;
      languagetest=data.toefl!==""?
      {"toefl":data.toefl}
      :data.ilets!==""?{"ielts":data.ilets}
      :{"pte":data.pte}
    navigate("/application/select-academictest", { state: {languagetest,...mydata} });
  };

  const handleOnOptionSelected = (option) => {
    setSelectedOption(option);
    // Toggle openCard based on the selected option
    setOpenCard(option === "No Preference" ? "" : option);
  };

  const handleOnTOEFL = () => {
    console.log("button click");
    if (errors.toefl) {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          Score
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else if (data.toefl.trim() == "") {
      console.log("inside else if");
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          Score
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else {
      console.log("This is the data ", data);
      handleOnContinue();
    }
  };

  const handleOnIELTS = () => {
    if (errors.ilets) {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          score
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else if (data.ilets.trim() == "") {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          Score
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else {
      console.log("This is the data ", data);
      handleOnContinue();
    }
  };

  const handleOnPTE = () => {
    if (errors.pte) {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          Score
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else if (data.pte.trim() == "") {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          Score
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            top: "100px",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else {
      console.log("This is the data ", data);
      handleOnContinue();
    }
  };

  const handleOnPreference = () => {
    navigate("/application/select-academictest", { state: mydata });
  };

  return (
    <>
      <IconButton
        className="absolute z-10 text-black xl:flex mmd:flex md:hidden sm:hidden xs:hidden"
        onClick={handleOnBack}
      >
        <ArrowBackRoundedIcon style={{ fontSize: "32px" }} />
      </IconButton>

      <ToastContainer />

      <div
        className="
        flex w-full h-screen justify-center items-center fixed
        xxl:w-full xxl:h-screen xxl:fixed xxl:mt-0
        xl:w-full xl:h-screen xl:fixed xl:mt-10
        lg:w-full lg:h-screen lg:fixed lg:mt-5
        mmd:pb-64 mmd:w-full mmd:h-screen mmd:absolute
        md:pb-0 md:w-full md:h-screen md:absolute
        sm:pb-0 sm:w-screen sm:min-h-screen sm:absolute
        xs:pb-0 xs:w-screen xs:min-h-screen xs:absolute"
      >
        <img
          src="/images/applicationflow/countrybg.jpg"
          className="
            xxl:w-screen xxl:h-screen
            xl: w-screen xl:h-screen
            lg:w-screen lg:h-screen
            mmd:w-full mmd:h-screen
            md:w-full md:h-screen
            sm:w-screen sm:h-screen
            xs:w-screen xs:h-screen"
        />

        <div
          className={`
            justify-center items-center mb-28 absolute w-[800px] ${
              openCard === "TOEFL" ? "h-auto mt-24" : "h-[490px] mt-32"
            } ${openCard === "IELTS" ? "h-auto mt-24" : "h-[490px] mt-32"} ${
            openCard === "PTE" ? "h-auto mt-24" : "h-[490px] mt-32 "
          } rounded-[30px] backdrop-filter bg-blue-700 backdrop-blur-md bg-opacity-10 
            hover:shadow-gray-500 shadow-xl  border-gray-600
          xxl:rounded-[30px] xxl:h-[490px]
          xl:w-[800px] xl:h-[300px] xl:mb-32 
          lg:w-[800px] lg:h-[490px] lg:mb-0 lg:mt-44
          mmd:w-fit mmd:min-h-[490px] mmd:mb-0
          md:w-fit md:min-h-[490px] md:mb-0
          sm:w-fit sm:min-h-full sm:h-auto sm:mb-0
          xs:w-screen xs:min-h-full xs:h-auto xs:mb-0
          `}
        >
          {/* Heading */}
          <Grid container textAlign={"center"} style={{ cursor: "pointer" }}>
            <Grid item xs={12}>
              <label
                className="
                text-2.5xl mb-2 text-[#181a1b] tracking-wide font-bold mt-7 
              bg-slate-200 p-4 rounded-full bg-opacity-30
                md:text-md text-center
              "
              >
                Current Track : {mydata.track}
              </label>
            </Grid>

            <Grid item xs={12}>
              <label
                className="
                text-5xl mt-10 mb-10 text-black tracking-wide font-bold px-11
                md:text-[20px] md:text-center
                xs:text-[20px]
              "
              >
                Which Language Test have you given or are planning to take?
              </label>
              <hr
                className={`border-black ${
                  SelectedOption === "" ? "mb-20" : ""
                }`}
              ></hr>
            </Grid>
          </Grid>

          {/* Main Grid */}
          <div
            className="
              mb-12 
              xl:w-full xl:grid xl:grid-cols-4
              lg:w-full lg:grid lg:grid-cols-2 
              mmd:w-full mmd:h-auto mmd:grid mmd:grid-cols-2 mmd:place-items-center mmd:mb-0
              md:w-full md:h-auto md:grid md:grid-cols-2 md:place-items-center md:mb-0
              sm:w-full sm:h-auto sm:grid sm:grid-cols-2 sm:place-items-center
              xs:w-full xs:h-auto xs:grid xs:grid-cols-2 xs:place-items-center xs:overflow-x-clip"
          >
            {/* TOEFL */}
            <Grid item xs={3}>
              <button
                onClick={() => handleOnOptionSelected("TOEFL")}
                className="
                  w-fit h-fit rounded-3xl hover:scale-105 transition-all ease-in
                  xs:hover:scale-95"
              >
                {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                <label
                  className={`
                      flex h-fit w-fit 
                      ${
                        SelectedOption === "TOEFL"
                          ? " bg-white"
                          : " bg-[#196be8] "
                      } 
                      ease-in-out transition-all bg-opacity-50 items-center py-3 pl-3 mt-10 rounded-full 
                      xs:py-0 xs:pl-0 xs:w-[150px] xs:h-[80px]`}
                >
                  {SelectedOption === "TOEFL" && (
                    <div className="w-24 h-24 rounded-full p-[3.5px] bg-blue-700 ml-2">
                      <CheckIcon
                        sx={{ fontSize: "2em", color: "white" }}
                        className=" h-20 pt-2 "
                      />
                    </div>
                  )}
                  {SelectedOption !== "TOEFL" && (
                    <div className="w-32 h-24 rounded-full p-[3.5px] bg-white xs:p-0 xs:ml-3">
                      <img
                        src="/images/applicationflow/SelectLanguageTest/toefl.png"
                        className="h-20 pt-2 pl-3"
                      />
                    </div>
                  )}

                  <label 
                    className="text-black flex items-start justify-start font-extrabold tracking-wide text-[13px] ml-5 w-36 xs:text-[20px]"
                  >
                    TOEFL
                  </label>
                </label>
                {/* </div> */}
              </button>
            </Grid>

            {/* IELTS */}
            <Grid item xs={3}>
              <button
                onClick={() => handleOnOptionSelected("IELTS")}
                className="
                  w-fit h-fit rounded-3xl hover:scale-105 transition-all ease-in
                  xs:hover:scale-95"
              >
                {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                <label
                  className={`
                    flex  h-fit w-fit
                    ${
                      SelectedOption === "IELTS"
                        ? " bg-white"
                        : " bg-[#196be8] "
                    } ease-in-out transition-all 
                    bg-opacity-50 items-center py-3 pl-3 mt-10 rounded-full  
                    xs:py-0 xs:pl-0 xs:w-[150px] xs:h-[80px]`}
                >
                  {SelectedOption === "IELTS" && (
                    <div className=" w-24 h-24 rounded-full p-[3.5px] ml-2 bg-blue-700">
                      <CheckIcon
                        sx={{ fontSize: "2em", color: "white" }}
                        className=" h-20 pt-2 "
                      />
                    </div>
                  )}
                  {SelectedOption !== "IELTS" && (
                    <div className="w-32 h-24 rounded-full p-[3.5px] bg-white xs:p-0 xs:ml-3">
                      <img
                        src="/images/applicationflow/SelectLanguageTest/ielts.png"
                        className=" h-20 pt-2 pl-1"
                      />
                    </div>
                  )}
                  <label className="text-black flex items-start justify-start font-extrabold tracking-wide text-[13px] ml-5 w-36 xs:text-[20px]">
                    IELTS
                  </label>
                </label>
                {/* </div> */}
              </button>
            </Grid>

            {/* PTE */}
            <Grid item xs={3}>
              <button
                onClick={() => handleOnOptionSelected("PTE")}
                className="
                  w-fit h-fit rounded-3xl hover:scale-105 transition-all ease-in
                  xs:hover:scale-95"
              >
                {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                <label
                  className={`
                      flex  h-fit w-fit
                      ${
                        SelectedOption === "PTE"
                          ? " bg-white"
                          : " bg-[#196be8] "
                      } ease-in-out transition-all 
                      bg-opacity-50 items-center py-3 pl-3 mt-10 rounded-full  
                      xs:py-0 xs:pl-0 xs:w-[150px] xs:h-[80px]`}
                >
                  {SelectedOption === "PTE" && (
                    <div className=" w-24 h-24 rounded-full p-[3.5px] bg-blue-700 xs:ml-3">
                      <CheckIcon
                        sx={{ fontSize: "2em", color: "white" }}
                        className="h-20 pt-2 "
                      />
                    </div>
                  )}
                  {SelectedOption !== "PTE" && (
                    <div className="w-32 h-24 rounded-full overflow-hidden p-[3.5px] bg-white xs:p-0 xs:ml-3 ">
                      <img
                        src="/images/applicationflow/SelectLanguageTest/pte.png"
                        className=" h-32 pb-4 pl-0"
                      />
                    </div>
                  )}
                  <label className="text-black flex items-start justify-start font-extrabold tracking-wide text-[13px] ml-5 w-36 xs:text-[20px]">
                    PTE
                  </label>
                </label>
                {/* </div> */}
              </button>
            </Grid>

            {/* No Peference */}
            <Grid item xs={3}>
              <button
                onClick={() => handleOnPreference()}
                className="
                  w-fit h-fit rounded-3xl hover:scale-105 transition-all ease-in
                  xs:hover:scale-95"
              >
                {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                <label
                  className={` bg-cyan-600 ease-in-out transition-all bg-opacity-50 items-center py-3  px-10 flex  mt-10 rounded-full h-fit w-fit `}
                >
                  <div className=" h-24 pb-4 pl-1" />
                  <label className="text-black flex items-start justify-start font-extrabold tracking-wider text-[15px]   w-44 pr-5">
                    No Preference
                  </label>
                </label>
                {/* </div> */}
              </button>
            </Grid>
          </div>

          {/* Form div */}
          <div className="flex w-full justify-center">
            {/* -------------------------------------------------------------FOR TOEFL------------------------------------------------------------- */}

            {/* Render input field if openCard is not empty */}
            {openCard && (
              <Grid item xs={12}>
                <div
                  className="mt-10"
                  style={{ display: openCard === "TOEFL" ? "flex" : "none" }} // Check if the openCard state is set to "Diploma"
                >
                  <div className="w-full justify-center">
                    <label
                    className="
                      flex justify-center items-center text-3xl text-black tracking-wider font-bold
                      mmd:text-[20px]
                      md:text-[15px]
                      sm:text-[15px]
                      xs:text-[15px]"
                    >
                      Enter your score: &nbsp;
                      <Controller
                        name="toefl"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              sx={{
                                "& input": {
                                  fontSize: "1.5rem",
                                },
                                "& .MuiFormHelperText-root": {
                                  fontSize: "1rem", // Adjust the font size here as needed
                                },
                              }}
                              {...field}
                              type="text"
                              id="toefl"
                              className="w-fit md:p-3 sm:p-3 xs:p-3"
                              error={errors.toefl}
                              helperText={
                                <>
                                  {errors?.toefl?.message && (
                                    <span className="text-red-700">
                                      {errors?.toefl?.message}
                                    </span>
                                  )}
                                </>
                              }
                              onKeyPress={(event) => {
                                // Prevent non-numeric characters
                                const charCode = event.which
                                  ? event.which
                                  : event.keyCode;
                                if (
                                  charCode > 31 &&
                                  (charCode < 48 || charCode > 57)
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              inputMode="numeric"
                              placeholder="Score"
                              required
                            />
                          </>
                        )}
                      />
                    </label>
                    <div className="flex justify-center">
                      <button
                        onClick={handleOnTOEFL}
                        className="font-extrabold w-fit h-fit mt-10 mb-10 px-40 py-4 text-[20px] rounded-full bg-[#02133e] text-white"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            )}

            {/* ----------------------------------------------------------FOR IELTS---------------------------------------------------------------- */}
            {/* Render input field if openCard is not empty */}
            {openCard && (
              <Grid item xs={12}>
                <div
                  className="mt-10"
                  style={{ display: openCard === "IELTS" ? "flex" : "none" }} // Check if the openCard state is set to "Diploma"
                >
                  <div className="w-full justify-center">
                    <label
                      className="
                    flex justify-center items-center text-3xl text-black tracking-wider font-bold
                    mmd:text-[20px]
                    md:text-[15px]
                    sm:text-[15px]
                    xs:text-[15px]"
                    >
                      Enter your score: &nbsp;
                      <Controller
                        name="ilets"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              sx={{
                                "& input": {
                                  fontSize: "1.5rem",
                                },
                                "& .MuiFormHelperText-root": {
                                  fontSize: "1rem", // Adjust the font size here as needed
                                },
                              }}
                              {...field}
                              type="text"
                              id="ilets"
                              className="w-fit md:p-3 sm:p-3 xs:p-3"
                              error={errors.ilets}
                              helperText={
                                <>
                                  {errors?.ilets?.message && (
                                    <span className="text-red-700">
                                      {errors?.ilets?.message}
                                    </span>
                                  )}
                                </>
                              }
                              onKeyPress={(event) => {
                                // Prevent non-numeric characters
                                const charCode = event.which
                                  ? event.which
                                  : event.keyCode;
                                if (
                                  charCode > 31 &&
                                  (charCode < 48 || charCode > 57)
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              inputMode="numeric"
                              placeholder="Score"
                              required
                            />
                          </>
                        )}
                      />
                    </label>
                    <div className="flex justify-center">
                      <button
                        onClick={handleOnIELTS}
                        className="font-extrabold w-fit h-fit mt-10 mb-10 px-40 py-4 text-[20px] rounded-full bg-[#02133e] text-white"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            )}

            {/* ----------------------------------------------------------FOR PTE---------------------------------------------------------------- */}
            {/* Render input field if openCard is not empty */}
            {openCard && (
              <Grid item xs={12}>
                <div
                  className="mt-10"
                  style={{ display: openCard === "PTE" ? "flex" : "none" }} // Check if the openCard state is set to "Diploma"
                >
                  <div className="w-full justify-center">
                    <label
                      className="
                    flex justify-center items-center text-3xl text-black tracking-wider font-bold
                    mmd:text-[20px]
                    md:text-[15px]
                    sm:text-[15px]
                    xs:text-[15px]"
                    >
                      Enter your score: &nbsp;
                      <Controller
                        name="pte"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              sx={{
                                "& input": {
                                  fontSize: "1.5rem",
                                },
                                "& .MuiFormHelperText-root": {
                                  fontSize: "1rem", // Adjust the font size here as needed
                                },
                              }}
                              {...field}
                              type="text"
                              id="pte"
                              className="w-fit md:p-3 sm:p-3 xs:p-3"
                              error={errors.pte}
                              helperText={
                                <>
                                  {errors?.pte?.message && (
                                    <span className="text-red-700">
                                      {errors?.pte?.message}
                                    </span>
                                  )}
                                </>
                              }
                              onKeyPress={(event) => {
                                // Prevent non-numeric characters
                                const charCode = event.which
                                  ? event.which
                                  : event.keyCode;
                                if (
                                  charCode > 31 &&
                                  (charCode < 48 || charCode > 57)
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              inputMode="numeric"
                              placeholder="Score"
                              required
                            />
                          </>
                        )}
                      />
                    </label>
                    <div className="flex justify-center">
                      <button
                        onClick={handleOnIELTS}
                        className="font-extrabold w-fit h-fit mt-10 mb-10 px-40 py-4 text-[20px] rounded-full bg-[#02133e] text-white"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewStudentApplicationSelectLanguageTest;
