import React,{useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import { AppBar, Toolbar, Icon, DialogActions, Box, Typography, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const ViewAllDetailCoursePopUp = (props) => {
  const { openDeletePopupForAllDetail, setOpenDeletePopupForAllDetail, handleDeleteFalseForAllDetail, handleDeleteTrueForAllDetail, dataForAllDetail } = props;
  // console.log("here data",dataForAllDetail)
  const handleClose = () => {
    setOpenDeletePopupForAllDetail(false);
  };

  const handleapplyInSecondDialog=()=>{
    handleDeleteTrueForAllDetail()
      window.location.href = 'http://localhost:3001/sign-in';
  }
  {/* <AppBar position="static" elevation={0} className=''>
                    <Toolbar className="flex w-full">
                        <label>here are courses of choosen university</label>
                        <button
                            // size="large"
                            // edge="start"
                            // color="inherit"
                            // aria-label="menu"
                            // sx={{ mr: 2 }}
                            className="absolute mr-10 p-2.5 px-3 rounded-full right-0 hover:bg-[#ffff] hover:bg-opacity-25 hover:text-black hover:shadow-md "
                            onClick={handleClose}
                        >
                            <CloseIcon sx={{ fontSize: "2rem" }} />
                        </button>
                    </Toolbar>
                </AppBar> */}


  {/* <div
                                className=" text-black 
    justify-center items-center  w-full  h-full rounded-[30px] 
    backdrop-filter bg-blue-200 backdrop-blur-md bg-opacity-20 shadow-gray-500 
    shadow-xl border-gray-600"
                              >
 
                                <div className=" w-full h-full ">
                                  <div
                                    className="
          justify-center items-center w-full text-black  h-fit  mt-0  py-7 
          backdrop-filter bg-blue-500 backdrop-blur-md    tracking-wide
           "
                                  >
                                    <label className=" text-[30px] font-extrabold">
                                      {dataForAllDetail?.course_id?.course_short_name}-(
                                      {dataForAllDetail?.course_id?.course_name})
                                    </label>
                                  </div>
                                  <div className="w-full">
                                    <div className="w-full flex justify-between  items-center px-32  tracking-wide">
                                      <div className=" justify-center flex  items-center">
                                        <div className="w-fit mt-9 rounded-full overflow-hidden">
                                          <img
                                            alt="image"
                                            className=" h-40 w-40"
                                            src={dataForAllDetail?.university_id?.image}
                                          />
                                        </div>
                                        <div className="grid grid-rows-2 justify-start items-center tracking-wide ml-10 mt-5">
                                          <label className="flex justify-start text-[30px] tracking-wide">
                                            &nbsp;&nbsp;
                                            {
                                              dataForAllDetail?.university_id
                                                ?.university_name
                                            }
                                          </label>
                                          <label className="flex justify-start text-[20px] tracking-wide">
                                            {" "}
                                            &nbsp;&nbsp; &nbsp;&nbsp;St.
                                            Andrews, United Kingdom
                                          </label>
                                        </div>
                                      </div>
                                      
                                    </div>

                                    <div className="w-full justify-center items-center flex mt-20 mb-4 tracking-wider">
                                      <div className="flex justify-center items-center mx-5 tracking-wider">
                                        <label>
                                          <img
                                            src="/images/applicationflow/coursefinderimages/creditcard.svg"
                                            className=" h-24 w-20"
                                          />
                                        </label>
                                        <label className=" mt-2  ml-3">
                                          &nbsp; Tution Fees(in {dataForAllDetail?.currency}
                                          ) :- <br /> {dataForAllDetail.fee}/-
                                        </label>
                                      </div>
                                      <div className="flex justify-center items-center mx-5 tracking-wider">
                                        <label>
                                          <img
                                            src="/images/applicationflow/coursefinderimages/duration.png"
                                            className=" h-20 w-20"
                                          />
                                        </label>
                                        <label className=" ml-3">
                                          &nbsp;Course Duration:- <br />{" "}
                                          {dataForAllDetail?.course_id?.course_duration}{" "}
                                          Months
                                        </label>
                                      </div>
                                      <div className="flex justify-center items-center mx-5 tracking-wider">
                                        <label>
                                          <img
                                            src="/images/applicationflow/coursefinderimages/qulification.png"
                                            className=" h-20 w-20"
                                          />
                                        </label>
                                        <label className=" ml-3">
                                          Qualification(Min):- <br />{" "}
                                          {dataForAllDetail?.qualification}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="rounded-[20px] mt-9 ml-14 flex justify-center items-center bg-transparent rounded-l-none rounded-t-none w-fit h-fit  overflow-hidden  ">
                                        <button class="rounded-[20px]  justify-center px-16 py-7   border-transparent bg-blue-300 backdrop-blur-md bg-opacity-50 overflow-hidden  relative  w-full cursor-pointer flex items-center border-blue-300 group">
                                          <span class="text-black flex h-full justify-center items-center text-[20px] rounded-[20px] border-transparent rounded-l-none font-semibold  transform transition-all duration-300 ">
                                            Apply
                                          </span>
                                          <span class="absolute right-0 h-full w-0 rounded-lg bg-blue-300 flex items-center justify-center transition-all duration-300 group-hover:w-full">
                                            <span class="text-blue-300 first:opacity-0 group-hover:opacity-100 group-hover:text-black w-full h-full justify-center items-center flex text-[30px] font-semibold">
                                              <ArrowForwardIcon
                                                sx={{ fontSize: "3rem" }}
                                              />
                                            </span>
                                          </span>
                                        </button>
                                      </div>
                                </div>
                                
                                </div>  */}
  return (
    <>
      <Dialog
        classes={{
          paper: 'rounded-3xl h-full w-[670px]',
        }}
        
        open={openDeletePopupForAllDetail}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        className=' flex justify-end items-end ml-5 '
      >


        <div class=" h-full grid place-items-start w-full font-mono bg-white">

          <div class="bg-blue-200  h-64 rounded-md rounded-r-none shadow-black shadow-md w-full">
            <div class="md:flex leading-none  ">

              <div class="flex h-fit w-fit  ">
                <img
                  src={dataForAllDetail?.university_id?.image}
                  alt="pic"
                  style={{
                    boxShadow:"0px 3px 12px black "
                  }}
                  class="h-56 w-56 rounded-full shadow-black  transform mx-3 translate-x-10  translate-y-16 border-4 border-gray-300 "
                />

              </div>
              <div class=" w-full justify-center items-center text-gray-300 ">
                <label className="flex  w-full justify-center text-[25px] text-black tracking-wide md:block my-4 text-sm text-left font-extrabold">
                  &nbsp;
                </label>
                <label className="flex mt-20 font-extrabold w-full justify-center  text-[27px] text-black tracking-tight">
                  {
                    dataForAllDetail?.university_id
                      ?.university_name
                  }
                </label>



              </div>

            </div>

            <div class="flex  w-full justify-end items-end  text-gray-300 ">

              <div className=' flex'>
                <div
                  class="h-32 w-80 z-40 mr-11 translate-y-8  bg-white rounded-md rounded-t-none shadow-black shadow-md transform ml-3   "
                >

                  <label className="flex font-serif font-extrabold ml-3  w-full items-center h-full justify-center text-[15px] text-black tracking-tight">
                    {dataForAllDetail?.course_id?.course_short_name}<br />
                    ({dataForAllDetail?.course_id?.course_name})

                  </label>

                </div>
              </div>
            </div>
            <div className="w-full h-full  mb-4 ">


              <div class="w-full items-center justify-center h-fit flex rounded-xl  -translate-y-8">
                <Grid
                  container
                  className=" flex items-center justify-center"
                >
                  <Grid item xs={0.5}>
                    <hr className=" border-solid border-2 border-blue-500 ring-2 " />
                  </Grid>
                  <Grid item xs={6}>

                    <button class="relative w-full sparkle-button flex items-center gap-1 text-lg font-semibold rounded-full bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                      <svg
                        class="absolute w-10 h-10 top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 text-blue-500"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                          fill="currentColor"
                        ></path>
                      </svg>

                      <svg
                        class="absolute w-10 h-10 bottom-0 right-0 transform translate-x-1/2 translate-y-1/2 text-blue-500"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.187 8.096L15 5.25L15.813 8.096C16.0231 8.83114 16.4171 9.50062 16.9577 10.0413C17.4984 10.5819 18.1679 10.9759 18.903 11.186L21.75 12L18.904 12.813C18.1689 13.0231 17.4994 13.4171 16.9587 13.9577C16.4181 14.4984 16.0241 15.1679 15.814 15.903L15 18.75L14.187 15.904C13.9769 15.1689 13.5829 14.4994 13.0423 13.9587C12.5016 13.4181 11.8321 13.0241 11.097 12.814L8.25 12L11.096 11.187C11.8311 10.9769 12.5006 10.5829 13.0413 10.0423C13.5819 9.50162 13.9759 8.83214 14.186 8.097L14.187 8.096Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M6 14.25L5.741 15.285C5.59267 15.8785 5.28579 16.4206 4.85319 16.8532C4.42059 17.2858 3.87853 17.5927 3.285 17.741L2.25 18L3.285 18.259C3.87853 18.4073 4.42059 18.7142 4.85319 19.1468C5.28579 19.5794 5.59267 20.1215 5.741 20.715L6 21.75L6.259 20.715C6.40725 20.1216 6.71398 19.5796 7.14639 19.147C7.5788 18.7144 8.12065 18.4075 8.714 18.259L9.75 18L8.714 17.741C8.12065 17.5925 7.5788 17.2856 7.14639 16.853C6.71398 16.4204 6.40725 15.8784 6.259 15.285L6 14.25Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M6.5 4L6.303 4.5915C6.24777 4.75718 6.15472 4.90774 6.03123 5.03123C5.90774 5.15472 5.75718 5.24777 5.5915 5.303L5 5.5L5.5915 5.697C5.75718 5.75223 5.90774 5.84528 6.03123 5.96877C6.15472 6.09226 6.24777 6.24282 6.303 6.4085L6.5 7L6.697 6.4085C6.75223 6.24282 6.84528 6.09226 6.96877 5.96877C7.09226 5.84528 7.24282 5.75223 7.4085 5.697L8 5.5L7.4085 5.303C7.24282 5.24777 7.09226 5.15472 6.96877 5.03123C6.84528 4.90774 6.75223 4.75718 6.697 4.5915L6.5 4Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <span className=' text-[15px]'>
                        &nbsp; &nbsp;Essential Infromation:-
                      </span>
                    </button>
                  </Grid>
                  <Grid item xs={5.5}>
                    <hr className=" border-solid border-2 ring-2 border-blue-500 " />
                  </Grid>
                </Grid>
              </div>

              {/* <Grid container className=' px-3 w-full'>
                <Grid item xs={5}> */}
              <div className='flex w-full'>
                <div className="flex w-1/2 justify-start items-center mx-5 my-6 ">
                  <label>
                    <img
                      src="/images/applicationflow/coursefinderimages/creditcard.svg"
                      className=" h-20 w-20"
                    />
                  </label>
                  <label className=" mt-2  ">
                    <label className=' font-extrabold text-[20px]'>&nbsp;{dataForAllDetail?.currency} {dataForAllDetail.fee}/-</label>
                    <br />
                    &nbsp;Tution Fees
                  </label>
                </div>

                {/* </Grid>

                                      <Grid item xs={5}> */}
                <div className="flex w-2/3 justify-start items-center mx-5 my-6">
                  <label>
                    <img
                      src="/images/applicationflow/coursefinderimages/qulification.png"
                      className=" h-20  w-20"
                    />
                  </label>
                  <label className=" mt-2  ">
                    <label className=' font-extrabold text-[20px]'>&nbsp;{dataForAllDetail?.qualification}</label>
                    <br />
                    &nbsp;Qualification(Min)
                  </label>
                </div>
              </div>
              {/* </Grid> */}

              {/* <Grid item xs={5}> */}
              <div className=' w-full flex'>

                <div className="flex w-1/2 justify-start items-center mx-5 my-6 ">
                  <label>
                    <img
                      src="/images/applicationflow/coursefinderimages/duration.png"
                      className="h-20 w-20"
                    />
                  </label>
                  <label className=" mt-2  ">
                    <label className=' font-extrabold text-[20px]'>&nbsp; {dataForAllDetail?.course_id?.course_duration} Months</label>
                    <br />
                    &nbsp;Course Duration
                  </label>
                </div>


                {/* </Grid>
                                      <Grid item xs={5}> */}
                <div className="flex w-2/3 justify-start items-center mx-5 my-6 ">
                  <label>
                    <img
                      src="/images/applicationflow/coursefinderimages/intake.png"
                      className="h-20 w-20"
                    />
                  </label>
                  <label className=" mt-2  ">
                    <label className=' font-extrabold text-[20px]'>&nbsp; {dataForAllDetail?.intake_month}</label>
                    <br />
                    &nbsp;Intake
                  </label>
                </div>


              </div>

              <div className=' w-full flex  px-40  mt-32 gap-12 '>
                <button onClick={handleDeleteFalseForAllDetail} class="rounded-[20px] shadow-2xl  justify-center  py-7   border-transparent bg-red-300 backdrop-blur-md  overflow-hidden  relative  w-fit cursor-pointer flex items-center border-red-300 group">
                  <span class="text-black flex h-full px-32 justify-center items-center text-[20px] rounded-[20px] border-transparent rounded-l-none   transform transition-all duration-300 ">
                    Back
                  </span>
                  <span class="absolute left-0 h-full w-0 rounded-lg bg-red-300 flex items-center justify-center transition-all duration-300 group-hover:w-full">
                    <span class="text-red-300 first:opacity-0 group-hover:opacity-100 group-hover:text-black w-full h-full justify-center items-center flex text-[30px] font-semibold">
                      <ArrowBackIcon
                        sx={{ fontSize: "3rem" }}
                      />
                    </span>
                  </span>
                </button>
                <button onClick={handleapplyInSecondDialog} class="rounded-[20px] justify-center shadow-2xl  py-7   border-transparent bg-blue-300 backdrop-blur-md  overflow-hidden  relative  w-fit cursor-pointer flex items-center border-blue-300 group">
                  <span class="text-black flex px-32 h-full justify-center items-center text-[20px] rounded-[20px] border-transparent rounded-l-none   transform transition-all duration-300 ">
                    Apply
                  </span>
                  <span class="absolute right-0 h-full w-0 rounded-lg bg-blue-300 flex items-center justify-center transition-all duration-300 group-hover:w-full">
                    <span class="text-blue-300 first:opacity-0 group-hover:opacity-100 group-hover:text-black w-full h-full justify-center items-center flex text-[30px] font-semibold">
                      <ArrowForwardIcon
                        sx={{ fontSize: "3rem" }}
                      />
                    </span>
                  </span>
                </button>
              </div>
            </div>


          </div>


        </div>


      </Dialog>
    </>
  );
};

export default ViewAllDetailCoursePopUp;