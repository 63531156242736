import { configureStore } from "@reduxjs/toolkit"; 
import GetCourseTypeSlice from "./GetCourseTypeSlice";
import GetMajorSlice from "./GetMajorSlice";
import GetCourseSlice from "./GetCourseSlice";
import GetUniversityAndCourseSlice from "./GetUniversityAndCourseSlice";
export const store = configureStore({
    reducer: {
        getmajors:GetMajorSlice,
        getcoursetype: GetCourseTypeSlice,
        getcourse:GetCourseSlice,
        getuniversityandcourse:GetUniversityAndCourseSlice,
    }
})