import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import { publicRequest } from "../../../constants";  

export const GetAllCourseType = createAsyncThunk('getcoursetype', async() => {
  const response = await publicRequest.get('/coursetype/getallcoursetype');
  // console.log('This is response ', response)
  return response.data;
});

const getallcoursetype = createSlice({
  name: 'getallcoursetype',
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(GetAllCourseType.fulfilled, (state, action)=> {
      state.data = action.payload
    })
  }
});

export default getallcoursetype.reducer;