import React from 'react'
import { Outlet } from 'react-router-dom'
import AddNewApplicationHeader from './AddNewApplicationHeader'
const AddNewApplicationLayout = () => {
    
  return (
    <>
   <AddNewApplicationHeader/>
   <Outlet/>
   </>
  )
}

export default AddNewApplicationLayout