import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import SEO from "../../../common/SEO";
import Layout from "../../../common/Layout";
import BreadcrumbOne from "../../../common/breadcrumb/BreadcrumbOne";
import EventData from "../../../data/event/EventData.json";
// import Main from "../../Main.json";
import UniversityData from "../../Destination/Main.json";
import { MdOutlineFactCheck, MdLocationPin } from "react-icons/md";
import { GiTeacher } from "react-icons/gi";
import { AiFillTrophy, AiFillCalendar } from "react-icons/ai";
import { BiMaleFemale } from "react-icons/bi";
import CourseOne from './CourseOne';
import Main from '../../Destination/Main.json';

const CourseItems = Main.slice(0, 9);
const EventDetails = () => {
  const [fix, setFixed] = useState(false);
  function setFixedSideBar() {
    if (window.scrollY >= 600) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }
  window.addEventListener("scroll", setFixedSideBar);
  const { state } = useLocation();
  // console.log(state);
  // const { id } = useParams();
  // const eventId = parseInt(id, 10);
  // const data = UniversityData.filter((event) => event.id === eventId);
  // const eventItem = data[0].universityDetailList[0];
  const eventItem = state.data;
  // console.log(eventItem);
  return (
    <>
      <SEO title={eventItem.title} />
      <Layout>
        <BreadcrumbOne
          title="Event Details"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Event Details"
        />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  <img src={eventItem.uniImageLarge} alt="Event Thumb" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-5">
                <div
                  className={
                    fix
                      ? "eduvibe-sidebar myCustomSidebar fixed"
                      : "eduvibe-sidebar myCustomSidebar"
                  }
                >
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">University Detail</h5>
                    <div className="widget-content">
                      <ul>
                        {eventItem.uniRank && (
                          <li>
                            <span>
                              <AiFillTrophy /> University Rank
                            </span>
                            <span>{eventItem.uniRank}</span>
                          </li>
                        )}
                        {eventItem.uniAcceptRate && (
                          <li>
                            <span>
                              <MdOutlineFactCheck /> Acceptance Rate
                            </span>
                            <span>{eventItem.uniAcceptRate}</span>
                          </li>
                        )}
                        {eventItem.uniStudentRatio && (
                          <li>
                            <span>
                              <GiTeacher /> Student Ratio
                            </span>
                            <span>{eventItem.uniStudentRatio}</span>
                          </li>
                        )}
                        {eventItem.uniIntakes && (
                          <li>
                            <span>
                              <AiFillCalendar /> Intakes
                            </span>
                            <span>{eventItem.uniIntakes}</span>
                          </li>
                        )}
                        {eventItem.uniStudentRatio && (
                          <li>
                            <span>
                              <BiMaleFemale /> Male: Female
                            </span>
                            <span>{eventItem.uniStudentRatio}</span>
                          </li>
                        )}
                        {eventItem.uniLocation && (
                          <li>
                            <span>
                              <MdLocationPin />
                              Location
                            </span>
                            <span>{eventItem.uniLocation}</span>
                          </li>
                        )}
                      </ul>

                      {/* <div className="read-more-btn mt--45">
                                                <Link className="edu-btn btn-bg-alt w-100 text-center" to="#">Price: $79.00</Link>
                                            </div> */}

                      <div className="read-more-btn mt--15">
                        <Link className="edu-btn w-100 text-center" to="#">
                          Visit University website
                        </Link>
                      </div>

                      <div className="read-more-btn mt--30 text-center">
                        <div className="eduvibe-post-share">
                          <span>Share: </span>
                          <a className="linkedin" href="#">
                            <i className="icon-linkedin"></i>
                          </a>
                          <a className="facebook" href="#">
                            <i className="icon-Fb"></i>
                          </a>
                          <a className="twitter" href="#">
                            <i className="icon-Twitter"></i>
                          </a>
                          <a className="youtube" href="#">
                            <i className="icon-youtube"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">{eventItem.uniName}</h3>
                  <h6>{eventItem.uniHeader1}</h6>
                  <p>{eventItem.uniPara1}</p>
                  <h6>{eventItem.uniHeader2}</h6>
                  <p>{eventItem.uniPara2}</p>
                  <h6>{eventItem.uniHeader3}</h6>
                  <p>{eventItem.uniPara3}</p>
                  <h6>{eventItem.uniHeader4}</h6>
                  <p>{eventItem.uniPara4}</p>
                  <ul className="column-gallery gallery-column-2">
                    <li>
                      <img src={eventItem.uniImage} alt="Gallery Images" />
                    </li>
                    <li>
                      <img src={eventItem.uniImage} alt="Gallery Images" />
                    </li>
                  </ul>
                  <h4>Four major elements that we offer:</h4>
                  <ul className="list-style-1 mb--20">
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i>{" "}
                      Struggling to sell one multi-million dollar home currently
                      on the market
                    </li>
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i> The
                      point of using Lorem Ipsum is that it has a normal
                      distribution of letters.
                    </li>
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i>{" "}
                      Publishing packages and web page editors now use their
                      default model text.
                    </li>
                    <li>
                      <i className="icon-checkbox-circle-fill-solid"></i>{" "}
                      Publishing packages and web page editors now use their
                      default model text.
                    </li>
                  </ul>
                  <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod te invidunt. ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam. et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                    ipsum dolor sit amet, consetetur sadipscing elitr.
                  </p>
                </div>
                <div className="edu-course-area edu-section-gap bg-color-white">
                    <div className="container">
                      <h4>Browse Courses</h4>
                        <div className="row g-5 mt--10">
                            { CourseItems !== undefined &&
                                CourseItems.map((item) => (
                                    <div className="col-12 col-sm-12 col-lg-6" key={ item.id }>
                                        <CourseOne state={{ data: item }} />
                                        {console.log(item)}
                                    </div>
                                ) )
                            }
                        </div>
                    </div>
                </div>
                <div className="event-widget event-widget-details mt--40">
                  <h5 className="title">Map</h5>
                  <div className="widget-content">
                    <div className="google-map">
                      <div className="eduvibe-google-map">
                        <iframe
                          className="radius-small w-100"
                          title="Google Map"
                          src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
                          height="290"
                          frameBorder="0"
                          style={{ border: 0 }}
                          allowFullScreen=""
                          aria-hidden="false"
                          tabIndex="0"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default EventDetails;
