import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import PostOne from '../../../components/post/PostOne';
import PostData from '../../../data/blog/PostData.json';
import UniversityData from './UniversityData.json';
// import CourseDataUK from '../../../data/course/CourseDataUK.json'
import CourseOne from './CourseOne';

function CourseList() {

    
        const [getAllItems] = useState( UniversityData );
        const [dataVisibleCount, setDataVisibleCount] = useState( 4 );
        const [dataIncrement] = useState( 4 );
        const [noMorePost, setNoMorePost] = useState( false );
        const [visibleItems, setVisibleItems] = useState( [] );
    
        useEffect( () => {
            setVisibleItems( getAllItems.filter( ( item ) => item.id <= dataVisibleCount ) );
        }, [] );
    
        const handleLoadMoreBtn = ( e ) => {
            e.preventDefault();
            let tempCount = dataVisibleCount + dataIncrement;
            if ( dataVisibleCount >= getAllItems.length ) {
                setNoMorePost( true );
            } else {
                setDataVisibleCount( tempCount );
                setVisibleItems( getAllItems.filter( ( data ) => data.id <= tempCount ) );
            }
        }

  return (
    <>
        <div className="col-lg-12">
            <div className="row g-6">
                { 
                visibleItems.map((item) => (
                    <div className="col-lg-6 col-md-6 col-12" key={ item.id }>
                        <CourseOne data={item} />
                    </div>
                                        ) )
                                    }
                                </div>
                                <div className="row text-center mt--60">
                                    <div className="col-lg-12">
                                        <button
                                            className="edu-btn"
                                            onClick={ handleLoadMoreBtn }
                                            disabled={ noMorePost ? 'disabled' : null }
                                        >
                                            { 
                                                noMorePost ? (
                                                    'All Posts Displayed'
                                                ) : (
                                                    <span>
                                                        Load More Courses
                                                        {/* <span className="eduvibe-spin-icon">
                                                            <FaSpinner />
                                                        </span> */}
                                                    </span>
                                                )
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
    </>
  )
}

export default CourseList