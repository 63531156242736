import React, { useState } from 'react';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import PostDataa from './PostDataa.json'
import { CoursesProvider } from '../../context/courses_context';
import { FilterProvider } from '../../context/filter_context';
import FaqIELTS from './faqIELTS';
import IeltsInner from './ieltsMidPart';
import IeltSteps from './ieltsSteps';
import IeltsFee from './ieltsfee';
import Sections from './Sections';
import Sections2 from './Sections2';
import PostOnee from './PostOnee';
import IeltsMidPart from './ieltsMidPart';
import { useParams } from 'react-router-dom';
import TestPrep from '../../sections/TestPrep.json'


const Ielts = () => {
    const { name } = useParams();
    const dataName = TestPrep.filter(blog => blog.name === name)
    const data = dataName[0];


    const [contentTab, setContentTab] = useState('overview');
    const handleTab = (content) => {
        if (content === 'overview') {
            setContentTab('overview');
        } else if (content === 'curriculum') {
            setContentTab('curriculum');
        } else if (content === 'instructor') {
            setContentTab('instructor');
        } else if (content === 'reviews') {
            setContentTab('reviews');
        }
    }
    return (
        <>
            <SEO title={data.title} />
            <Layout>
                <BreadcrumbOne
                    title={data.title}
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl={data.name}
                />
                <CoursesProvider>
                    <FilterProvider>
                        <div className="edu-course-area  bg-color-white">
                            <div className="container">
                                <div className="row g-5">
                                    <div className='course-details-content'>
                                        <ul className="edu-course-tab nav nav-tabs" role="tablist">
                                            <li className="nav-item">
                                                <button
                                                    className={contentTab === 'overview' ? 'nav-link active' : 'nav-link'}
                                                    type="button"
                                                    aria-label="Overview"
                                                // onClick={() => handleTab('overview')}
                                                >
                                                    <a href='#tip2'>ABOUT {data.name} </a>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={contentTab === 'curriculum' ? 'nav-link active' : 'nav-link'}
                                                    type="button"
                                                    aria-label="Curriculum"
                                                // onClick={() => handleTab('curriculum')}
                                                >
                                                    <a href='#tip3'> {data.name}  BASICS</a>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={contentTab === 'instructor' ? 'nav-link active' : 'nav-link'}
                                                    type="button"
                                                    aria-label="Instructor"
                                                // onClick={() => handleTab('instructor')}
                                                >
                                                    <a href='#tip4'>REGISTRATION</a>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={contentTab === 'reviews' ? 'nav-link active' : 'nav-link'}
                                                    type="button"
                                                    aria-label="Reviews"
                                                // onClick={() => handleTab('reviews')}
                                                ><a href='#tip1'> PREPARATION TIPS</a>

                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={contentTab === 'reviews' ? 'nav-link active' : 'nav-link'}
                                                    type="button"
                                                    aria-label="Reviews"
                                                // onClick={() => handleTab('reviews')}
                                                >
                                                    <a href="#tip5">FAQS </a>
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={contentTab === 'reviews' ? 'nav-link active' : 'nav-link'}
                                                    type="button"
                                                    aria-label="Reviews"
                                                    onClick={() => handleTab('reviews')}
                                                >

                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={contentTab === 'reviews' ? 'nav-link active' : 'nav-link'}
                                                    type="button"
                                                    aria-label="Reviews"
                                                    onClick={() => handleTab('reviews')}
                                                >

                                                </button>
                                            </li>


                                        </ul>
                                        <div id="tip2"><IeltsMidPart datas={data.items} /></div>


                                    </div>

                                    <h3 style={{ fontFamily: "sans-serif", color: "skyblue" }}>{data.title1}</h3>


                                    <h5 style={{ fontFamily: "sans-serif", color: "gray" }}>About Test</h5>
                                    <p style={{ fontFamily: "sans-serif", fontSize: "18px", marginTop: "1px" }}>{data.title1Content1}</p>


                                    <h5 id="tip3" style={{ fontFamily: "sans-serif", color: "gray" }} >Why Take the Taste?</h5>
                                    <div style={{ fontFamily: "sans-serif", fontSize: "18px", marginTop: "1px" }}>{data.title1Content2}</div>

                                    <h3 style={{ fontFamily: "sans-serif", color: "skyblue" }}>{data.title2}</h3>
                                    <h4 style={{ fontFamily: "sans-serif", marginTop: "2px", color: "gray" }}>Eligibility</h4>
                                    <div className="course-details-card mt--5">
                                        <div className="course-details-two-content">

                                            <div className="feature-style-6 mt--5">
                                                <div className="edu-feature-list color-variation-1">
                                                    <div className="icon">
                                                        <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                                    </div>
                                                    <div className="content">
                                                        <h6 className="title" style={{ fontFamily: "sans-serif" ,color:"gray"}}>Industry Expert Instructor</h6>
                                                        <p className="description"  style={{ fontFamily: "sans-serif", fontSize: "18px"}}>{data.title2Sub}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 style={{ fontFamily: "sans-serif", color: "gray" }}>General Facts</h4>
                                    <div className="course-details-card mt--10">
                                        <div className="course-details-two-content">

                                            <div className="feature-style-6 mt--10">
                                                <div className="edu-feature-list color-variation-1">

                                                    <div className="content">
                                                        <h6 className="title" style={{ color: "blue" }}>Date and Frequency</h6>
                                                        <p className="description" style={{ fontFamily: "sans-serif", fontSize: "18px"}}>{data.title2Sub1}</p>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="edu-feature-list color-variation-1">

                                                    <div className="content">
                                                        <h6 className="title" style={{ color: "blue" }}>Center and Admit Card</h6>
                                                        <p className="description"style={{ fontFamily: "sans-serif", fontSize: "18px"}}>{data.title2sub2}</p>
                                                    </div>
                                                </div>
                                                <br></br>

                                                <div className="edu-feature-list color-variation-1">

                                                    <div className="content">
                                                        <h6 className="title" style={{ color: "blue" }}>Result</h6>
                                                        <p className="description" style={{ fontFamily: "sans-serif", fontSize: "18px"}}>{data.title2sub3}</p>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="edu-feature-list color-variation-1">

                                                    <div className="content">
                                                        <h6 className="title" style={{ color: "blue" }}>Score Reporting</h6>
                                                        <p className="description" style={{ fontFamily: "sans-serif", fontSize: "18px"}}>{data.title2sub4}</p>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="edu-feature-list color-variation-1">

                                                    <div className="content">
                                                        <h6 className="title" style={{ color: "blue" }}>Score Validity</h6>
                                                        <p className="description" style={{ fontFamily: "sans-serif", fontSize: "18px"}}>{data.title2sub5}</p>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="edu-feature-list color-variation-1">

                                                    <div className="content">
                                                        <h6 className="title" style={{ color: "blue" }}>{data.title2Subtitl6}</h6>
                                                        <p className="description" style={{ fontFamily: "sans-serif", fontSize: "18px"}}>{data.title2sub6}</p>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="edu-feature-list color-variation-1">

                                                    <div className="content">
                                                        <h6 className="title" style={{ color: "blue" }}>{data.titleSubtitle7}</h6>
                                                        <p className="description" style={{ fontFamily: "sans-serif", fontSize: "18px"}}>{data.title2sub7}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h4 style={{ fontFamily: "sans-serif", color: "gray" }}>Sections</h4>

                                  
                                        <div className='row g-lg-5 g-md-5 g-sm-5'>
                                            

                                            <div className="col-lg-12 col-xl-6">

                                                <div className="course-details-card mt--5">
                                                    <div className="course-details-two-content">
                                                    {/* {data.section.map((list) => { */}
                                                        <div><h5 >{data.section[0].title1}</h5></div>
                                                        <Sections datas={data.section[0].part1}  />
                                                    {/* })} */}
                                                    </div>
                                                </div>
                                            </div>
                                            { data.section[1].part2.length  < 1 ? 
                                            <div> </div>
                                            :
                                            <div className="col-lg-12 col-xl-6">
                                                <div className="course-details-card mt--5">
                                                    <div className="course-details-two-content">
                                                        <div><h5>{data.section[1].title2} </h5></div>
                                                        <Sections2  datas={data.section[1].part2}/>

                                                    </div>
                                                </div>
                                             </div>
                                            }

                                        </div>
                                   
                                    <div id="tip4">  <IeltsFee datas={data.fees} /></div>

                                    <h4 style={{ fontFamily: "sans-serif", color: "gray" }}>{data.title3}</h4>
                                    <h7 style={{ fontFamily: "sans-serif", fontSize: "19px" }}>{data.title3Sub}</h7>
                                    <IeltSteps datas={data} />

                                    <h3 style={{ fontFamily: "sans-serif", color: "skyblue" }}>Prepartion Tips</h3>
                                    <div className="edu-elements-area bg-color-white">
                                        <div className="container">
                                            <div className="row g-5">
                                                <div className="col-lg-12">
                                                    <div className="row g-5">
                                                        {
                                                            data.blog.slice(0, 6).map((item) => (
                                                                <div className="col-lg-4 col-md-6 col-12" key={item.id}>
                                                                    <PostOnee data={item} data1={data.name} />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row text-center mt--60">
                                        {/* <div className="col-lg-12">
                                            <button
                                                className="edu-btn"

                                            >

                                                <span><a href='/ieltsInnerr' style={{ color: "white" }}>
                                                    Load More

                                                </a>

                                                </span>

                                            </button>
                                        </div> */}
                                    </div>
                                    {data.faq[0].leftColumn.length > 0 ?
                                   
                                    <div id="tip5">
                                    <FaqIELTS  datas={data.faq[0]}/>
                                   </div>
                                    :
                                    <div> </div> 
                                   
                                   }



                                </div>
                            </div>
                        </div>
                    </FilterProvider>
                </CoursesProvider>

                <div >

                </div>
            </Layout>
        </>
    )
}

export default Ielts;