import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import bg from '../../assets/images/bg/cityscape.jpg'

import {
  Link,
  Box,
  Paper,
  Button,
  AppBar,
  Toolbar,
  Icon,
  ButtonBase,
  Card,
  CardContent,
  DialogContent,
  Container,
  Dialog,
  div,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
  IconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import { LoadingButton } from "@mui/lab";
import { blue } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

export default function AddNewStudentApplicationSelectCountry() {
  const [Country, setCountry] = useState();
  const [Intake, setIntake] = useState();
  const [CourseType, setCourseType] = useState();
  const [University, setUniversity] = useState();
  const [Course, setCourse] = useState();
  const [loading, setLoading] = useState(false);

  // console.log(intakes);
  // console.log(CourseTypeApi)
  // console.log(UniversityApi)
  //    console.log(CourseApi)

  const [open, setOpen] = useState(true);

  const [uk, setUk] = useState(false);
  const [ukClicked, setUkClicked] = useState(false);

  const [us, setUs] = useState(false);
  const [usClicked, setUsClicked] = useState(false);
  const [countryImg, setCountryImg] = useState("");
  const [ind, setind] = useState(false);
  const [indClicked, setIndClicked] = useState(false);

  const [uniCourseArray, setuniCourseArray] = useState([]);
  const [uniCourseCounter, setuniCourseCounter] = useState(0);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  // var cardStyle1 = {
  //     display: 'flex',
  //     width: '150px',
  //     transitionDuration: '0.3s',
  //     height: '150px',
  //     alignItems: "center",
  //     justifyContent: "center",
  //     backgroundColor: uk ? '#603aa2' : '#2bccb160',
  //     color: 'white',

  // }

  const [countries, setcountries] = useState([]);

  // var cardStyle2 = {
  //     display: 'flex',
  //     width: '150px',
  //     transitionDuration: '0.3s',
  //     height: '150px',
  //     alignItems: "center",
  //     justifyContent: "center",
  //     backgroundColor: us ? '#603aa2' : '#2bccb160'
  // }

  // var cardStyle3 = {
  //     display: 'flex',
  //     width: '150px',
  //     transitionDuration: '0.3s',
  //     height: '150px',
  //     alignItems: "center",
  //     justifyContent: "center",
  //     backgroundColor: ind ? '#603aa2' : '#2bccb160'
  // }

  const handleClickUk = () => {
    if (ukClicked == false) {
      setUk(true);
      setUkClicked(true);
      setCountryImg("assets/images/flags/uk1.webp");
      setUs(false);
      setUsClicked(false);
      setind(false);
      setIndClicked(false);
      setCountry("UK");
      setOpen(false);
      console.log("Uk");
    } else {
      setUk(false);
      setUkClicked(false);
      setUs(false);
      setUsClicked(false);
      setind(false);
      setIndClicked(false);

      // setOpen(false);
    }
  };

  const handleClickUs = () => {
    if (usClicked == false) {
      setUs(true);
      setUsClicked(true);
      setCountryImg("assets/images/flags/USA.png");
      setUk(false);
      setUkClicked(false);
      setind(false);
      setIndClicked(false);
      setCountry("US");
      setOpen(false);
      console.log("US");
    } else {
      setUk(false);
      setUkClicked(false);
      setUs(false);
      setUsClicked(false);
      setind(false);
      setIndClicked(false);
      // setOpen(false);
    }
  };

  const handleClickInd = () => {
    if (indClicked == false) {
      setind(true);
      setIndClicked(true);
      setCountryImg("assets/images/flags/INDIA.webp");
      setUk(false);
      setUkClicked(false);
      setUs(false);
      setUsClicked(false);
      setCountry("IND");
      setOpen(false);
      console.log("IND");
    } else {
      setUk(false);
      setUkClicked(false);
      setUs(false);
      setUsClicked(false);
      setind(false);
      setIndClicked(false);
    }
  };

  const handleSelectedCountry = (e) => {
    setCountry(e.target.value);
  };

  const handleSelectedIntake = (e) => {
    setIntake(e.target.value);
  };

  const handleOnBack = () => {
    navigate("/application");
  };

  const handleOpenForAll = () => {
    setcountries(["US", "UK", "IND"]);
    navigate("/application/select-coursetype");
  };

  const handleparticular = () => {
    setcountries(
      [us ? "US" : "", uk ? "UK" : "", ind ? "IND" : ""].filter(
        (name) => name != ""
      )
    );
    navigate("/application/select-coursetype");
  };
  console.log(countries);
  return (
    <>
      <IconButton
        className="absolute z-10 text-black xxl:flex xl:flex lg:hidden mmd:hidden md:hidden sm:hidden xs:hidden"
        onClick={handleOnBack}
      >
        <ArrowBackRoundedIcon style={{ fontSize: "32px" }} />
      </IconButton>

      <div
        className="
        flex w-full h-screen justify-center items-center fixed
        xxl:w-full xxl:h-screen xxl:fixed
        xl:w-full xl:h-screen xl:fixed
        lg:w-full lg:h-screen lg:fixed
        mmd:pb-64 mmd:w-screen mmd:min-h-screen mmd:absolute
        md:pb-0 md:w-screen md:min-h-screen md:absolute
        sm:pb-0 sm:w-screen sm:min-h-screen sm:absolute
        xs:pb-0 xs:w-screen xs:min-h-screen xs:absolute"
      >
        <img
          src="/images/applicationflow/countrybg.jpg"
          className="
            xxl:w-screen xxl:h-screen
            xl: w-screen xl:h-screen
            lg:w-screen lg:h-screen
            md:w-screen md:h-screen
            sm:w-screen sm:h-screen
            xs:w-screen xs:h-screen"
        />
        <div
          className="
          justify-center items-center absolute  w-[800px] h-[490px] rounded-[30px] 
          backdrop-filter bg-blue-700 backdrop-blur-md bg-opacity-10 hover:shadow-gray-500 
          shadow-xl border-gray-600
          xxl:rounded-[30px] xxl:h-[490px]
          xl:w-[800px] xl:h-[300px] xl:mb-32 
          lg:w-[800px] lg:h-[490px] lg:mb-0 lg:mt-44
          mmd:w-fit mmd:min-h-[490px] mmd:mb-0
          md:w-fit md:min-h-[490px md:mb-0
          sm:w-fit sm:min-h-full sm:h-auto sm:mb-0
          xs:w-screen xs:min-h-full xs:h-auto xs:mb-0
          "
        >
          <Grid>
            <Grid item xs={12}>
              <label
                className="
                mt-32 text-5xl font-bold w-full mb-10 text-black text-center
                xxl:text-center
                xl:text-center xl:mb-10 xl:mt-10
                lg:text-[30px] lg:text-center 
                md:text-[20px] md:mb-0 md:text-center
                mmd:text-[20px] mmd:mb-0 mmd:text-center
                sm:text-[18px] sm:mb-0 sm:text-center sm:mt-5
                xs:text-[18px] xs:mb-0 xs:text-center xs:mt-5"
              >
                Which Country do you wish to pursue your education in?
              </label>
              <hr className=" border-black mb-28" />
            </Grid>
          </Grid>

          <div
            container
            textAlign={"center"}
            style={{ cursor: "pointer" }}
            className="
              mb-12 
              lg:w-full lg:grid lg:grid-cols-3 
              md:w-full md:h-auto md:grid md:grid-cols-3 md:place-items-center md:mb-0
              mmd:w-full mmd:h-auto mmd:grid mmd:grid-cols-3 mmd:place-items-center mmd:mb-0
              sm:w-full sm:h-auto sm:grid sm:grid-cols-2 sm:place-items-center
              xs:w-full xs:h-auto xs:grid xs:grid-cols-2 xs:place-items-center"
          >
            {/* <Grid container> */}

            <Grid item xs={4}>
              <div className="lg:w-64 md:w-44 sm:w-40 xs:w-36 ">
                <motion.button
                  className={`dark:focus-visible:outline-gray-500 w-64 bg-opacity-45  ${
                    uk ? "bg-sky-300" : "bg-gray-200"
                  } 
                h-auto focus:outline-gray-400 focus:scale-105  rounded-b-[30px] rounded-t-[30px]
                lg:w-64
                md:mt-6 md:rounded-[15px]
                mmd:mt-6 mmd:rounded-[15px]
                sm:w-44 sm:mt-6 sm:rounded-[15px] 
                xs:w-44 xs:mt-6 xs:rounded-[15px]
              `}
                  viewport={{ once: true }}
                  initial={{ opacity: 0.97, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ type: "easeIn", duration: 0.25 }}
                  whileHover={{ scale: 1.05 }}
                  onClick={handleClickUk}
                >
                  <div className="lg:w-[160px] rounded-[15px] shadow-lg mmd:w-[110px] mmd:object-cover md:w-[110px] md:object-cover sm:w-[100px] sm:object-cover">
                    <img
                      className="
                        object-cover w-[200px] h-[120px] rounded-t-[15px] shadow-lg
                        lg:w-[500px] lg:h-[100px]
                        mmd:w-[110px] mmd:h-[90px]
                        md:w-[110px] md:h-[90px]
                        xs:w-[100px] xs:h-[60px]"
                      // objectFit="cover"
                      // width={500}
                      // height={300}
                      // priority
                      src="https://cdn.britannica.com/25/4825-004-F1975B92/Flag-United-Kingdom.jpg"
                      alt={`Flag of UK`}
                    />
                    <div className="px-6 mt-3 flex justify-between items-start ">
                      <div className="w-full">
                        <h2
                          className="
                            text-[20px] font-extrabold mb-2  w-11/12 lg:w-10/12 xl:w-11/12 text-ellipsis 
                            overflow-hidden whitespace-nowrap 
                            lg:text-[20px]
                            mmd:text-[15px]
                            md:text-[15px]
                            sm:text-[15px] sm:ml-2
                            xs:text-[10px] xs:ml-2"
                        >
                          {"U. K."} &nbsp;{" "}
                          {uk && <CheckIcon sx={{ fontSize: "2rem" }} />}
                        </h2>
                      </div>
                    </div>
                  </div>
                </motion.button>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className="lg:w-64  mmd:w-44 md:w-44 sm:w-40 xs:w-36">
                <motion.button
                  className={`dark:focus-visible:outline-gray-500 w-64 bg-opacity-45 
                ${us ? "bg-sky-300" : "bg-gray-200"}
                h-auto focus:outline-gray-400 focus:scale-105 rounded-lg rounded-t-[30px] rounded-b-[30px]
                lg:w-64
                mmd:mt-6 mmd:rounded-3xl
                md:mt-6 md:rounded-3xl
                sm:w-44 sm:mt-6 sm:rounded-3xl
                xs:w-44 xs:mt-6 xs:rounded-3xl
              `}
                  viewport={{ once: true }}
                  initial={{ opacity: 0.97, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ type: "easeIn", duration: 0.25 }}
                  whileHover={{ scale: 1.05 }}
                  onClick={handleClickUs}
                >
                  <div className="lg:w-[160px] rounded-[15px] shadow-lg mmd:w-[110px] mmd:object-cover md:w-[110px] md:object-cover sm:w-[100px] sm:object-cover">
                    <img
                      className="
                      object-cover w-[200px] h-[120px]  rounded-t-[15px] shadow-lg
                      lg:w-[500px] lg:h-[100px] 
                      mmd:w-[200px] mmd:h-[90px]
                      md:w-[200px] md:h-[90px]
                      sm:w-[100px] sm:h-[60px]
                      xs:w-[100px] xs:h-[60px]"
                      // objectFit="cover"
                      // width={500}
                      // height={300}
                      // priority
                      src="https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg"
                      alt={`Flag of USA`}
                    />
                    <div
                      className="px-6 mt-3 flex justify-between items-start 
          "
                    >
                      <div className="w-full">
                        <h2
                          className="
                          text-[20px] font-extrabold mb-2 w-11/12 lg:w-10/12 xl:w-11/12 text-ellipsis 
                          overflow-hidden whitespace-nowrap 
                          lg:text-[20px]
                          mmd:text-[15px]
                          md:text-[15px]
                          sm:text-[15px] sm:ml-2
                          xs:text-[10px] xs:ml-2
                        "
                        >
                          {"U.S.A"} &nbsp;{" "}
                          {us && <CheckIcon sx={{ fontSize: "2rem" }} />}
                        </h2>
                      </div>
                    </div>
                  </div>
                </motion.button>
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className="lg:w-64  mmd:w-44 md:w-44 sm:w-40 xs:w-36">
                <motion.button
                  className={`dark:focus-visible:outline-gray-500 w-64 bg-opacity-45 
                ${ind ? "bg-sky-300" : "bg-gray-200"}
                h-auto focus:outline-gray-400 focus:scale-105 rounded-lg rounded-t-[30px] rounded-b-[30px]
                lg:w-64
                mmd:mt-6 mmd:rounded-3xl
                md:mt-6 md:rounded-3xl
                sm:w-44 sm:mt-6 sm:rounded-3xl
                xs:w-44 xs:mt-6 xs:rounded-3xl
                `}
                  viewport={{ once: true }}
                  initial={{ opacity: 0.97, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ type: "easeIn", duration: 0.25 }}
                  whileHover={{ scale: 1.05 }}
                  onClick={handleClickInd}
                >
                  <div className="lg:w-[160px] rounded-[15px] shadow-lg mmd:w-[110px] mmd:object-cover md:w-[110px] md:object-cover sm:w-[100px] sm:object-cover">
                    <img
                      className="
                        object-cover w-[200px] h-[120px] rounded-t-[15px] shadow-lg 
                        lg:w-[500px] lg:h-[100px]
                        mmd:w-[200px] mmd:h-[90px]
                        md:w-[200px] md:h-[90px]
                        sm:w-[100px] sm:h-[60px]
                        xs:w-[100px] xs:h-[60px]"
                      // objectFit="cover"
                      // width={500}
                      // height={300}
                      // priority
                      src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                      alt={`Flag of india`}
                    />
                    <div className=" px-6  mt-3 flex justify-between items-start ">
                      <div className="w-full">
                        <h2
                          className="
                          text-[20px] font-extrabold mb-2 w-11/12 lg:w-10/12 xl:w-11/12 text-ellipsis overflow-hidden 
                          whitespace-nowrap 
                          lg:text-[20px]
                          mmd:text-[15px]
                          md:text-[15px]
                          sm:text-[15px] sm:ml-2
                          xs:text-[10px] xs:ml-2"
                        >
                          India &nbsp;{" "}
                          {ind && <CheckIcon sx={{ fontSize: "2rem" }} />}
                        </h2>
                      </div>
                    </div>
                  </div>
                </motion.button>
              </div>
            </Grid>
          </div>
          {/* </Grid> */}

          {/* <Grid container textAlign={"center"} style={{ cursor: "pointer" }}>
                <Grid item xs={200}> */}
          {/* {!us && !uk && !ind && (
            <div
              className="
              flex w-full h-[200px] rounded-full items-center justify-center
              mmd:w-full mmd:h-[100px] mmd:items-center mmd:justify-center 
              md:w-full md:h-[100px] md:items-center md:justify-center
              sm:w-full sm:h-[100px] sm:items-center sm:justify-center
              xs:w-full xs:h-[100px] xs:items-center xs:justify-center
            "
            >
              <button
                //   onClick={handleClickData}
                className="
                  flex ease-in delay-100 mt-[33px] text-opacity-100 font-bold rounded-b-[30px] px-8 w-full text-black  
                  border-b-[12px] border-l-[15px] border-r-[15px] text-[20px] opacity-80  border-transparent bg-opacity-25 
                  bg-gray-200 rounded-t-none justify-center items-center
                  xxl:rounded-lg xxl:mt-[321px] xxl:rounded-b-[30px]
                  xl:rounded-lg xl:mt-[322px] xl:rounded-b-[30px] xl:w-full xl:py-14
                  lg:rounded-full lg:mt-[240px] lg:w-[300px] lg:h-[60px] lg:pt-5
                  mmd:rounded-full mmd:mb-[135px] mmd:w-[300px] mmd:h-[60px]
                  md:rounded-full md:mt-[56px] md:w-[250px] md:h-[50px]
                  sm:rounded-full sm:mt-5 sm:w-[200px] sm:h-[50px]
                  xs:rounded-full xs:mt-10 xs:w-[200px] xs:h-[50px]
                "
                onClick={handleOpenForAll}
              >
                Open For All
              </button>
            </div>
          )} */}

          {/* {(us || uk || ind) && ( */}
            <div
              className="
            flex w-full h-[200px] rounded-full items-center justify-center
            mmd:w-full mmd:h-[100px] mmd:items-center mmd:justify-center 
            md:w-full md:h-[100px] md:items-center md:justify-center
            sm:w-full sm:h-[100px] sm:items-center sm:justify-center
            xs:w-full xs:h-[100px] xs:items-center xs:justify-center
          "
            >
              <button
                //   onClick={handleClickData}
                className="
                flex ease-in delay-100 mt-[33px] text-opacity-100 font-bold rounded-b-[30px] px-8 w-full text-black  
                border-b-[12px] border-l-[15px] border-r-[15px] text-[20px] opacity-80  border-transparent bg-opacity-25 
                bg-gray-200 rounded-t-none justify-center items-center
                xxl:rounded-lg xxl:mt-[321px] xxl:rounded-b-[30px]
                xl:rounded-lg xl:mt-[322px] xl:rounded-b-[30px] xl:w-full xl:py-14
                lg:rounded-full lg:mt-[240px] lg:w-[300px] lg:h-[60px] lg:pt-5
                mmd:rounded-full mmd:mb-[135px] mmd:w-[300px] mmd:h-[60px]
                md:rounded-full md:mt-[56px] md:w-[250px] md:h-[50px]
                sm:rounded-full sm:mt-5 sm:w-[200px] sm:h-[50px]
                xs:rounded-full xs:mt-10 xs:w-[200px] xs:h-[50px]
              "
              disabled={!(ind || us || uk)}
                onClick={handleparticular}
              >
                Continue
              </button>
            </div>
          {/* )} */}
          {/* </Grid>
                </Grid> */}
        </div>
      </div>
    </>
  );
}
