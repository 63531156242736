import React, { useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import AboutUsTwoInstructor from '../../components/about-us-two/AboutUsTwoInstructor';
import AboutOne from '../../components/about/AboutOne';
import AboutUsTwoCountDown from '../../components/about-us-two/AboutUsTwoCountDown';

import { Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import FsLightbox from 'fslightbox-react';
import { FaPlay } from 'react-icons/fa';

import Featuree from './Featuree';
import AboutStudent from './AboutStudent';
import AboutInvestor from './AboutInvestor';
import SectionTitle from '../../components/sectionTitle/SectionTitle';



const AboutUs = () => {

    const [toggler, setToggler] = useState( false );
    const videoLink = ['https://www.youtube.com/watch?v=pNje3bWz7V8'];
    const items = [
        {
            title: 'OBJECTIVE',
            info: 'My Story Abroad Education Consultancy Pvt. Ltd. prides itself on the fact that it has been founded with the sole intention of assisting aspiring immigrants who intend to emigrate to prosperous foreign lands to realize their dreams and contribute to the development of global society.',
            icon: 'icon-Hand---Book'
        },
        {
            title: 'VISION',
            info: 'Education is the best way to empower the society. We work in an integrated manner to develop an ecosystem to help youth acquire the best possible education in an easy and hassle-free way across the globe.',
            icon: 'icon-Campus'
        },
        {
            title: 'MISSION',
            info: 'To provide expert guidance affordably so that young students from the Indian middle class get an opportunity to pursue the higher education of their interest and become successful global citizens.',
            icon: 'icon-Campus'
        }
    ];
    return (
        <>
            <SEO title="About Us " />
            <Layout>
                <BreadcrumbOne
                    title="About Us"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="About Us"
                />

                <Element
                    name="about-us"
                    className="edu-about-area about-style-1 edu-section-gap bg-color-white"
                >
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6">
                                <div className="about-image-gallery">
                                    <img className="image-1" src="https://static.vecteezy.com/system/resources/previews/010/891/581/original/study-abroad-card-template-education-in-foreign-university-editable-social-media-post-design-flat-color-illustration-for-poster-web-banner-ecard-vector.jpg" alt="About Main Thumb" />
                                    <div className="image-2">
                                        {/* <img src="https://www.applyboard.com/wp-content/uploads/2022/05/ab-world-1.png" alt="About Parallax Thumb" /> */}
                                    </div>
                                    <div className="badge-inner">
                                        <img className="image-3" src="/images/about/about-09/badge.png" alt="About Circle Thumb" />
                                    </div>
                                    <div className="shape-image shape-image-1">
                                        <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inner">
                                    <div className="section-title">
                                        <span className="pre-title">Our Mission, Vision & Objectives</span>
                                        <h4> We are building the global citizens of tomorrow</h4>
                                    </div>
                                    {/* <p className="description">The MSA platform is the ultimate solution for Institutions and Recruitment partners alike to unlock global access, gain awareness and achieve strategic objectives</p> */}
                                    {items && items.length > 0 &&
                                        <div className="about-feature-list">
                                            {items.map((data, i) => (
                                                <div className="our-feature" key={i}>
                                                    <div className="our-feature">
                                                        <div className="icon">
                                                            <i className={data.icon}></i>
                                                        </div>
                                                        <div className="feature-content">
                                                            <h6 className="feature-title">{data.title}</h6>
                                                            <p className="feature-description">{data.info}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {/* <div className="read-more-btn">
                                        <Link className="edu-btn" to="/about-us-1">Know About Us<i className="icon-arrow-right-line-right"></i></Link>
                                    </div> */}
                                    <div className="shape shape-6 about-parallax-2 d-xl-block d-none">
                                        <img src="/images/shapes/shape-07.png" alt="Shape Thumb" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Element>
                
                <div className="edu-service-area service-wrapper-3">
                <div className="about-us-two-service-wrapper edu-section-gapTop bg-image position-relative">
                    <div className="eduvibe-about-us-two-service">
                        <div className="container eduvibe-animated-shape">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        classes = "text-center"
                                        slogan = "Our Progress"
                                        title = "So far"
                                    />
                                </div>
                            </div>

                            <Featuree />

                            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                <div className="shape-image shape-image-1">
                                    <img src="/images/shapes/shape-29.png" alt="Shape Thumb" />
                                </div>
                                <div className="shape-image shape-image-2">
                                    <img src="/images/shapes/shape-02-06.png" alt="Shape Thumb" />
                                </div>
                            </div>
                        </div>
                    </div>
                        
                    <div className="eduvibe-about-us-two-video">
                        <div className="container eduvibe-animated-shape">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="video-gallery-wrapper edu-section-gapTop video-section-overlayto-another">
                                        <div className="video-gallery-1">
                                            <div className="thumbnail video-popup-wrapper">
                                                <img className="radius-small w-100" src="https://assets.studies-overseas.com/Multiple_Country_Options_for_Higher_Education_Overseas_ee6e556895.jpg" alt="Video PopUp Thumb" />
                                                <button className="video-play-btn with-animation position-to-top video-popup-activation btn-secondary-color size-80" onClick={ () => setToggler( ! toggler ) }>
                                                    <span><FaPlay className="play-icon" /></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                <div className="shape-image shape-image-1">
                                    <img src="/images/shapes/shape-07-01.png" alt="Shape Thumb" />
                                </div>
                                <div className="shape-image shape-image-2">
                                    <img src="/images/shapes/shape-03-02.png" alt="Shape Thumb" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FsLightbox 
                toggler={ toggler } 
                sources={ videoLink }
                maxYoutubeVideoDimensions={ { width: 900, height: 550 } }
            />

                <AboutStudent />

                {/* <AboutInvestor /> */}

                {/* <AboutOne /> */}

                {/* <AboutUsTwoCountDown /> */}

                {/* <TestimonialSectionOne /> */}

            </Layout>

        </>
    )
}

export default AboutUs;