import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  IconButton,
  TextField,
  Typography,
  createFilterOptions,
  Autocomplete,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { GetAllMajors } from "./store/GetMajorSlice";
import CheckIcon from "@mui/icons-material/Check";
import { GetAllCourse } from "./store/GetCourseSlice";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";

const AddNewStudentApplicationSelectMajor = () => {
  const navigate = useNavigate();
  const state = useLocation();
  const mydata = state.state;
  console.log("this is state in major  ",state.state)
  const [selected, setSelected] = useState([]);
  // useEffect(() => {

  //   setSelected([])
  // }, [])
  const [SearchedMajor, setSearchedMajor] = useState(null);
  const coursetype = state.data;

  const dispatch = useDispatch();

  const majordata = useSelector((state) => state.getmajors);
  const coursedata = useSelector((state) => state.getcourse);
  useEffect(() => {
    dispatch(GetAllMajors());
    dispatch(GetAllCourse());
  }, [selected]);
  const Course = coursedata.data;
  const Majors = majordata.data;
  console.log(Course);
  const handleOnBack = () => {
    navigate("/application/select-youreducation", { state: mydata.track });
  };
  const filterOptionsnew = createFilterOptions({
    matchFrom: "any",
    limit: 20,
  });

  const defaultValues = {
    Search_Major: { course_name: "" },
  };

  const {
    handleSubmit,
    setValue,
    register,
    reset,
    control,
    watch,
    formState,
    unregister,
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  const data = watch();

  const handleMajorSearch = () => {
    console.log("this is data", data?.Search_Major?.major?.name);
    setSearchedMajor(data?.Search_Major?.major?.name);
  };

  // const handlemajorselect = (name) => {
  // // console.log(name)
  // if (name == "Engineering") {
  //   setSelected((prev) => prev?.filter((n) => n === "Engineering").length > 0 ? setSelected(selected.filter((name) => name !== "Engineering")) : setSelected(selected?.length > 0 ? [...selected, "Engineering"] : ["Engineering"]))
  //   // const is=selected.filter((name)=>name==="Engineering")
  //   // console.log("this is",is)
  //   // if(is.length>0)
  //   // {
  //   //   setSelected(selected.filter((name)=>name!=="Engineering"))
  //   // }
  //   // else{
  //   //   setSelected((prev)=>[...prev,"Engineering"])
  //   // }
  // }
  // else if (name == "Arts") {
  //   setSelected((prev) => prev?.filter((n) => n === "Arts")?.length > 0 ? setSelected(selected.filter((name) => name !== "Arts")) : setSelected(selected?.length > 0 ? [...selected, "Arts"] : ["Arts"]))
  // }
  // else if (name == "photography") {
  //   setSelected((prev) => prev?.filter((n) => n === "photography")?.length > 0 ? setSelected(selected.filter((name) => name !== "photography")) : setSelected(selected?.length > 0 ? [...selected, "photography"] : ["photography"]))

  // }
  // else if (name == "Management") {
  //   setSelected((prev) => prev?.filter((n) => n === "Management")?.length > 0 ? setSelected(selected?.filter((name) => name !== "Management")) : setSelected(selected?.length > 0 ? [...selected, "Management"] : ["Management"]))
  // }
  // else if (name == "Finance and Banking") {
  //   setSelected((prev) => prev?.filter((n) => n === "Finance and Banking")?.length > 0 ? setSelected(selected?.filter((name) => name !== "Finance and Banking")) : setSelected(selected?.length > 0 ? [...selected, "Finance and Banking"] : ["Finance and Banking"]))
  // }
  // else if (name == "Medicine And Pharma") {
  //   setSelected((prev) => prev?.filter((n) => n === "Medicine And Pharma")?.length > 0 ? setSelected(selected?.filter((name) => name !== "Medicine And Pharma")) : setSelected(selected?.length > 0 ? [...selected, "Medicine And Pharma"] : ["Medicine And Pharma"]))
  // }
  // else if (name == "Computer And Data Science") {
  //   setSelected((prev) => prev?.filter((n) => n === "Computer And Data Science")?.length > 0 ? setSelected(selected?.filter((name) => name !== "Computer And Data Science")) : setSelected(selected?.length > 0 ? [...selected, "Computer And Data Science"] : ["Computer And Data Science"]))
  // }
  // else if (name == "Science") {
  //   setSelected((prev) => prev?.filter((n) => n === "Science")?.length > 0 ? setSelected(selected?.filter((name) => name !== "Science")) : setSelected(selected?.length > 0 ? [...selected, "Science"] : ["Science"]))
  // }
  // }

  const handlemajorselect = (name) => {
    if (name != "select all") {
      console.log("hmm seleting particulets", selected);
      setSelected((prev) =>
        prev.includes(name)
          ? prev.filter((item) => item !== name)
          : [...prev, name]
      );
    } else if (name == "select all") {
      setSelected([
        "Engineering",
        "Arts",
        "photography",
        "Management",
        "Finance and Banking",
        "Medicine And Pharma",
        "Computer And Data Science",
        "Science",
      ]);
      console.log("selecting everything");
    }
  };

  const handleMajorSearchReset = () => {
    setSearchedMajor(null);
  };
  const handlemajorsubmit = () => {
    console.log("go ahead");
    navigate("/application/select-languagetest", { state: {"majors":selected,...mydata} });
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (option) => {
    setSelectedOption(option);
  };
  return (
    <>
      <IconButton
        className="absolute z-10 text-black xl:flex lg:hidden mmd:hidden md:hidden sm:hidden xs:hidden"
        onClick={handleOnBack}
      >
        <ArrowBackRoundedIcon style={{ fontSize: "32px" }} />
      </IconButton>

      <div
        className="
        flex w-full h-screen justify-center items-center fixed pb-36 
        xxl:w-full xxl:h-screen xxl:fixed
        xl:w-screen xl:h-screen xl:fixed
        lg:w-screen lg:h-screen lg:absolute
        mmd:pb-64 mmd:w-screen mmd:h-screen mmd:absolute
        md:pb-0 md:w-screen md:min-h-screen md:absolute
        sm:pb-0 sm:w-screen sm:min-h-screen sm:absolute
        xs:pb-0 xs:w-screen xs:min-h-screen xs:absolute"
      >
        <img
          src="/images/applicationflow/countrybg.jpg"
          className="
            xxl:w-screen xxl:h-screen
            xl:w-screen xl:h-screen
            lg:w-screen lg:h-screen
            mmd:w-screen mmd:h-screen
            md:w-screen md:h-screen
            sm:w-screen sm:h-screen
            xs:w-screen xs:h-screen"
        />

        <div
          className="
          justify-center items-center absolute w-[800px] h-[490px] rounded-[30px] 
          backdrop-filter bg-blue-700 backdrop-blur-md bg-opacity-10 hover:shadow-gray-500 
          shadow-xl border-gray-600
          xxl:rounded-[30px] xxl:h-[550px] xxl:mb-34
          xl:w-[1000px] xl:h-[550px] xl:mb-52
          lg:w-[800px] lg:h-auto lg:mt-72 lg:rounded-[30px]
          mmd:w-fit mmd:h-auto mmd:rounded-none
          md:w-fit md:min-h-[490px] md:rounded-none md:mb-0
          sm:w-fit sm:min-h-full sm:h-auto sm:rounded-none sm:mb-0
          xs:w-screen xs:min-h-full xs:h-auto xs:rounded-none xs:mb-0"
        >
          <Grid
            container
            textAlign={"center"}
            style={{ cursor: "pointer" }}
            className="mb-16"
          >
            <Grid item xs={12}>
              <label className="text-3xl mb-2 text-[#181a1b] tracking-wide font-bold mt-7 bg-slate-200 p-6 rounded-full bg-opacity-30">
                Current Track : {mydata.track}
              </label>
            </Grid>
            <Grid item xs={12}>
              <label className="text-5xl  text-black tracking-wide font-bold mt-15 p-6 rounded-full ">
                Which Major Do You Want To Pursue?
              </label>
            </Grid>
            <Grid item xs={12}>
              {" "}
              <hr className="border-black mb-2" />
            </Grid>

            <Grid item xs={12}>
              <div className=" flex w-full justify-center items-center">
                <Controller
                  control={control}
                  name="Search_Major"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      value={value}
                      sx={{
                        "& input": {
                          fontSize: "1.5rem",
                        },
                        borderRadius: "5px",
                      }}
                      id="Search_Major"
                      className=" w-2/6 mt-6 bg-transparent  "
                      options={(Course && Course) || []}
                      getOptionLabel={(option) => {
                        return option.course_name + "";
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option.course_name === value.course_name;
                      }}
                      renderOption={(_props, option, { selected }) => (
                        <li {..._props}>{option.course_name}</li>
                      )}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <Controller
                          name="Search_Major"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...params}
                              sx={{
                                "& label": {
                                  fontSize: "1.5rem", // Adjust the font size of the label here as needed
                                },
                              }}
                              label="Search&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                              placeholder="Search Major"
                              id="Search_Major"
                            />
                          )}
                        />
                      )}
                    />
                  )}
                />

                <div class="flex  w-fit ml-6 mt-4">
                  <button
                    onClick={() => handleMajorSearch()}
                    class="rounded-lg overflow-hidden shadow-md backdrop-blur-sm bg-opacity-70 rounded-r-none border relative w-36 h-20 cursor-pointer flex items-center border-blue-500 bg-blue-500 group hover:bg-blue-500 active:bg-blue-500 active:border-blue-500"
                  >
                    <span class="text-gray-200 font-semibold ml-8 transform group-hover:translate-x-20 transition-all duration-300 ">
                      Search
                    </span>
                    <span class="absolute right-0 h-full w-0 hover:w-10 rounded-lg bg-blue-500   flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                      <span class="text-blue-500 first:opacity-0 hover:opacity-100 hover:text-white w-full h-full justify-center items-center flex text-[30px] font-semibold">
                        +
                      </span>
                    </span>
                  </button>

                  <button
                    onClick={() => handleMajorSearchReset()}
                    class="rounded-lg overflow-hidden shadow-md backdrop-blur-sm bg-opacity-80 rounded-l-none border relative w-36 h-20 cursor-pointer flex items-center border-red-500 bg-red-500 group hover:bg-red-500 active:bg-red-500 active:border-red-500"
                  >
                    <span class="text-gray-200 font-semibold ml-10 transform group-hover:translate-x-20 transition-all duration-300 ">
                      Reset
                    </span>
                    <span class="absolute right-0 h-full w-0 hover:w-10 rounded-lg bg-red-500  flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                      <span class="text-red-500 first:opacity-0 hover:opacity-100 hover:text-white w-full h-full justify-center items-center flex text-[30px] font-semibold">
                        -
                      </span>
                    </span>
                  </button>
                </div>

                {/*<div className="w-fit">
            <button
                  onClick={() => handleMajorSearch()}
                  className="w-fit h-fit rounded-3xl   hover:scale-102 transition-all ease-in mt-6 ml-10"
                >
                  <label
                    className={` bg-[#196be8] bg-opacity-50 hover:bg-teal-500 border-2 border-black rounded-r-none ease-in-out transition-all items-center py-3   px-3 flex  rounded-3xl h-fit w-fit `}
                  >
                    <div className="" />
                    <label className="text-black flex items-center justify-center font-extrabold tracking-wider text-[15px]   w-fit ">
                      Search
                    </label>
                  </label>
                </button> */}
                {/* <button class="rounded-lg overflow-hidden relative w-40 h-20 cursor-pointer flex items-center  border-blue-500 bg-blue-500 group hover:bg-blue-500 active:bg-blue-500 active:border-blue-500">
  <span class="text-gray-200 font-semibold ml-8 transform group-hover:translate-x-20 transition-all duration-300">Search</span>
  <span class="absolute right-0 h-full w-10 rounded-lg bg-blue-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
    <svg class="svg w-8 hidden text-white" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <line x1="12" x2="12" y1="5" y2="19"></line>
      <line x1="5" x2="19" y1="12" y2="12"></line>
    </svg>
    <span class="text-blue-500 hover:text-white w-full h-full justify-center items-center flex text-[30px] font-semibold">+</span>
  </span>
</button>
<button class="rounded-lg overflow-hidden relative w-40 h-20 cursor-pointer flex items-center  border-red-500 bg-red-500 group hover:bg-red-500 active:bg-red-500 active:border-red-500">
  <span class="text-gray-200 font-semibold ml-12 transform group-hover:translate-x-20 transition-all duration-300">Reset</span>
  <span class="absolute right-0 h-full w-10 rounded-lg bg-red-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
    <svg class="svg w-8 hidden text-white" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <line x1="12" x2="12" y1="5" y2="19"></line>
      <line x1="5" x2="19" y1="12" y2="12"></line>
    </svg>
    <span class="text-red-500 hover:text-white w-full h-full justify-center items-center flex text-[30px] font-semibold">+</span>
  </span>
</button> */}
                {/* <button
                  onClick={() => handleMajorSearchReset()}
                  className="w-fit h-fit rounded-3xl  hover:scale-102 transition-all ease-in mt-6"
                >
                  <label
                    className={` bg-[#196be8] bg-opacity-50 rounded-l-none border-2 border-l-transparent border-black hover:bg-teal-500 ease-in-out transition-all items-center py-3   px-6 flex  rounded-3xl h-fit w-fit `}
                  >
                    <div className="" />
                    <label className="text-black flex items-center justify-center font-extrabold tracking-wider text-[15px]   w-fit ">
                      Reset
                    </label>
                  </label>
                </button> */}
                {/* <button
  class="rounded-lg overflow-hidden relative w-36 h-10 cursor-pointer flex items-center border border-green-500 bg-green-500 group hover:bg-green-500 active:bg-green-500 active:border-green-500"
>
  <span
    class="text-gray-200 font-semibold ml-8 transform group-hover:translate-x-20 transition-all duration-300"
    >Search</span>
  <span
    class="absolute right-0 h-full w-10 rounded-lg bg-green-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300"
  >
    <svg
      class="svg w-8 hidden  text-white"
      fill="none"
      height="24"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="12" x2="12" y1="5" y2="19"></line>
      <line x1="5" x2="19" y1="12" y2="12"></line>
    </svg>
  </span>
</button> 
                </div>*/}
              </div>
            </Grid>
          </Grid>

          <div
            className="
              xl:w-full xl:grid xl:grid-cols-3 xl:place-items-center
              lg:w-full lg:grid lg:grid-cols-2
              md:w-full md:h-auto md:grid md:grid-cols-2 md:place-items-center md:mb-0
              mmd:w-full mmd:h-auto mmd:grid mmd:grid-cols-2 mmd:place-items-center mmd:mb-0
              sm:w-full sm:h-auto sm:grid sm:grid-cols-2 sm:place-items-center
              xs:w-full xs:h-auto xs:grid xs:grid-cols-2 xs:place-items-center"
          >
            {SearchedMajor === null &&
              Majors &&
              Majors.map((name) => (
                <Grid items xs={3}>
                  {selected && selected.includes(name.name) ? (
                    <button
                      onClick={() => {
                        handlemajorselect(name.name);
                      }}
                      className=" w-fit h-fit rounded-3xl"
                    >
                      {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                      <label className="flex items-center bg-white bg-opacity-50 p-3  mt-10 rounded-full h-fit w-fit">
                        <div className=" w-fit h-fit rounded-full p-[3.5px] bg-blue-700">
                          <CheckIcon sx={{ fontSize: "2em", color: "white" }} />
                        </div>

                        <label className="text-black flex items-start justify-start font-extrabold tracking-wide text-[13px]  ml-5 w-64">
                          {name.name}
                        </label>
                      </label>
                      {/* </div> */}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        handlemajorselect(name.name);
                      }}
                      className=" w-fit h-fit rounded-3xl"
                    >
                      {/* <div className="h-fit w-fit bg-[#7010a060] mt-20 mb-32 mx-28 items-center group   hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl"> */}
                      <label className="flex items-center bg-[#196be8] bg-opacity-50 p-3  mt-10 rounded-full h-fit w-fit">
                        <div className=" w-fit h-fit rounded-full p-[3.5px] bg-white">
                          {/* <SchoolIcon sx={{ fontSize: "2em", color: "white" }} /> */}
                          <img src={name?.icon} />
                        </div>

                        <label className="text-black flex items-start justify-start  text-[13px] tracking-wide font-extrabold  ml-5 w-64">
                          {name.name}
                        </label>
                      </label>
                      {/* </div> */}
                    </button>
                  )}
                </Grid>
              ))}

            {SearchedMajor !== null &&
              Majors &&
              Majors.map((name) => {
                if (SearchedMajor === name.name)
                  return (
                    <Grid items xs={12}>
                      {selected && selected.includes(name.name) ? (
                        <button
                          onClick={() => {
                            handlemajorselect(name.name);
                          }}
                          className=" w-fit h-fit rounded-3xl"
                        >
                          <label className="flex items-center bg-white bg-opacity-50 p-3  mt-10 rounded-full h-fit w-fit">
                            <div className=" w-fit h-fit rounded-full p-[3.5px] bg-blue-700">
                              <CheckIcon
                                sx={{ fontSize: "2em", color: "white" }}
                              />
                            </div>

                            <label className="text-black flex items-start justify-start font-extrabold tracking-wide text-[13px]  ml-5 w-64">
                              {name.name}
                            </label>
                          </label>
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            handlemajorselect(name.name);
                          }}
                          className=" w-fit h-fit rounded-3xl"
                        >
                          <label className="flex items-center bg-[#196be8] bg-opacity-50 p-3  mt-10 rounded-full h-fit w-fit">
                            <div className=" w-fit h-fit rounded-full p-[3.5px] bg-white">
                              <img src={name?.icon} />
                            </div>

                            <label className="text-black flex items-start justify-start  text-[13px] tracking-wide font-extrabold  ml-5 w-64">
                              {name.name}
                            </label>
                          </label>
                        </button>
                      )}
                    </Grid>
                  );
              })}

            
            {selected && selected.length > 0 ? (
              <Grid item xs={12}>
                {" "}
                <button
                  onClick={() => {
                    handlemajorsubmit();
                  }}
                  className=" font-extrabold w-fit h-fit mt-10 px-36 py-4 text-[20px] rounded-full bg-[#02133e] text-white"
                >
                  Continue
                </button>{" "}
              </Grid>
            ) : (
              <Grid item xs={12}>
                {" "}
                <button
                  onClick={() => {
                    handlemajorselect("select all");
                  }}
                  className=" font-extrabold w-fit h-fit mt-10 px-[67px] py-4 text-[20px] rounded-full bg-[#02133e] text-white"
                >
                  No Preference
                </button>{" "}
              </Grid>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewStudentApplicationSelectMajor;
