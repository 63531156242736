import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerFive from './BannerFive';
import LogoOne from './LogoOne';
import HomeFiveCategories from './HomeFiveCategories';
import HomeFiveProgress from '../../components/home-five/HomeFiveProgress';
import FooterOne from '../../common/footer/FooterOne';
import AboutSix from './AboutSix';
import CallToActionOne from '../../components/cta/CallToActionOne';
import HomeTwoService from './HomeTwoService';
import InstructorPageOne from './InstructorPageOne';
import AboutFour from './AboutFour';
import HomeOneInstructor from './HomeOneInstructor';
import BannerSlider from './BannerSlider';
const HomeFive = () => {
    return (
        <>
            <SEO title="Home" />

            <HeaderTwo styles="header-transparent header-style-2" searchDisable buttonStyle="bg-color-white" />

            <BannerSlider />

            <AboutSix
                slogan="How We Assist"
                title="Why choose My Story Abroad?"
                para="We believe with right exposure you can do wonders for yourself and your family hence we help you make choices that resonate with your aspirations and dreams. Once that happens your chances of realising your potential increases exponentially. You control and determine your destiny, not your birthplace. You become a #global-citizen."
                image="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
            />

            <HomeTwoService />
{/* 
            <CallToActionOne /> */}
            <HomeOneInstructor />


            <HomeFiveProgress />
            <InstructorPageOne />

            <CallToActionOne />

            {/* <AboutFour /> */}

            <InstructorPageOne />
            <HomeFiveCategories />

            <LogoOne />

         
            {/* <AboutSix
                slogan="Padho India"
                title="The Padho India Pledge"
                para="We will be sponsoring 1-year of education for a child (aged 5-13) with less means and privilege, against every student who uses our service to study in a dream university across the world."
                image="https://images.unsplash.com/photo-1610484826967-09c5720778c7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            /> */}


            <FooterOne />
        </>
    )
}

export default HomeFive;