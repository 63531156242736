import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';

const CourseType = ({ data, classes, bgWhite }) => {
    const [show, setShow] = useState(false);
    const [reveal, setReveal] = useState(false);
    const handleClose = () =>{
     setShow(false);
     setReveal(true);}
    const handleShow = () => setShow(true);
  
    const loginShow = () =>{ setReveal(true);}
    const [result, setResult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_bxh6md3',
                'template_1g7v07n',
                e.target,
                'user_8Lx0gfI1ktOoeEN8DTV10'
            )
            .then((result) => {
                console.log(result.text);
            },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
        setResult(true);
    };

    setTimeout(() => {
        setResult(false);
    }, 5000);
    return (
        <div className={`edu-card card-type-1 radius-small ${classes ? classes : ''} ${bgWhite === 'enable' ? 'bg-white' : ''}`}>
            <div className="inner">
                <div className="thumbnail">
                    <Link to={process.env.PUBLIC_URL + `/ebookkInner/${data.id}`}>
                        <img className="w-100" src={`${process.env.PUBLIC_URL}${data.image}`} alt="Course Thumb" />
                    </Link>
                    <div className="top-position status-group left-top">
                        <span className="eduvibe-status status-01">{data.level}</span>
                    </div>
                    <div className="wishlist-top-right">
                        <button className="wishlist-btn"><i className="icon-Heart"></i></button>
                    </div>
                </div>
                <div className="content">
                    <ul className="edu-meta meta-01">
                        <li><i className="icon-file-list-4-line"></i>{data.lesson} Lessons</li>
                        <li><i className="icon-time-line"></i>{data.duration}</li>
                    </ul>
                    <h6 className="title">
                        <Link to={process.env.PUBLIC_URL + `/ebookkInner/${data.id}`}>{data.title}</Link>
                    </h6>
                    <div className="edu-rating rating-default">
                        <div className="rating eduvibe-course-rating-stars">
                            <i className="icon-Star"></i>
                            <i className="icon-Star"></i>
                            <i className="icon-Star"></i>
                            <i className="icon-Star"></i>
                            <i className="icon-Star"></i>
                        </div>
                        <span className="rating-count">({data.review} Reviews and Ratings)</span>
                    </div>
                    <div className="card-bottom">
                        <div className="price-list price-style-03">
                            <button className="rn-btn edu-btn" onClick={handleShow}>
                                <span>download </span><i className="icon-arrow-right-line-right"></i>
                            </button>
                            <Modal show={show} centered onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ marginBottom: "5px" }}>Sign Up</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <form action="" onSubmit={sendEmail} style={{ margin: "30px" }}>
                                        <div className="col-lg-12" >
                                            <div className="form-group">
                                                <Form.Label><b>Full Name* </b></Form.Label>
                                                <input type="text" className="form-control form-control-lg" name="fullname" placeholder="Name*" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <Form.Label><b>Email Address*</b></Form.Label>
                                                <input type="email" className="form-control form-control-lg" name="email" placeholder="Email*" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <Form.Label><b> Mobile Number*</b></Form.Label>
                                                <input type="text" className="form-control form-control-lg" name="phone" placeholder="Phone" />
                                            </div>
                                        </div>


                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                {/* <textarea className="form-control" name="message" placeholder="Your Message"></textarea> */}
                                                <p>By continuing, you agree to msa Edu’s<a href='' style={{ color: "blue" }}> Terms & Conditions </a> and <a href='' style={{ color: "blue" }}>Privacy Policy </a>  </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <button className="rn-btn edu-btn w-100" type="submit">
                                                <span>Submit Now</span><i className="icon-arrow-right-line-right"></i></button>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <p></p>
                                                {/* <textarea className="form-control" name="message" placeholder="Your Message"></textarea> */}
                                                <p style={{ paddingLeft: "55px" }}>Already have an account? <a href=''onClick={handleClose} style={{ color: 'blue' }}>Login </a> </p>
                                            </div>
                                        </div>

                                        {/* { result ? <div className="form-group"><Result /></div>  : null } */}
                                    </form>
                                </Modal.Body>

                            </Modal>

                            {/* {
                                data.price === '0' ?
                                    <div className="price current-price">Free</div>
                                :
                                    <div className="price current-price">${data.price}</div>
                            }
                            { 
                                data.oldPrice && <div className="price old-price">${data.oldPrice}</div> 
                            } */}
                        </div>
                        {/* <ul className="edu-meta meta-01">
                            <li><i className="icon-account-circle-line"></i>{data.student} Students</li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseType;