import { React, useState } from "react";
import { Link } from "react-router-dom";
const BreadcrumbOne = ({
  title,
  rootUrl,
  parentUrl,
  currentUrl,
  description,
  btn,
  datas
}) => {
  const [noMore, setNoMore] = useState(false);
  // console.log(datas.universityUpperContent[0]);
  const handleLoadMoreBtn = (e) => {
    e.preventDefault();
    setNoMore(!noMore);
    var readButton = document.getElementById("toggleButton");
    if(readButton.innerHTML === "Read More"){
      readButton.innerHTML = "Read Less";
    }else{
      readButton.innerHTML = "Read More";
    }
    
    // console.log(noMore);
    //document.getElementsByClassName('.edu-btn').dangerouslySetInnerHTML
  };
  return (
    <div className="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
      <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-inner text-start">
              <div className="page-title">
                <h3
                  className="title"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></h3>
              </div>
              <nav className="edu-breadcrumb-nav">
                <ol className="edu-breadcrumb d-flex justify-content-start liststyle">
                  <li className="breadcrumb-item">
                    <Link
                      to={`${rootUrl}`}
                      dangerouslySetInnerHTML={{ __html: parentUrl }}
                    ></Link>
                  </li>
                  <li className="separator">
                    <i className="ri-arrow-drop-right-line"></i>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    dangerouslySetInnerHTML={{ __html: currentUrl }}
                  ></li>
                </ol>
              </nav>
              {description && (
                <div className="edu-breadcrumb-nav">
                  <p dangerouslySetInnerHTML={{ __html: description }}></p>
                </div>
              )}
            </div>
          </div>
          {noMore && (
            <div>
              <table>
                <tbody>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      {/* <span>Number of International Students in UK</span> */}
                      <span>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title1}
                      </span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title1ANS}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title2}
                      </span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title2ANS}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title3}
                      </span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title3ANS}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title4}
                      </span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title4ANS}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title5}
                      </span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title5ANS}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title6}
                      </span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <p>
                        {datas.universityUpperContent[0].textInsideBreadcrumbs[0].upperTable[0].title6ANS}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h4>
                {datas.universityUpperContent[0].textInsideBreadcrumbs[0].studyReasons[0].titleHead}
              </h4>
              <p>
                {datas.universityUpperContent[0].textInsideBreadcrumbs[0].studyReasons[0].title}

                {/* <a href="https://leverageedu.com/blog/best-forex-cards-for-students/">
                  international
                </a>
                students moving to UK has increased dramatically. There are
                the top 5 reasons to study in UK mentioned below: */}
              </p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].studyReasons[0].textFirst.map(
                (listBullet) => {
                  // console.log(listBullet);
                  return (
                    <ul>
                      <li>{listBullet}</li>
                    </ul>
                  );
                }
              )}

              <h4>{datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].paraTitle}</h4>
              <p>{datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].para}</p>

              <table>
                <tbody>
                  {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].table[0].body.map(
                    (tableContent) => {
                      return (
                        
                        <tr>
                          <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[0]}&nbsp;</span>
                          </td>
                           <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[1]}&nbsp;</span>
                          </td> 
                          <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[2]}&nbsp;</span>
                          </td>
                        </tr>
                        
                      );
                    }
                  )}
                  
                  {/* <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Fall</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>June</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>September</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Winter</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>November</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>January</span>
                    </td>
                  </tr> */}
                
                </tbody>
              </table>

              <h4>{datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topUniversity2023title}</h4>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topUniversity2023title}
                {/* <a href="https://leverageedu.com/blog/mba-courses-in-UK/">
                  UK
                </a>{" "}
                as per QS world universities ranking 2023: */}
              </p>
              <table>
                <tbody>
                {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topUniversity2023.map(
                    (tableContent) => {
                      return (
                        
                        <tr>
                          <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[0]}&nbsp;</span>
                          </td>
                           <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[1]}&nbsp;</span>
                          </td> 
                        </tr>
                        
                      );
                    }
                  )}
                  {/* <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>University of Oxford</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>#31</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>University of Cambridge</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>#34</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Imperial College London</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>#47</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>University College London</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>#110</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>University of Edinburgh </span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>#116</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>University of Manchester</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>#152</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>King's College London</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>#154</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        London School of Economics and Political Science
                      </span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>#172</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>University of Warwick</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>#237</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>University of Bristol</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>#242</span>
                    </td>
                  </tr> */}
                </tbody>
              </table>

              <h4>Top Universities in {datas.universityUpperContent[0].name} for Bachelors</h4>
              <p>
               {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topUniversityBachelorsPara}
              </p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topUniversityBachelorsList.map((bachelorsList)=>{
                return (
                  <ul>
                  <li>{bachelorsList}</li>
                  </ul>
                )
              }
              )}
              {/* <ul>
                <li>University of Oxford&nbsp;</li>
                <li>University of Cambridge</li>
                <li>Imperial College London</li>
                <li>University College London</li>
                <li>University of Edinburgh</li>
                <li>University of Manchester</li>
                <li>King's College London</li>
                <li>London School of Economics and Political Science</li>
                <li>University of Bristol</li>
              </ul> */}

              <h4>Top Universities in {datas.universityUpperContent[0].name} for Masters</h4>
              <p>
                Top universities in {datas.universityUpperContent[0].name} to study master degree program are
                mentioned below:
              </p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topUniversityMastersList.map((mastersList)=>{
                return (
                  <ul>
                  <li>{mastersList}</li>
                  </ul>
                )
              }
              )}
              {/* <ul>
                <li>University of Oxford</li>
                <li>University of Cambridge</li>
                <li>Imperial College London</li>
                <li>
                  <a href="https://leverageedu.com/blog/university-of-alberta-acceptance-rate/">
                    London School of Economics and Political Science
                  </a>
                </li>
                <li>University of Edinburgh</li>
                <li>King's College London</li>
                <li>University of Warwick</li>
                <li>University of Manchester</li>
                <li>University of Bristol</li>
                <li>University of Glasgow</li>
              </ul> */}

              <h4>Top Public Universities in {datas.universityUpperContent[0].name}</h4>
              <p>
               {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topPublicUniversityPara}
              </p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topPublicUniversityList.map((publicList)=>{
                return (
                  <ul>
                  <li>{publicList}</li>
                  </ul>
                )
              }
              )}
              {/* <ul>
                <li>University of Oxford</li>
                <li>University of Cambridge</li>
                <li>University College London</li>
                <li>
                  <a href="https://leverageedu.com/blog/mcgill-university-courses/">
                    Edinburgh University
                  </a>
                </li>
                <li>University of Manchester</li>
                <li>King's College London</li>
                <li>London School of Economics and Political Science</li>
                <li>Imperial College London</li>
                <li>University of Warwick</li>
              </ul> */}

              <h4>Top Courses Offered by Universities in {datas.universityUpperContent[0].name}</h4>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topCourseOfferedPara}
              </p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topCourseOfferedList.map((courseList)=>{
                return (
                  <ul>
                  <li>{courseList}</li>
                  </ul>
                )
              }
              )}
              {/* <ul>
                <li>
                  <a href="https://leverageedu.com/blog/types-of-mba-in-UK/">
                    Business Analytics
                  </a>
                </li>
                <li>Data Science</li>
                <li>Computer Science</li>
                <li>MBA and MIM</li>
                <li>MBBS</li>
                <li>Finance, International Business and Accounting</li>
                <li>Agricultural Science and Forestry</li>
                <li>Law</li>
                <li>Architecture and Construction Management </li>
                <li>
                  <a href="https://leverageedu.com/blog/colleges-for-psychology/">
                    Engineering
                  </a>
                </li>
                <li>Fashion and Interior designing</li>
              </ul> */}

              <h4>Top Student Cities in {datas.universityUpperContent[0].name}</h4>
              <p> {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topStudentCitiesPara}&nbsp;
              </p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].aboutIntake[0].topStudentCitiesList.map((cityList)=>{
                return (
                  <ul>
                  <li>{cityList}</li>
                  </ul>
                )
              }
              )}
              {/* <ul>
                <li>
                  <a href="https://leverageedu.com/blog/university-of-toronto-courses/">
                    London
                  </a>
                </li>
                <li>Edinburgh</li>
                <li>Manchester</li>
                <li>Glasgow</li>
                <li>
                  <a href="https://leverageedu.com/blog/universities-in-quebec/">
                    Coventry
                  </a>
                </li>
                <li>Newcastle</li>
                <li>Birmingham</li>
                <li>Bristol</li>
                <li>Nottingham</li>
                <li>Sheffield</li>
              </ul> */}

              <h4>Admission Requirements for Top Universities in {datas.universityUpperContent[0].name}</h4>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].admissionRequirement[0].para}&nbsp;
              </p>
              <h5>Eligibility Criteria</h5>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].admissionRequirement[0].eligibilityCriteria.map((eligibilityCriteria)=>{
                return (
                  <ul>
                  <li>{eligibilityCriteria}</li>
                  </ul>
                )
              }
              )}
              {/* <ul>
                <li>
                  Students must have a 50% or higher in Standard XII to be
                  accepted for admittance to an undergraduate program.
                </li>
                <li>
                  A degree equal to a British bachelor's degree will be
                  considered for PG programs. Students must have at least a 2nd
                  division bachelor's degree.
                </li>
              </ul> */}

              <h4>Documents Required</h4>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].admissionRequirement[0].documentRequired.map((documentRequired)=>{
                return (
                  <ul>
                  <li>{documentRequired}</li>
                  </ul>
                )
              }
              )}
              {/* <ul>
                <li>All official academic transcripts and grade cards</li>
                <li>
                  <a href="https://leverageedu.com/blog/student-passport-in-india/">
                    Scanned copy of passport
                  </a>
                </li>
                <li>
                  <a href="https://leverageedu.com/blog/sop-statement-of-purpose/">
                    Statement of Purpose
                  </a>
                </li>
                <li>
                  <a href="https://leverageedu.com/blog/is-lors-important-for-study-abroad-application/">
                    Letter of Recommendation
                  </a>
                </li>
                <li>
                  An updated CV/
                  <a href="https://leverageedu.com/blog/top-technical-skills-to-enhance-your-resume/">
                    {" "}
                    Resume
                  </a>
                </li>
                <li>
                  Certificates of Extracurricular activities/merits (if
                  required)
                </li>
                <li>Proof of sufficient funds to study in UK</li>
              </ul> */}

              <h4>English Language Score Requirements</h4>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].admissionRequirement[0].englishLanguage[0].para}
              </p>
              <table>
                <tbody>
                {datas.universityUpperContent[0].textInsideBreadcrumbs[0].admissionRequirement[0].englishLanguage[0].score.map(
                    (englishLanguage) => {
                      return (
                        
                        <tr>
                          <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{englishLanguage[0]}&nbsp;</span>
                          </td>
                           <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{englishLanguage[1]}&nbsp;</span>
                          </td> 
                        </tr>
                        
                      );
                    }
                  )}
                  </tbody>
                </table>
              {/* <table>
                <tbody>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>English Proficiency Exam</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Minimum Score Required</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        <a href="https://leverageedu.com/blog/ielts-letter-writing-samples/">
                          IELTS
                        </a>
                      </span>
                    </td>{" "}
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Overall 6.5 out of 9 with no band below 6.0</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        <a href="https://leverageedu.com/blog/pte/">PTE</a>
                      </span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>60-65</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>
                        <a href="https://leverageedu.com/blog/toefl-exam-pattern/">
                          TOEFL iBT
                        </a>
                      </span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Overall 90</span>
                    </td>
                  </tr>
                </tbody>
              </table> */}

              <h4>Application Process</h4>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].applicationProcess[0].mainpara}
              </p>
              <ul>
                <li> {datas.universityUpperContent[0].textInsideBreadcrumbs[0].applicationProcess[0].bpara1}</li>
              </ul>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].applicationProcess[0].para1}
              </p>
              <ul>
                <li>{datas.universityUpperContent[0].textInsideBreadcrumbs[0].applicationProcess[0].bpara2}</li>
              </ul>
              <p>
                <br />
                {datas.universityUpperContent[0].textInsideBreadcrumbs[0].applicationProcess[0].para2}
              </p>
              <ul>
                <li>{datas.universityUpperContent[0].textInsideBreadcrumbs[0].applicationProcess[0].bpara3}</li>
              </ul>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].applicationProcess[0].para3}&nbsp;
              </p>
              <ul>
                <li>{datas.universityUpperContent[0].textInsideBreadcrumbs[0].applicationProcess[0].bpara4}</li>
              </ul>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].applicationProcess[0].para4}
              </p>

              <h4>Cost of Studying in {datas.universityUpperContent[0].name}</h4>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].costStudyingPara}
              </p>

              <h4>Cost of Education in {datas.universityUpperContent[0].name}</h4>
              <table>
                <tbody>
                {datas.universityUpperContent[0].textInsideBreadcrumbs[0].costStudyTable.map(
                    (tableContent) => {
                      return (
                        
                        <tr>
                          <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[0]}&nbsp;</span>
                          </td>
                           <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[1]}&nbsp;</span>
                          </td> 
                           <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[2]}&nbsp;</span>
                          </td> 
                           <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[3]}&nbsp;</span>
                          </td> 
                        </tr>
                        
                      );
                    }
                  )}
                </tbody>
              </table>
              {/* <table>
                <tbody>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Study Program</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Average Annual Fee (CAD)</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Average Annual Fee (INR)</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Undergraduate Degree</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>13K to 20K</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>8-12 Lakhs</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Postgraduate Degree</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>17K to 25K</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>10-15 Lakhs</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>MBA Program</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>30K to 40K</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>18-24 Lakhs</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>Doctoral Degree</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>7K to 15K</span>
                    </td>
                    <td
                      class="ck-editor__editable ck-editor__nested-editable"
                      contenteditable="false"
                    >
                      <span>4-9 Lakhs</span>
                    </td>
                  </tr>
                </tbody>
              </table> */}

              <h4>Cost of Living in {datas.universityUpperContent[0].name}</h4>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].costLivingPara}
              </p>
              <table>
                <tbody>
                {datas.universityUpperContent[0].textInsideBreadcrumbs[0].costLivingTable.map(
                    (tableContent) => {
                      return (
                        
                        <tr>
                          <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[0]}&nbsp;</span>
                          </td>
                           <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[1]}&nbsp;</span>
                          </td> 
                           <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[2]}&nbsp;</span>
                          </td> 
                           <td
                            class="ck-editor__editable ck-editor__nested-editable"
                            contenteditable="false"
                          >
                            <span>{tableContent[3]}&nbsp;</span>
                          </td> 
                        </tr>
                        
                      );
                    }
                  )}
                </tbody>
              </table>

              <h4>Scholarships at Top Universities in {datas.universityUpperContent[0].name}</h4>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].scholarshipPara}
              </p>

              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].scholarshipList.map((documentRequired)=>{
                return (
                  <ul>
                  <li>{documentRequired}</li>
                  </ul>
                )
              }
              )}

              {/* <ul>
                <li>Chevening Scholarships</li>
                <li>Dr. Manmohan Singh Scholarships</li>
                <li>Hornby Scholarships</li>
                <li>Rhodes Scholarship</li>
                <li>Commonwealth Scholarship and Fellowship Plan</li>
                <li>Felix Scholarships</li>
                <li>Erasmus Mundus Joint Masters Degree Scholarship</li>
                <li>Inlaks Scholarships</li>
                <li>Charles Wallace India Trust Scholarships (CWIT)</li>
                <li>Scotland Saltire Scholarships</li>
              </ul> */}

              <h4>FAQs</h4>
              <p>{datas.universityUpperContent[0].textInsideBreadcrumbs[0].questionAnswer[0].question1}</p>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].questionAnswer[0].answer1}
              </p>
              <p>{datas.universityUpperContent[0].textInsideBreadcrumbs[0].questionAnswer[0].question2}&nbsp;</p>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].questionAnswer[0].answer1}
              </p>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].questionAnswer[0].question3}{" "}
              </p>
              <p>
              {datas.universityUpperContent[0].textInsideBreadcrumbs[0].questionAnswer[0].answer3}
              </p>
              <p>
                <br data-cke-filler="true" />
              </p>
            </div>
          )}
          {btn && (
            <div className="row text-center mt--60">
              <div className="col-lg-12">
                <button
                  id="toggleButton"
                  className="edu-btn"
                  onClick={handleLoadMoreBtn}
                  //disabled={ noMorePost ? 'disabled' : null }
                >
                  <span>Read More</span>
                </button>
              </div>
            </div>
          )}
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-11-07.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-01-02.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src="/images/shapes/shape-03.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-4">
              <img src="/images/shapes/shape-13-12.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-5">
              <img src="/images/shapes/shape-36.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-6">
              <img src="/images/shapes/shape-05-07.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbOne;
