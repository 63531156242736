import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com';

const CourseeInfo = ( { data }) => {
    const [toggler, setToggler] = useState( false );
    const [show, setShow] = useState(false);
    
    const handleClose = () =>{
     setShow(false);
     }
    const handleShow = () => setShow(true);
  
    
    const [result, setResult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_bxh6md3',
                'template_1g7v07n',
                e.target,
                'user_8Lx0gfI1ktOoeEN8DTV10'
            )
            .then((result) => {
                console.log(result.text);
            },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
        setResult(true);
    };

    setTimeout(() => {
        setResult(false);
    }, 5000);
    return (
        <div className="eduvibe-sidebar course-details-sidebar">
            <div className="inner">
                <div>
                    {/* <div className="video-area">
                        <div className="thumbnail video-popup-wrapper">
                            <img className="radius-small w-100" src={`${process.env.PUBLIC_URL}/images/course/video-bg/${data.image}`} alt="Course Video Thumb" />
                            <button onClick={ () => setToggler( ! toggler ) } className="video-play-btn position-to-top video-popup-activation">
                                <span className="play-icon"></span>
                            </button>
                            <FsLightbox toggler={ toggler } sources={ data.videoLink } />
                        </div>
                    </div> */}
                    <div className="eduvibe-widget-details myCustomCssForCard   ">
                        <div className="widget-content">
                            <ul>
                                { data.duration && <li><span><i className="icon-time-line"></i> </span><span>{data.duration}</span></li> }
                                { data.student && <li><span><i className="icon-user-2"></i> Enrolled</span><span>{data.student}</span></li> }
                                { data.lesson && <li><span><i className="icon-draft-line"></i> Lectures</span><span>{data.lesson}</span></li> }
                                { data.level && <li><span><i className="icon-bar-chart-2-line"></i> Skill Level</span><span>{data.level}</span></li> }
                                { data.language && <li><span><i className="icon-translate"></i> Language</span><span>{data.language}</span></li> }
                                { data.quizzes && <li><span><i className="icon-artboard-line"></i> Quizzes</span><span>{data.quizzes}</span></li> }
                                { data.certificate && <li><span><i className="icon-award-line"></i> Certificate</span><span>{data.certificate === 'available' ? 'Yes' : 'No'}</span></li> }
                                { data.passPercentage && <li><span><img className="eduvibe-course-sidebar-img-icon" src="/images/icons/percent.svg" alt="icon Thumb" />Pass Percentage</span><span>{data.passPercentage}%</span></li> }
                                { data.deadline && <li><span><i className="icon-calendar-2-line"></i> Deadline</span><span>{data.deadline}</span></li> }
                                { data.instructor && <li><span><i className="icon-user-2-line_tie"></i> Instructor</span><span>{data.instructor}</span></li> }
                            </ul>
                            <div className="read-more-btn mt--45">
                                <a onClick={handleShow} className="edu-btn btn-bg-alt w-100 text-center">
                                    Download
                                </a>
                                <Modal show={show} centered onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title style={{ marginBottom: "5px" }}>Sign Up</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <form action="" onSubmit={sendEmail} style={{ margin: "30px" }}>
                                        <div className="col-lg-12" >
                                            <div className="form-group">
                                                <Form.Label><b>Full Name* </b></Form.Label>
                                                <input type="text" className="form-control form-control-lg" name="fullname" placeholder="Name*" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <Form.Label><b>Email Address*</b></Form.Label>
                                                <input type="email" className="form-control form-control-lg" name="email" placeholder="Email*" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <Form.Label><b> Mobile Number*</b></Form.Label>
                                                <input type="text" className="form-control form-control-lg" name="phone" placeholder="Phone" />
                                            </div>
                                        </div>


                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                {/* <textarea className="form-control" name="message" placeholder="Your Message"></textarea> */}
                                                <p>By continuing, you agree to msa Edu’s<a href='' style={{ color: "blue" }}> Terms & Conditions </a> and <a href='' style={{ color: "blue" }}>Privacy Policy </a>  </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <button className="rn-btn edu-btn w-100" type="submit">
                                                <span>Submit Now</span><i className="icon-arrow-right-line-right"></i></button>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <p></p>
                                                {/* <textarea className="form-control" name="message" placeholder="Your Message"></textarea> */}
                                                <p style={{ paddingLeft: "55px" }}>Already have an account? <a href=''onClick={handleClose} style={{ color: 'blue' }}>Login </a> </p>
                                            </div>
                                        </div>

                                        {/* { result ? <div className="form-group"><Result /></div>  : null } */}
                                    </form>
                                </Modal.Body>

                            </Modal>
                            </div>
                            <div className="read-more-btn mt--15">
                                <a onClick={handleShow} className="edu-btn w-100 text-center">Save for Later</a>
                            </div>
                            <div className="read-more-btn mt--30 text-center">
                                <div className="eduvibe-post-share">
                                    <span>Share: </span>
                                    <a className="linkedin" href="#"><i className="icon-linkedin"></i></a>
                                    <a className="facebook" href="#"><i className="icon-Fb"></i></a>
                                    <a className="twitter" href="#"><i className="icon-Twitter"></i></a>
                                    <a className="youtube" href="#"><i className="icon-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseeInfo;