import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import HeaderSticky from "../../common/header/HeaderSticky";
import MenuIcon from '@mui/icons-material/Menu';

const AddNewApplicationHeader = (disableSticky, styles, buttonStyle) => {

  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const [searchPopup, setSearchPopup] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prevState) => !prevState);
  };

  const onSearchHandler = () => {
    setSearchPopup((prevState) => !prevState);
  };

  if (searchPopup) {
    document.body.classList.add("search-popup-active");
  } else {
    document.body.classList.remove("search-popup-active");
  }

  const sticky = HeaderSticky(200);
  const classes = `header-default ${sticky ? "sticky" : ""}`;
  const stickyStatus = disableSticky ? "" : " header-sticky";
  
  return (
    <header
      className=
      {
        `bg-[#52bbbe] shadow-lg bg-opacity-70 backdrop-filter backdrop-blur-lg edu-header 
        ${stickyStatus} 
        ${styles || ""} 
        ${classes || ""}
        lg:w-full lg:overflow-x-clip
        md:w-full md:h-fit md:overflow-x-clip
        sm:w-full sm:overflow-x-clip
        xs:w-full xs:overflow-x-clip
      `}
    >
      <div className="row align-items-center">
        <div className="col-lg-4 col-xl-2 col-md-6 col-6">
          <div className="
            logo 
            ml-24 md:ml-2
            sm:ml-2
            xs:ml-2
            ">
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img
                className="
                  logo-light 
                  lg:w-[70px] lg:h-[70px]
                  md:w-[70px] md:h-fit
                  sm:w-[70px] sm:h-fit
                  xs:w-[70px] xm:h-fit"
                src="/images/logo/msa_logo.png"
                alt="My Story Abroad"
              />
            </Link>
          </div>
        </div>

        <div className="col-lg-8 d-none d-xl-block">
          <nav className="mainmenu-nav d-none d-lg-block ">
            <ul className="mainmenu ">
              {/* <li className="has-droupdown"> */}
              <li>
                <Link to="/application">
                  <label className="hover:text-[#ff331f] ease-in-out delay-50">
                    Student Application
                  </label>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <label className="hover:text-[#ff331f] ease-in-out delay-50">
                    Course Finding GURU
                  </label>
                </Link>
              </li>
              <li className="has-droupdown extraSize">
                <Link to="#">
                  <label className="hover:text-[#ff331f] ease-in-out delay-50">
                    Countries
                  </label>
                </Link>

                <ul className="submenu">
                  <li>
                    <Link to="/Destination/Australia">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        Australia
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Destination/Canada">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        Canada
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Destination/USA">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        United States of America
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Destination/UK">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        United Kingdom
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Destination/Germany">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        Germany
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        Ireland
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        Dubai
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        France
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        Malta
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        Singapore
                      </label>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has-droupdown extraSize">
                <Link to="#">
                  <label className="hover:text-[#ff331f] ease-in-out delay-50">
                    Resources
                  </label>
                </Link>

                <ul className="submenu">
                  <li>
                    <Link to="#">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        IELTS/PTE/TOEFL
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        Our Blog
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us">
                      <label className="hover:text-[#ff331f] ease-in-out delay-50">
                        Contact Us
                      </label>
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/About">
                  <label className="hover:text-[#ff331f] ease-in-out delay-50">
                    About Us
                  </label>
                </Link>
              </li>
            </ul>
          </nav>
        </div>

        {/* <div className="col-lg-6 col-xl-2 col-md-6 col-6">
            <div className="header-right d-flex justify-content-end">
                <div className="header-menu-bar">
                    <div className="quote-icon quote-user d-none d-md-block ml--15 ml_sm--5">
                        <Link className={`edu-btn btn-medium left-icon header-button ${ buttonStyle || '' }`} to={process.env.PUBLIC_URL + "/login-register"}>
                            <i className="ri-user-line"></i>Login / Register
                        </Link>
                    </div>
                    <div className="quote-icon quote-user d-block d-md-none ml--15 ml_sm--5">
                    <Link to={process.env.PUBLIC_URL + "/login-register"} className="white-box-icon" href="#"><i className="ri-user-line"></i></Link>
                    </div>
                </div>
                <div className="mobile-menu-bar ml--15 ml_sm--5 d-block d-xl-none">
                    <div className="hamberger">
                        <button className="white-box-icon hamberger-button header-menu" onClick={ onCanvasHandler }>
                            <i className="ri-menu-line"></i>
                        </button>
                    </div>
                </div>
            </div> 
        </div>*/}
      </div>
    </header>
  );
};

export default AddNewApplicationHeader;
