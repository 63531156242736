import React from 'react';

const LogoOne = ( { separator }) => {
    return (
        <div className={`edu-brand-area edu-section-gap bg-color-white ${ separator !== 'disable' ? 'edu-line-separator' : '' }`}>
            <div className="container">
                <div className="row eduvibe-home-five-brands">
                    <div className="col-lg-2 col-md-12">
                        <div className="client-logo">
                            <h6>650+ University Partners</h6>
                        </div>
                    </div>
                    {/* 116 x 25 */}
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/massachusetts.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/massachusetts.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    {/* 122 x 30 */}
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/oxford.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/oxford.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    {/* 97 x 20 */}
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo mt-2">
                            <img className="logo-main" src="/customizedimages/stanford.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/stanford.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    {/* 131 x30 */}
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/cambridge.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/cambridge.jpg" alt="Brand Images" />
                        </a>
                    </div>
                     {/* 82 x 25 */}
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/caltech.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/caltech.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/cambridge.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/cambridge.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/caltech.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/caltech.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/stanford.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/stanford.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/caltech.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/caltech.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/oxford.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/oxford.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main"src="/customizedimages/ucl.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/ucl.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/ucl.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/ucl.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main"src="/customizedimages/massachusetts.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/massachusetts.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/ucl.jpg" alt="Brand Images" />
                            <img className="logo-hover"src="/customizedimages/ucl.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/cambridge.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/cambridge.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/ucl.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/ucl.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-6 col-6">
                        <a href="#" className="client-logo">
                            <img className="logo-main" src="/customizedimages/massachusetts.jpg" alt="Brand Images" />
                            <img className="logo-hover" src="/customizedimages/massachusetts.jpg" alt="Brand Images" />
                        </a>
                    </div>
                    
                  
                   
                </div>
            </div>
        </div>
    )
}

export default LogoOne;