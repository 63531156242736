import React from 'react'
import Layout from '../../../common/Layout'
import CourseItemsForFilter from '../../../components/course/CourseItemsForFilter'
import Filters from '../../../components/course/Filters'
import { CoursesProvider } from './courses_context'
import { FilterProvider } from './filter_context'
import SideFilters from './SideFilters'
import UniversitiesList from './UniversitiesList'

function UniversitiesFilter() {
  return (
    <>
        
            <CoursesProvider>
                <FilterProvider>
                    <div className="edu-course-area  bg-color-white">
                        <div className="container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <UniversitiesList />
                                </div>
                                <div className="col-lg-4">
                                    <SideFilters />
                                </div>
                            </div>
                        </div>
                    </div>
                </FilterProvider>
            </CoursesProvider>
        
    </>
  )
}

export default UniversitiesFilter