import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {GiCapitol}  from 'react-icons/gi';
import {IoLanguageSharp}  from 'react-icons/io5';
import {TiGroup}  from 'react-icons/ti';
import {MdOutlineGroups}  from 'react-icons/md';
import {BsCurrencyExchange, BsGraphUpArrow}  from 'react-icons/bs';
import {GrCurrency}  from 'react-icons/gr';
import {BiDialpadAlt}  from 'react-icons/bi';
import {IoIosSchool}  from 'react-icons/io';

// const items = [
   
//     {
//         title: 'Capital',
//         numberOfCourses: 'London',
//         icon: <GiCapitol/>,
//         link: '#'
//     },
//     {
//         title: 'Population',
//         numberOfCourses: '68 Mn',
//         icon: <TiGroup/>,
//         link: '#'
//     },
//     {
//         title: 'Language',
//         numberOfCourses: 'English',
//         icon: <IoLanguageSharp/>,
//         link: '#'
//     },
//     {
//         title: 'International Students',
//         numberOfCourses: '485,645',
//         icon: <MdOutlineGroups/>,
//         link: '#'
//     },
//     {
//         title: 'GDP',
//         numberOfCourses: '$ 3.12 Trillion',
//         icon: <BsGraphUpArrow/>,
//         link: '#'
//     },
//     {
//         title: 'LDialing Code',
//         numberOfCourses: '+44',
//         icon: <BiDialpadAlt/>,
//         link: '#'
//     },
//     {
//         title: 'Currency',
//         numberOfCourses: 'Pound sterling',
//         icon: <BsCurrencyExchange/>,
//         link: '#'
//     },
//     {
//         title: 'Universities and Colleges',
//         numberOfCourses: '359',
//         icon: <IoIosSchool/>,
//         link: '#'
//     },
 
// ];



const 
Block = ( { wrapperClass, styleClass,datas } ) => {
  //  const {datas} =props;
    console.log(datas);
    return (
        
        <div className={ `row ${ wrapperClass || 'g-5 mt--25' }` }>
            <div style={{fontSize:"40px",fontWeight:"bolder",color:"black"}}>Fast Facts</div>
     
            { datas.map( ( data , i ) => (
                // <ScrollAnimation 
                //     animateIn="fadeInUp"
                //     animateOut="fadeInOut"
                //     className={ `col-lg-4 col-md-6 col-12 ${ styleClass ? styleClass : '' }` }
                //     animateOnce={ true }
                //     key={ i }
                // >
                <div className='col-lg-4 col-md-6 col-12'>
                    <div className="service-card service-card-5">
                        <div className="inner">
                            <div className="icon">
                
                                <i><GiCapitol/></i>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.numberOfCourses }</p>
                            </div>
                        </div>
                    </div>
                    </div>
                // </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default Block;