import {React, useState, useContext} from 'react';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';

function FAQ_Uni() {

    const onlineEducation = {
        Scholarship: [
            {
                id: 1,
                title: 'Fulbright-Nehru Master’s Fellowships',
                program: 'PG',
                body: "The Fulbright-Nehru Master's Fellowships are made for outstanding Indians to pursue a master's degree program at select U.S. colleges and universities in the areas of Arts and Culture Management.",
                eligibility: "Students must have completed an equivalent of a U.S. bachelor's degree from a recognized Indian university with at least 55% marks.",
                applicability: "An application is to be submitted."
            },
            {
                id: 2,
                title: 'University of Toronto Scholars Program',
                body: 'University of Toronto Scholars Program',
                eligibility: "Students must have completed an equivalent of a U.S. bachelor's degree from a recognized Indian university with at least 55% marks.",
                applicability: "An application is to be submitted."
            },
            {
                id: 3,
                title: 'Presidents Scholars of Excellence Program',
                body: 'Awarded to meritorious international students, this scholarship award is automatically offered to selected students who are applying to University Of Torontos Engineering programs.',
                eligibility: "Students must have completed an equivalent of a U.S. bachelor's degree from a recognized Indian university with at least 55% marks.",
                applicability: "An application is to be submitted."
            },
            {
                id: 4,
                title: 'Is There Any Prerequisites To Learn Python?',
                body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.',
                eligibility: "Students must have completed an equivalent of a U.S. bachelor's degree from a recognized Indian university with at least 55% marks.",
                applicability: "An application is to be submitted."
            }
        ],

        FAQs:[
            {
                id: 1,
                title: 'What is MIT famous for?',
                body: "While MIT is perhaps best known for its programs in engineering and the physical sciences, other areas—notably economics, political science, urban studies, linguistics, and philosophy—are also strong. Admission is extremely competitive, and undergraduate students are often able to pursue their own original research.",
            },
            {
                id: 2,
                title: ' Is MIT better than IIT?',
                body: "In India, IITs (Indian Institutes of Technology) are considered the best while at the global level, MIT (Massachusetts Institute of Technology) followed by Harvard's John A. Paulson School Of Engineering And Applied Sciences has constantly been at the top."
            },
            {
                id: 3,
                title: 'Is it hard to get into MIT?',
                body: "MIT is one of the most selective schools in the world. A 7.3% acceptance rate means that MIT is extremely competitive to get into. You'll need excellent grades, test scores, essays, and letters of recommendation to even be considered."
            },
            {
                id: 4,
                title: 'Does MIT have a medical school?',
                body: "MIT offers a popular combined MD-PhD programme with Harvard Medical School (HMS), as well as a Harvard-MIT programme in Health Sciences and Technology (HST), which brings MIT and Harvard together with Boston-area hospitals for a unique collaboration."
            }
        ],

        Study:[
            {
                id: 1,
                title: 'Average 1st-year Tuition Cost',
                body: "For international students, the average first-year tuition cost is as follows: ",
            },
            {
                id: 2,
                title: ' Is MIT better than IIT?',
                body: "In India, IITs (Indian Institutes of Technology) are considered the best while at the global level, MIT (Massachusetts Institute of Technology) followed by Harvard's John A. Paulson School Of Engineering And Applied Sciences has constantly been at the top."
            },
            {
                id: 3,
                title: 'Is it hard to get into MIT?',
                body: "MIT is one of the most selective schools in the world. A 7.3% acceptance rate means that MIT is extremely competitive to get into. You'll need excellent grades, test scores, essays, and letters of recommendation to even be considered."
            },
            {
                id: 4,
                title: 'Does MIT have a medical school?',
                body: "MIT offers a popular combined MD-PhD programme with Harvard Medical School (HMS), as well as a Harvard-MIT programme in Health Sciences and Technology (HST), which brings MIT and Harvard together with Boston-area hospitals for a unique collaboration."
            }
        ],

    }

    const [activeId1, setActiveId1] = useState( '0' );
    const [activeId2, setActiveId2] = useState( '0' );

    const CustomToggle = ({ children, eventKey }) => {
        const { activeEventKey } = useContext( AccordionContext );
        const decoratedOnClick = useAccordionButton( eventKey );
        const isCurrentEventKey = activeEventKey === eventKey;
        return <button type="button" onClick={ decoratedOnClick } aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{ children }</button>
    }

    function toggleActive1( id ) {
        if (activeId1 === id) {
            setActiveId1(null);
        } else {
            setActiveId1(id);
        }
    }

    function toggleActive2( id ) {
        if (activeId2 === id) {
            setActiveId2(null);
        } else {
            setActiveId2(id);
        }
    }

  return (
    <div className="edu-course-area bg-color-white ">
    {/* edu-section-gap */}
    <div className="container">
        <h6>Scholarships</h6>
        <div className="row g-5 align-items-center mt--20">
            <div className="col-lg-12">
                <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                        {
                        onlineEducation.Scholarship.map( ( accordion, i ) => (
                            <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive1(i.toString())} bsPrefix={`edu-accordion-item ${activeId1 === i.toString() ? 'active-item' : ''}`}>
                                <div className="edu-accordion-header">
                                    <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                </div>
                                <div>
                                <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                                <br/>
                                <Accordion.Body bsPrefix="edu-accordion-body">Eligibility</Accordion.Body>
                                <Accordion.Body bsPrefix="edu-accordion-body">{accordion.eligibility}</Accordion.Body>
                                <br/>
                                <Accordion.Body bsPrefix="edu-accordion-body">Applicability</Accordion.Body>
                                <Accordion.Body bsPrefix="edu-accordion-body">{accordion.applicability}</Accordion.Body>
                                </div>
                            </Accordion.Item>
                        ) )
                        }
                    </Accordion>
                </div>
            </div>
                                
        </div>
        <br></br>
        <br></br>
        <br></br>

        <h6>FAQs</h6>
        <div className="row g-5 align-items-center mt--20">
            <div className="col-lg-12">
                <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                        {
                        onlineEducation.FAQs.map( ( accordion, i ) => (
                            <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive1(i.toString())} bsPrefix={`edu-accordion-item ${activeId1 === i.toString() ? 'active-item' : ''}`}>
                                <div className="edu-accordion-header">
                                    <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                </div>
                                <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                            </Accordion.Item>
                        ) )
                        }
                    </Accordion>
                </div>
            </div>
                                
        </div>

        <br></br>
        <br></br>
        <br></br>

        <h6>Studying in United States</h6>
        <div className="row g-5 align-items-center mt--20">
            <div className="col-lg-12">
                <div className="accordion-style-1">
                    <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                        {
                        onlineEducation.Study.map( ( accordion, i ) => (
                            <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive1(i.toString())} bsPrefix={`edu-accordion-item ${activeId1 === i.toString() ? 'active-item' : ''}`}>
                                <div className="edu-accordion-header">
                                    <CustomToggle eventKey={i.toString()}>{accordion.title}</CustomToggle>
                                </div>
                                <Accordion.Body bsPrefix="edu-accordion-body">{accordion.body}</Accordion.Body>
                            </Accordion.Item>
                        ) )
                        }
                    </Accordion>
                </div>
            </div>
                                
        </div>

    </div>
    </div>
  )
}

export default FAQ_Uni