import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { slugify } from '../../../utils';
import SEO from '../../../common/SEO';
import Layout from '../../../common/Layout';
import CourseInfo from '../../../components/course/CourseInfo';
import UniversityData from './UniversityData.json';
import CourseData from './CourseData.json';
import Main from "../../Main.json";
import InstructorData from '../../../data/instructor/InstructorData.json';
import CurriculumTabContent from '../../../data/course/CurriculumTabContent.json';
import UniInfo from './UniInfo';
import CourseList from './CourseList';
import CourseLoadMore from './CourseList';
import FAQ_Uni from './FAQ_Uni';


const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton( eventKey );
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={decoratedOnClick} aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{children}</button>
}

const CurriculumContent = () => {
    const [activeId, setActiveId] = useState( '0' );

    function toggleActive( id ) {
        if (activeId === id) {
            setActiveId(null);
        } else {
            setActiveId(id);
        }
    }

    return (
        <Accordion bsPrefix="edu-accordion-02" defaultActiveKey={activeId} flush>
            {
                CurriculumTabContent.map( ( accordion, i ) => (
                    <Accordion.Item eventKey={i.toString()} key={i} onClick={() => toggleActive(i.toString())} bsPrefix={`edu-accordion-item ${activeId === i.toString() ? 'bg-active' : ''}`}>
                        <div className="edu-accordion-header">
                            <CustomToggle eventKey={i.toString()}>{ accordion.title }</CustomToggle>
                        </div>
                        <Accordion.Body bsPrefix="edu-accordion-body">
                            <ul>
                                { 
                                    accordion.content.map( ( title, index ) => (
                                        <li key={index}>
                                            <div className="text"><i className="icon-draft-line"></i>{title}</div>
                                            <div className="icon"><i className="icon-lock-password-line"></i></div>
                                        </li>
                                    ) )
                                
                                }
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                ) )
            }
        </Accordion>
    )
}

const Uni_Details = () => {
    const { id } = useParams();
    const courseId = parseInt( id, 10 );
    const data = UniversityData.filter( course => course.id === courseId );
    const UniversityItem = data[0];
    

    const indexOfInstructor = InstructorData.findIndex( function( instructor ) {
        return slugify( instructor.name ) === slugify( UniversityItem.instructor );
    } );
    const instructor = InstructorData[indexOfInstructor];
    const instructorExcerpt = instructor.details.substring(0, 157) + "...";

    return (
        <>
            {/* <SEO title={ UniversityItem.title } /> */}
            <Layout>
                <div className="edu-course-details-area edu-section-gap bg-color-white">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-12">
                                <div className="main-image thumbnail">
                                    <img className="radius-small" src={`${process.env.PUBLIC_URL}${UniversityItem.image}`} alt="Course Thumb" />
                                </div>
                            </div>
                        </div>

                        <div className="row g-5">
                            <div className="col-xl-8 col-lg-7">
                                <div className="course-details-content mt--30">

                                    <h3 className="title">{ UniversityItem.title }</h3>
                                    {/* <h3 className="title">Massachusetts Institute Of Technology (MIT)</h3> */}

                                    <div className="course-details-card">
                                        <div className="course-details-two-content">
                                            <div dangerouslySetInnerHTML={{__html: UniversityItem.details}} />
                                        </div>
                                    </div>

                                   
                                    <CourseList />

                                    <div className="course-details-card mt--40">
                                        <div className="course-details-two-content">
                                            {/* <CurriculumContent /> */}
                                            <FAQ_Uni/>
                                        </div>
                                    </div>
                                    
                                    
                                    

                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5">
                                <UniInfo data={UniversityItem}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default Uni_Details;