import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Button,
  Container,
  IconButton,
  Link,
} from "@mui/material";
import { motion } from "framer-motion";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

const AddNewStudentApplicationGetStarted = () => {
  const navigate = useNavigate();
  return (
    <div className=" w-full h-full justify-center flex items-center">
      <img src="/images/applicationflow/countrybg.jpg" />

      <div className="flex w-full h-screen justify-center items-center fixed ">
        <div className="justify-center items-center absolute mb-10 w-[1000px] h-fit rounded-[80px]  backdrop-filter bg-blue-700 backdrop-blur-md bg-opacity-10 shadow-black shadow-lg   border-gray-600">
          <div className="flex   bg-none sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
            <Paper className="h-full sm:h-auto bg-transparent md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2  p-12   sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
              <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
                <div className="w-full flex justify-center items-center">
                  <img
                    className="logo-light h-32 w-32 mr-10 mb-10"
                    src="/images/logo/msa_logo.png"
                    alt="My Story Abroad"
                  />
                </div>
                <label className="font-extrabold text-[30px] ml-2 tracking-tighter w-full">
                  Most Reliable Study Abroad Expert
                </label>
                <div className="w-full flex justify-center items-center  mt-20 font-bold">
                  Thinking of studying abroad? But don't know which universities
                  & courses are best-fit for you! Try our AI powered University
                  Course Finder. Feed in your preferences, let the AI match them
                  against millions of data points & voila! you get what you are
                  looking for, saving you hours of research.
                </div>
                <div className="flex justify-center mt-16 items-center w-full">
                  <button
                    onClick={() => navigate("/application/select-country")}
                    rel="noopener"
                    className="px-40 py-4 mt-14 text-[20px]  text-center text-black font-bold bg-sky-600 bg-opacity-50 rounded-3xl "
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </Paper>

            <img
              src="images/applicationflow/anotherimage.svg"
              className=" w-[500px] h-[550px]  rounded-r-[70px]  shadow-lg shadow-black bg-blue-300 bg-opacity-50"
            />
            {/* <Box
        className="relative bg-cyan-600 bg-opacity-70 hidden md:flex flex-auto items-center justify-center h-full py-48 lg:px-112 overflow-hidden rounded-3xl rounded-r-full"
        sx={{ backgroundColor: 'primary.main' }}
      >
        <svg
          className="absolute inset-0 pointer-events-none "
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: 'primary.light' }}
            className="opacity-20 bg-cyan-400"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20 "
          sx={{ color: 'primary.light' }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
        </Box>

        <div className="z-10 relative w-full max-w-2xl">
          <div className="text-7xl font-bold leading-none text-black">
            <div>Welcome to</div>
            <div>our community</div>
          </div>
          <div className="mt-24 text-lg tracking-tight leading-6 text-gray-800">
            Fuse helps developers to build organized and well coded dashboards full of beautiful and
            rich modules. Join us and start building your application today.
          </div>
          <div className="flex items-center mt-32">
         
            <div className="ml-16 font-medium tracking-tight text-black">
              More than 17k people joined us, it's your turn
            </div>
          </div>
        </div>
      </Box> 
    */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewStudentApplicationGetStarted;
