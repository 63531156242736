import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCourseType } from "./store/GetCourseTypeSlice";
import { Grid, Typography, IconButton } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import bg from "../../assets/images/bg/Japan_Skyline.jpg";

const AddNewStudentApplicationSelectCourseType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllCourseType());
  }, []);

  const state = useSelector((state) => state.getcoursetype);
  const coursetype = state.data;
  // console.log("State" ,state)

  const handleOnBack = () => {
    navigate("/application/select-country");
  };

  return (
    <>
      <IconButton
        className="absolute z-10 text-black xl:flex mmd:flex md:hidden sm:hidden xs:hidden"
        onClick={handleOnBack}
      >
        <ArrowBackRoundedIcon style={{ fontSize: "32px" }} />
      </IconButton>

      <div
        className="
        flex w-full h-screen justify-center items-center fixed
        xxl:w-full xxl:h-screen xxl:fixed xxl:mt-0
        xl:w-full xl:h-screen xl:fixed xl:mt-10
        lg:w-full lg:h-screen lg:fixed lg:mt-5
        mmd:pb-64 mmd:w-screen mmd:min-h-screen mmd:absolute
        md:pb-0 md:w-screen md:min-h-screen md:absolute
        sm:pb-0 sm:w-screen sm:min-h-screen sm:absolute
        xs:pb-0 xs:w-screen xs:min-h-screen xs:absolute"
      >
        <img
          src="/images/applicationflow/countrybg.jpg"
          className="
            xxl:w-screen xxl:h-screen
            xl: w-screen xl:h-screen
            lg:w-screen lg:h-screen
            md:w-screen md:h-screen
            sm:w-screen sm:h-screen
            xs:w-screen xs:h-screen"
        />
        <div
          className="
          justify-center items-center absolute  w-[800px] h-[490px] rounded-[30px] 
          backdrop-filter bg-blue-700 backdrop-blur-md bg-opacity-10 hover:shadow-gray-500 
          shadow-xl border-gray-600
          xxl:rounded-[30px] xxl:h-[490px]
          xl:w-[800px] xl:h-[300px] xl:mb-32 
          lg:w-[800px] lg:h-[490px] lg:mb-0 lg:mt-44
          mmd:w-fit mmd:min-h-[490px] mmd:mb-0
          md:w-fit md:min-h-[490px md:mb-0
          sm:w-fit sm:min-h-full sm:h-auto sm:mb-0
          xs:w-screen xs:min-h-full xs:h-auto xs:mb-0
          "
        >
          <Grid container textAlign={"center"} style={{ cursor: "pointer" }}>
            <Grid item xs={12}>
              <label className="text-2xl text-purple-700">&nbsp;</label>
            </Grid>
            <Grid item xs={12}>
              <label className="text-6xl mt-5 mb-5 text-[#181a1b] tracking-wide font-bold">
                Choose Course Level
              </label>
              <hr className="border-black mb-10"></hr>
            </Grid>
          </Grid>

          <div
            className="
              mb-12 
              lg:w-full lg:grid lg:grid-cols-2 
              md:w-full md:h-auto md:grid md:grid-cols-2 md:place-items-center md:mb-0
              mmd:w-full mmd:h-auto mmd:grid mmd:grid-cols-2 mmd:place-items-center mmd:mb-0
              sm:w-full sm:h-auto sm:grid sm:grid-cols-2 sm:place-items-center
              xs:w-full xs:h-auto xs:grid xs:grid-cols-2 xs:place-items-center"
          >
            <Grid items xs={6} className="mt-24">
              <button
                onClick={() =>
                  navigate("/application/select-youreducation", {
                    state: "Bachelors",
                  })
                }
                className=" w-fit h-fit rounded-3xl"
              >
                <div className=" items-center group  w-[150px] h-[150px] bg-[#2b9de460] hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl">
                  <label className="flex items-center bg-[#181a1b] p-6 ml-4 rounded-full h-fit w-fit">
                    <SchoolIcon sx={{ fontSize: "2em", color: "white" }} />
                  </label>
                  <label className="text-black font-extrabold text-[15px]  mt-3">
                    Bachelors
                  </label>
                </div>
              </button>
            </Grid>

            <Grid items xs={6} className="mt-24">
              <button
                onClick={() =>
                  navigate("/application/select-youreducation", {
                    state: "Masters",
                  })
                }
                className=" w-fit h-fit rounded-3xl"
              >
                <div className=" items-center group  w-[150px] h-[150px] bg-[#2b9de460] hover:bg-[#2bccb160] backdrop-blur-sm transition-colors duration-300 ease-in-out p-5   rounded-3xl">
                  <label className="flex items-center bg-[#181a1b] p-6 ml-4 rounded-full h-fit w-fit">
                    <SchoolIcon sx={{ fontSize: "2em", color: "white" }} />
                  </label>
                  <label className="text-black font-extrabold text-[15px]  mt-3">
                    Masters
                  </label>
                </div>
              </button>
            </Grid>
          </div>
          {/* <div className="flex w-full rounded-full mt-[58px]  items-center justify-center space-x-8">

            <div
              //   onClick={handleClickData}
              className="ease-in delay-100 rounded-t-[30px] tracking-widest text-opacity-100 font-extrabold rounded-b-[30px] p-8 w-full  text-[#5D3FD3]  border-b-[12px] border-l-[15px] border-r-[15px] text-[20px] opacity-80 border-[#2b9de460]   "
              loadingPosition="start"
              variant="contained"
            // onClick={handleparticular}
            >
              &nbsp;
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AddNewStudentApplicationSelectCourseType;
