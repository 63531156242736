import React from "react";
import SEO from /*'../../common/SEO'*/ "../../../common/SEO";
import Layout from "../../../common/Layout";
import BreadcrumbOne from "./BreadcrumbOne";
import UniversitiesCourse from "./UniversitiesCourse";
import UniversityData from "./UniversityData.json";
import PaginationOne from "../../../components/pagination/PaginationOne";
import SearchOne from "../../../components/widgets/blog/SearchOne";
import UniversitiesFilter from "./UniversitiesFilter";
import { useFilterContext } from './filter_context';
import Main from "../../Destination/Main.json";
import { useParams } from "react-router-dom";

const UKUniversities = () => {
  const CourseItems = UniversityData.slice(0, 9);
  const {name} = useParams();
  // const blogId = name;
  const dataItem =Main.filter(rishabh => rishabh.name === name);
  const data = dataItem[0];
  // console.log(data);
  return (
    <>
      <SEO title ='University' />
      <Layout>
        <BreadcrumbOne
          title={data.universityUpperContent[0].title}
          rootUrl="/"
          parentUrl="Home"
 
          currentUrl={data.universityUpperContent[0].name}
          description={data.universityUpperContent[0].titlePara}
          btn="read more"
          datas={data}
        />

        <div className="edu-course-area edu-section-gap bg-color-white">
          <div className="container">
            <UniversitiesFilter 
            datas={ data } />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default UKUniversities;
