import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCourseType } from "./store/GetCourseTypeSlice";
import {
  Grid,
  IconButton,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import SchoolIcon from "@mui/icons-material/School";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import bg from "../../assets/images/bg/London_bg_1.jpg";
import { Controller, useForm } from "react-hook-form";
// import {toast} from "react-toastify"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorIcon from "@mui/icons-material/Error";

const schema = yup.object().shape({
  emptyvalue: yup.string().required("Values cannot be empty"),
  percentage12: yup
    .string()
    .required("Please enter percentage")
    .matches(
      "^(?!0[0-9]|1[0-9]{2}|100$)[3-9][0-9]?|100$",
      "Percentage should be between 33-100"
    )
    .max(2, "Percentage should be between 33-100"),
  percentagediploma: yup
    .string()
    .required("Please enter percentage")
    .matches(
      "^(?!0[0-9]|1[0-9]{2}|100$)[3-9][0-9]?|100$",
      "Percentage should be between 33-100"
    ),
  percentageUG: yup
    .string()
    .required("Please enter percentage")
    .matches(
      "^(?!0[0-9]|1[0-9]{2}|100$)[3-9][0-9]?|100$",
      "Percentage should be between 33-100"
    ),
  percentagePG: yup
    .string()
    .required("Please enter percentage")
    .matches(
      "^(?!0[0-9]|1[0-9]{2}|100$)[3-9][0-9]?|100$",
      "Percentage should be between 33-100"
    ),
  board: yup.mixed().required("Please enter board"),
});

const defaultValues = {
  emptyvalue: "",
  percentage12: "",
  percentagediploma: "",
  percentageUG: "",
  percentagePG: "",
  board: "",
};

const AddNewStudentApplicationSelectEducationLevel = () => {
  const { control, formState, handleSubmit, setError, setValue, watch } =
    useForm({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema),
    });
  const data = watch();
  const { isValid, dirtyFields, errors } = formState;
  const navigate = useNavigate();
  const state = useLocation();
  // console.log(state);
  const mydata = state.state;

  console.log("this is state in select educastion level",state.state)
  const coursetype = state.data;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllCourseType());
  }, []);
  const coursedata = useSelector((coursedata) => coursedata.getcoursetype);
  const Coursetypes = coursedata.data;

  const [openCard, setOpenCard] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleOnBack = () => {
    navigate("/application/select-coursetype");
  };

  const handleOnContinue = () => {
    let educationallevel;
    if(mydata==="Bachelors")
    {
   educationallevel=data.percentage12!==""?{"grade12":{"per":data.percentage12,"board":data.board}}:{"diploma":data.percentagediploma}
    }
    else{
      educationallevel=data.percentagePG!==""?{"PG":data.percentagePG}:{"UG":data.percentageUG}
    }
    
    navigate("/application/select-major", { state:{"track":mydata,educationallevel} });
  };

  const handleCardToggle = (title) => {
    setOpenCard(openCard === title ? "" : title);
  };

  // Handle on click for std 12
  const handleStd12Submit = () => {
    // Form Submission Logic
    if (errors.percentage12) {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.5)",
            backgroundColor: "#f20000",
            color: "white",
            // width: "fit",
            // position: "fixed",
            top: "100px",
            // right: "50px",
            // transform: "translate(-50%)",
            transition: " ease-in",
            zIndex: 9999,
          },
        }
      );
    } else if (data.percentage12.trim() == "") {
      setErrorMessage("Please enter percentage");
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please Enter
          Percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          // position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.2)",
            backgroundColor: "#f20000",
            color: "white",
            width: "fit",
            // position: "fixed",
            top: "100px",
            // right: "20%",
            // transform: "translate(-50%)",
            transition: " ease-in",
          },
        }
      );
    } else if (data.board == "") {
      setErrorMessage("Please enter board");

      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please select
          board
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          // position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.2)",
            backgroundColor: "#f20000",
            color: "white",
            width: "fit",
            // position: "fixed",
            top: "100px",
            // right: "20%",
            // transform: "translate(-50%)",
            transition: " ease-in",
          },
        }
      );
    } else {
      console.log("This is the data ", data);
      handleOnContinue();
    }
  };

  // Handle on click for PG
  const handlePGSubmit = () => {
    if (errors.percentagePG) {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          // position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.2)",
            backgroundColor: "#f20000",
            color: "white",
            width: "fit",
            // position: "fixed",
            top: "100px",
            // left: "42%",
            // transform: "translate(-50%)",
            transition: " ease-in",
          },
        }
      );
    } else if (data.percentagePG.trim() == "") {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          // position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.2)",
            backgroundColor: "#f20000",
            color: "white",
            width: "fit",
            // position: "fixed",
            top: "100px",
            // left: "42%",
            // transform: "translate(-50%)",
            transition: " ease-in",
          },
        }
      );
    } else {
      console.log("This is the data ", data);
      handleOnContinue();
    }
  };

  // Handle on click for UG
  const handleUGSubmit = () => {
    if (errors.percentageUG) {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          // position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.2)",
            backgroundColor: "#f20000",
            color: "white",
            width: "fit",
            // position: "fixed",
            top: "100px",
            // left: "42%",
            // transform: "translate(-50%)",
            transition: " ease-in",
          },
        }
      );
    } else if (data.percentageUG.trim() == "") {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          // position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.2)",
            backgroundColor: "#f20000",
            color: "white",
            width: "fit",
            // position: "fixed",
            top: "100px",
            // left: "42%",
            // transform: "translate(-50%)",
            transition: " ease-in",
          },
        }
      );
    } else {
      console.log("This is the data ", data);
      handleOnContinue();
    }
  };

  // Handle on click for Diploma
  const handleDiplomaSubmit = () => {
    if (errors.percentagediploma) {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          // position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.2)",
            backgroundColor: "#f20000",
            color: "white",
            width: "fit",
            // position: "fixed",
            top: "100px",
            // left: "42%",
            // transform: "translate(-50%)",
            transition: " ease-in",
          },
        }
      );
    } else if (data.percentagediploma.trim() == "") {
      toast(
        <>
          <ErrorIcon sx={{ fontSize: "2em", color: "white" }} /> Please enter
          percentage
        </>,
        {
          autoClose: 2000,
          hideProgressBar: true,
          // Set to 15sec
          // position: toast?.POSITION?.BOTTOM_CENTER,
          style: {
            boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.2)",
            backgroundColor: "#f20000",
            color: "white",
            width: "fit",
            // position: "fixed",
            top: "100px",
            // left: "42%",
            // transform: "translate(-50%)",
            transition: " ease-in",
          },
        }
      );
    } else {
      console.log("This is the data ", data);
      handleOnContinue();
    }
  };

  return (
    <>
      <IconButton
        className="absolute z-10 text-black mmd:flex md:hidden sm:hidden xs:hidden"
        onClick={handleOnBack}
      >
        <ArrowBackRoundedIcon style={{ fontSize: "32px" }} />
      </IconButton>
      <ToastContainer />
      <div
        className="
        flex w-full h-screen justify-center items-center fixed
        xxl:w-full xxl:h-screen xxl:fixed xxl:mt-0
        xl:w-full xl:h-screen xl:fixed xl:mt-10
        lg:w-full lg:h-screen lg:fixed lg:mt-5
        mmd:pb-64 mmd:w-screen mmd:min-h-screen mmd:absolute
        md:pb-0 md:w-screen md:min-h-screen md:absolute
        sm:pb-0 sm:w-screen sm:min-h-screen sm:absolute
        xs:pb-0 xs:w-screen xs:min-h-screen xs:absolute"
      >
        <img
          src="/images/applicationflow/countrybg.jpg"
          className="
            xxl:w-screen xxl:h-screen
            xl: w-screen xl:h-screen
            lg:w-screen lg:h-screen
            md:w-screen md:h-screen
            sm:w-screen sm:h-screen
            xs:w-screen xs:h-screen"
        />

        <div
          className={`
          justify-center items-center mb-28 absolute  w-[800px] 
          ${openCard === "POST GRADUATE" ? "h-auto mt-36" : "h-[490px]"} 
          ${openCard === "UNDER GRADUATE" ? "h-auto mt-36" : "h-[490px]"} 
          ${openCard === "Diploma" ? "h-auto mt-36" : "h-[490px] "} 
          ${openCard === "Grade 12" ? "h-auto mt-36" : "h-[490px]"} 
          rounded-[30px] backdrop-filter bg-blue-700 backdrop-blur-md bg-opacity-10 hover:shadow-gray-500 shadow-xl  
          border-gray-600
          xxl:rounded-[30px] xxl:h-[490px]
          xl:w-[800px] xl:h-[300px] xl:mb-32 
          lg:w-[800px] lg:h-[490px] lg:mb-0 lg:mt-44
          mmd:w-fit mmd:min-h-[490px] mmd:mb-0
          md:w-fit md:min-h-[490px md:mb-0
          sm:w-fit sm:min-h-full sm:h-auto sm:mb-0
          xs:w-screen xs:min-h-full xs:h-auto xs:mb-0
          `}
        >
          <Grid container textAlign={"center"} style={{ cursor: "pointer" }}>
            <Grid item xs={12}>
              <label className="text-2xl text-purple-700 font-bold">
                Current Track: {mydata}
              </label>
            </Grid>
            <Grid item xs={12}>
              <label
                className={`text-6xl mt-2 
               ${openCard === "POST GRADUATE" ? "mb-0" : "mb-[56px]"} 
          ${openCard === "UNDER GRADUATE" ? "mb-0" : "mb-[56px]"} 
          ${openCard === "Diploma" ? "mb-0" : "mb-[56px]"} 
          ${openCard === "Grade 12" ? "mb-0" : "mb-[56px]"}  
          text-[#181a1b] tracking-wide font-bold`}
              >
                Highest Level of Education
              </label>
              <hr className="border-black mb-6"></hr>
            </Grid>

            {Coursetypes &&
              Coursetypes.filter((element) => element.before == mydata).map(
                (name) => (
                  <Grid
                    items
                    xs={6}
                    className={` ${
                      openCard === "Diploma" ||
                      openCard == "Grade 12" ||
                      openCard === "UNDER GRADUATE" ||
                      openCard === "POST GRADUATE"
                        ? ""
                        : "mt-[44px]"
                    } `}
                  >
                    <button
                      onClick={() => handleCardToggle(name.title)} // Pass the title of the card to handleCardToggle function
                    >
                      <div
                        className="
                        items-center group mt-10 w-[150px] h-[150px] bg-[#2b9de460] hover:bg-[#2bccb160] 
                        backdrop-blur-sm transition-colors duration-300 ease-in-out p-5 rounded-3xl"
                      >
                        <label className="flex items-center bg-[#181a1b] p-6 ml-4 rounded-full h-fit w-fit">
                          <SchoolIcon
                            sx={{ fontSize: "2em", color: "white" }}
                          />
                        </label>
                        <label className="text-black font-extrabold text-[15px] mt-3">
                          {name.title}
                        </label>
                      </div>
                    </button>
                  </Grid>
                )
              )}

            {/* ---------------------------------------------------------FOR DIPLOMA--------------------------------------------------------------- */}
            <Grid item xs={12}>
              <div
                className="mt-10"
                style={{ display: openCard === "Diploma" ? "flex" : "none" }} // Check if the openCard state is set to "Diploma"
              >
                <div className="w-full justify-center">
                  <label
                    className="
                    flex justify-center items-center text-3xl text-black tracking-wider font-bold
                    mmd:text-[20px]
                    md:text-[15px]
                    sm:text-[15px]
                    xs:text-[15px]"
                  >
                    What is your gained or expected Percentage: &nbsp;
                    <Controller
                      name="percentagediploma"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            sx={{
                              "& input": {
                                fontSize: "1.5rem",
                              },
                              "& .MuiFormHelperText-root": {
                                fontSize: "1rem", // Adjust the font size here as needed
                              },
                            }}
                            {...field}
                            type="text"
                            id="percentagediploma"
                            className="w-fit md:p-3 sm:p-3 xs:p-3"
                            error={errors.percentagediploma}
                            helperText={
                              <>
                                {errors?.percentagediploma?.message && (
                                  <span className="text-red-700">
                                    {errors?.percentagediploma?.message}
                                  </span>
                                )}
                              </>
                            }
                            onKeyPress={(event) => {
                              // Prevent non-numeric characters
                              const charCode = event.which
                                ? event.which
                                : event.keyCode;
                              if (
                                charCode > 31 &&
                                (charCode < 48 || charCode > 57)
                              ) {
                                event.preventDefault();
                              }
                            }}
                            inputMode="numeric"
                            placeholder="percentage %"
                            required
                          />
                        </>
                      )}
                    />
                  </label>
                  <div className="">
                    <button
                      onClick={handleDiplomaSubmit}
                      className="font-extrabold w-fit h-fit mt-10 mb-10 px-40 py-4 text-[20px] rounded-full bg-[#02133e] text-white"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </Grid>

            {/* ---------------------------------------------------------FOR 12TH------------------------------------------------------------------ */}
            <Grid item xs={12}>
              <div
                className="mt-3 w-full"
                style={{ display: openCard === "Grade 12" ? "flex" : "none" }}
              >
                <Grid
                  container
                  textAlign={"center"}
                  style={{ cursor: "pointer" }}
                >
                  <Grid item xs={12}>
                    <div className="w-full justify-center">
                      <label className="flex text-3xl justify-center items-center text-black tracking-wider font-bold">
                        <label
                          className="
                          mb-[10px] mr-12
                          lg:text-[20px] lg:mb-0
                          mmd:text-[20px]
                          md:text-[15px]
                          sm:text-[15px]
                          xs:text-[15px]"
                        >
                          Select Board:
                        </label>
                        <Controller
                          name="board"
                          control={control}
                          render={({ field }) => (
                            <FormControl
                              className="
                                w-[200px] mb-[10px] 
                                lg:mb-0
                                md:p-3 
                                sm:p-3 
                                xs:p-3 
                                xs:w-52"
                              error={errors.board}
                            >
                              <InputLabel id="board" className="text-black ">
                                Select Board
                              </InputLabel>
                              <Select
                                labelId="board"
                                {...field}
                                label="Select Board"
                                className="  text-black"
                              >
                                <MenuItem value={"ICSE"}>ICSE</MenuItem>
                                <MenuItem value={"CBSE"}>CBSE</MenuItem>
                                <MenuItem value={"STATE"}>STATE</MenuItem>
                              </Select>
                              <FormHelperText>
                                <label className="ml-3 mb-2 text-[12px] text-red-500 font-extrabold">
                                  {errors.board?.message}
                                </label>
                              </FormHelperText>
                            </FormControl>
                          )}
                        />
                      </label>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="w-full justify-center  items-center flex">
                      <label
                        className="
                        text-3xl mb-8  text-black tracking-wider font-bold
                        mmd:text-[20px]
                        md:text-[15px]
                        sm:text-[15px]
                        xs:text-[15px]"
                      >
                        What is your gained or expected Percentage: &nbsp;
                      </label>
                      <Controller
                        name="percentage12"
                        control={control}
                        render={({ field }) => (
                          <>
                            <TextField
                              sx={{
                                "& input": {
                                  fontSize: "1.5rem",
                                },
                                "& .MuiFormHelperText-root": {
                                  fontSize: "1rem", // Adjust the font size here as needed
                                },
                              }}
                              {...field}
                              type="text"
                              id="percentage12"
                              className=" w-fit mb-5 md:p-3 sm:p-3 xs:p-3"
                              error={errors.percentage12}
                              helperText={
                                <>
                                  {errors?.percentage12?.message && (
                                    <span className="text-red-700">
                                      {errors?.percentage12?.message}
                                    </span>
                                  )}
                                  {!errors?.percentage12?.message &&
                                    errorMessage && (
                                      <span className="text-red-700">
                                        {errorMessage}
                                      </span>
                                    )}
                                </>
                              }
                              onKeyPress={(event) => {
                                // Prevent non-numeric characters
                                const charCode = event.which
                                  ? event.which
                                  : event.keyCode;
                                if (
                                  charCode > 31 &&
                                  (charCode < 48 || charCode > 57)
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              inputMode="numeric"
                              placeholder="percentage %"
                              required
                            />
                          </>
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <button
                      onClick={handleStd12Submit}
                      className="
                        font-extrabold w-fit h-fit px-40 py-4 text-[20px] rounded-full bg-[#02133e] text-white
                        lg:mb-10"
                    >
                      Continue
                    </button>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {/* ---------------------------------------------------------FOR UG-------------------------------------------------------------------- */}
            <Grid item xs={12}>
              <div
                className="mt-10"
                style={{
                  display: openCard === "UNDER GRADUATE" ? "flex" : "none",
                }}
              >
                <div className="w-full justify-center">
                  <label
                    className="
                    flex justify-center items-center text-3xl text-black tracking-wider font-bold
                    mmd:text-[20px]
                    md:text-[15px]
                    sm:text-[15px]
                    xs:text-[15px]"
                  >
                    What is your gained or expected Percentage? &nbsp;
                    <Controller
                      name="percentageUG"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            sx={{
                              "& input": {
                                fontSize: "1.5rem",
                              },
                              "& .MuiFormHelperText-root": {
                                fontSize: "1rem", // Adjust the font size here as needed
                              },
                            }}
                            {...field}
                            type="text"
                            id="percentageUG"
                            className="w-fit md:p-3 sm:p-3 xs:p-3"
                            error={errors.percentageUG}
                            helperText={
                              <>
                                {errors?.percentageUG?.message && (
                                  <span className="text-red-700">
                                    {errors?.percentageUG?.message}
                                  </span>
                                )}
                              </>
                            }
                            onKeyPress={(event) => {
                              // Prevent non-numeric characters
                              const charCode = event.which
                                ? event.which
                                : event.keyCode;
                              if (
                                charCode > 31 &&
                                (charCode < 48 || charCode > 57)
                              ) {
                                event.preventDefault();
                              }
                            }}
                            inputMode="numeric"
                            placeholder="percentage %"
                            required
                          />
                          {/* <FormHelperText>
                            <label className="ml-3 mb-2 text-[12px] text-red-700 font-extrabold">
                              {errors?.percentageUG?.message}
                            </label>
                          </FormHelperText> */}
                        </>
                      )}
                    />
                  </label>
                  <div className="">
                    <button
                      onClick={handleUGSubmit}
                      className="font-extrabold w-fit h-fit mt-10 px-40 py-4 text-[20px] rounded-full bg-[#02133e] text-white"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </Grid>

            {/* ---------------------------------------------------------FOR PG-------------------------------------------------------------------- */}

            <Grid item xs={12}>
              <div
                className="mt-10"
                style={{
                  display: openCard === "POST GRADUATE" ? "flex" : "none",
                }}
              >
                <div className="w-full justify-center">
                  <label
                    className="
                    flex justify-center items-center text-3xl text-black tracking-wider font-bold
                    mmd:text-[20px]
                    md:text-[15px]
                    sm:text-[15px]
                    xs:text-[15px]"
                  >
                    What was your overall percentage gained in Under Graduation
                    or Diploma?&nbsp;
                  </label>
                  <label className="text-3xl text-black tracking-wider font-bold">
                    <Controller
                      name="percentagePG"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            sx={{
                              "& input": {
                                fontSize: "1.5rem",
                              },
                              "& .MuiFormHelperText-root": {
                                fontSize: "1rem", // Adjust the font size here as needed
                              },
                            }}
                            {...field}
                            type="text"
                            id="percentagePG"
                            className="w-fit md:p-3 sm:p-3 xs:p-3"
                            error={errors.percentagePG}
                            helperText={
                              <>
                                {errors?.percentagePG?.message && (
                                  <span className="text-red-700">
                                    {errors?.percentageG?.message}
                                  </span>
                                )}
                              </>
                            }
                            onKeyPress={(event) => {
                              // Prevent non-numeric characters
                              const charCode = event.which
                                ? event.which
                                : event.keyCode;
                              if (
                                charCode > 31 &&
                                (charCode < 48 || charCode > 57)
                              ) {
                                event.preventDefault();
                              }
                            }}
                            inputMode="numeric"
                            placeholder="percentage %"
                            required
                          />
                        </>
                      )}
                    />
                  </label>
                  <div className="">
                    <button
                      onClick={handlePGSubmit}
                      className=" font-extrabold w-fit h-fit  mb-10 px-40 py-4 text-[20px] rounded-full bg-[#02133e] text-white"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddNewStudentApplicationSelectEducationLevel;
