import React from 'react';
import { Link } from 'react-router-dom';

const IeltSteps = ( { wrapperClass ,datas} ) => {
    return (
        <div className={`home-three-about edu-about-area about-style-4 bg-color-white`} >
            <div className="container eduvibe-animated-shape" >
                <div className="row g-lg-5 g-md-5 g-sm-5">
                    
                
                    <div className="col-lg-12 col-xl-12">
                        <div className="inner mt_mobile--40">
                            {/* <div className="section-title text-start">
                                <span className="pre-title">Who We Are</span>
                                <h6>You can either register online and pay the test fee by Credit Card, or register by Courier and pay test fee by Demand Draft or ICICI deposit/Allahabad deposit slip or register in Person at IDP branch or Input Node</h6>
                            </div> */}
                            <div className="feature-style-6 mt--10">
                                {datas.step[0].detail.map((steps)=>{
                                    return(
                                        <div className="edu-feature-list color-variation-1">
                                        <div className="icon">
                                            <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                        </div>
                                        <div className="content">
                                            
                                            {/* <h6 className="title">Step 1</h6> */}
                                            <p className="description" style={{fontSize:"20px"}}>{steps}</p>
                                        </div>
                                    </div>

                                    )
                                })}
                                

                                {/* <div className="edu-feature-list color-variation-2">
                                    <div className="icon">
                                        <img src="/images/about/about-05/book.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Step 2</h6>
                                        <p className="description">Select your test date and test city</p>
                                    </div>
                                </div> */}
                                
                                {/* <div className="edu-feature-list color-variation-3">
                                    <div className="icon">
                                        <img src="/images/about/about-05/reward.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Step 3</h6>
                                        <p className="description" style={{fontSize:"20px"}}>Complete the online application form</p>
                                    </div>
                                </div> */}
                                {/* <div className="edu-feature-list color-variation-3">
                                    <div className="icon">
                                        <img src="/images/about/about-05/reward.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Step 4</h6>
                                        <p className="description">Pay the test fee by credit card</p>
                                    </div>
                                </div> */}
                                {/* <div className="edu-feature-list color-variation-1">
                                    <div className="icon">
                                        <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Step 5</h6>
                                        <p className="description" style={{fontSize:"20px"}}>Get instant seat booking and acknowledgement</p>
                                    </div>
                                </div> */}
                                
                            </div>
                        </div>
                        <div className="read-more-btn mt--30"  id="tip1">
                            <Link className="edu-btn" to="#">Know About Us <i className="icon-arrow-right-line-right"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                        <div className="inner mt_mobile--40">
                            {/* <div className="section-title text-start">
                                <span className="pre-title">Who We Are</span>
                                <h6>You can either register online and pay the test fee by Credit Card, or register by Courier and pay test fee by Demand Draft or ICICI deposit/Allahabad deposit slip or register in Person at IDP branch or Input Node</h6>
                            </div> */}
                            <div className="feature-style-6 mt--40">
                                {/* <div className="edu-feature-list color-variation-1">
                                    <div className="icon">
                                        <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Step 1</h6>
                                        <p className="description">Go to the official website for IELTS in India.</p>
                                    </div>
                                </div> */}

                                {/* <div className="edu-feature-list color-variation-2">
                                    <div className="icon">
                                        <img src="/images/about/about-05/book.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Step 2</h6>
                                        <p className="description"style={{fontSize:"20px"}}>Select your test date and test city</p>
                                    </div>
                                </div>
                                 */}
                                {/* <div className="edu-feature-list color-variation-3">
                                    <div className="icon">
                                        <img src="/images/about/about-05/reward.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Step 3</h6>
                                        <p className="description">Complete the online application form</p>
                                    </div>
                                </div> */}
                                {/* <div className="edu-feature-list color-variation-3">
                                    <div className="icon">
                                        <img src="/images/about/about-05/reward.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Step 4</h6>
                                        <p className="description"style={{fontSize:"20px"}}>Pay the test fee by credit card</p>
                                    </div>
                                </div> */}
                                {/* <div className="edu-feature-list color-variation-1">
                                    <div className="icon">
                                        <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Step 5</h6>
                                        <p className="description">Get instant seat booking and acknowledgement</p>
                                    </div>
                                </div> */}
                                
                            </div>
                        </div>
                        {/* <div className="read-more-btn mt--30"  id="tip1">
                            <Link className="edu-btn" to="#">Know About Us <i className="icon-arrow-right-line-right"></i></Link>
                        </div> */}
                    </div>

                   

                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-03-08.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-27.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IeltSteps;