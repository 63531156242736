import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const FeatureItems = [
    {
        title: '15,000+ ',
        titlee: 'Student ',
        info: 'received offers from top universities in UK, US, Australia, Canada & more.',
        icon: 'icon-Destination',
        shape: 'shape-bg-1',
        link: '#'
    },
    {
        title: '650+',
        titlee: 'University Partners',
        info: 'abroad for seamless application submission and student experience',
        icon: 'icon-Browser',
        shape: 'shape-bg-2',
        link: '#'
    },
    {
        title: '2 Million+',
        titlee: 'Counsellings',
        info: 'in the last 6 years of our existence, growing at a 150,000+ every month today',
        icon: 'icon-Lock',
        shape: 'shape-bg-3',
        link: '#'
    },
    {
        title: '80 Million+',
        titlee: 'Users',
        info: 'who browsed the platform last year across our wide range of products.',
        icon: 'icon-Settings',
        shape: 'shape-bg-4',
        link: '#'
    }
];

const Featuree = ( { wrapperClass, bgColor, alignment, style } ) => {
    return (
        <div className={ `row eduvibe-about-one-service ${ wrapperClass || 'g-5 mt--20' }` }>
            { FeatureItems.map( ( data , i ) => (
                <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-3 col-md-6 col-12${ style ? ' '+style : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className={ `service-card service-card-3 ${ alignment ? ' ' + alignment : 'text-left' } ${ data.shape } ${ bgColor || '' } ` }>
                        <div className="inner">
                            <div className="icon">
                                <a href={ data.link }>
                                    <i className={ data.icon }></i>
                                </a>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }<br></br>{data.titlee}</a></h6>
                                <p className="description">{ data.info }</p>
                                {/* <div className="read-more-btn">
                                    <a className="btn-transparent sm-size heading-color" href={ data.link }>Learn More<i className="icon-arrow-right-line-right"></i></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default Featuree;