import React, { useState } from 'react'
import Slider from 'react-slick';
import BreadcrumbOne from '../../../common/breadcrumb/BreadcrumbOne';
import Layout from '../../../common/Layout';
import SEO from '../../../common/SEO';

import PostOne from '../../../components/post/PostOne';
import { BlogCarouselParams } from '../../../utils/script';
import PostData from '../../../data/blog/PostData.json';
import Data from './Data.json'
import CallToActionTwo from '../../../components/cta/CallToActionTwo';
import { FAQQQQQ } from './FAQQQQQ';
import InstructorOne from '../../../components/instructor/InstructorOne';
import InstructorData from '../../../data/instructor/InstructorData.json';
import Block from './Block';
// import { FaFile, FaFileCsv, FaFileInvoice, FaIdCardAlt, FaPassport } from 'react-icons/fa';
import { FaDollarSign, FaFile, FaFileCsv, FaFileInvoice, FaIdCardAlt, FaPassport } from 'react-icons/fa';
import { RiPassportFill } from 'react-icons/ri';
import { TbCertificate } from 'react-icons/tb';
import DataSet from './DataSet';
import TopUniversitySlider from './TopUniversitySlider';
import { MdDescription } from 'react-icons/md';
import { IoIosPaper } from 'react-icons/io';
import FaqIELTS from '../../ielts/faqIELTS';
import Explore from '../Explore';
import Exploreplaces from '../Exploreplaces.json';
import { useParams } from 'react-router-dom';
import Main from '../Main.json';
import FAQ from './FAQ';
import BannerSlider from './BannerSlider';



const PrevArrow = (props) => {
    const { onClick } = props;
    return <button className="slide-arrow prev-arrow" onClick={onClick}><i className="icon-arrow-left-line"></i></button>
}

const NextArrow = (props) => {
    const { onClick } = props;
    return <button className="slide-arrow next-arrow" onClick={onClick}><i className="icon-arrow-right-line"></i></button>
}
const Destination = ({ wrapperClass }) => {

    const { name } = useParams();
    //const dataName = parseInt(name)
    const dataItem = Main.filter(blog => blog.name === name)
    const data = dataItem[0];

    const [selectedItem, setSelectedItem] = useState('online-education');
    const [contentTab, setContentTab] = useState('overview');
    const handleTab = (content) => {
        if (content === 'overview') {
            setContentTab('overview');
        } else if (content === 'curriculum') {
            setContentTab('curriculum');
        } else if (content === 'instructor') {
            setContentTab('instructor');
        } else if (content === 'reviews') {
            setContentTab('reviews');
        }
    }

    const faqCategories = [
        {
            id: 1,
            slug: 'online-education',
            label: 'Online Education'
        },
        {
            id: 2,
            slug: 'payment-method',
            label: 'Payment Method'
        },
        {
            id: 3,
            slug: 'pricing-plan',
            label: 'Pricing Plan'
        }
    ];
    const TeamMembers = InstructorData.slice(0, 8);
    const sliderSettings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        centerMode: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 577,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    arrows: false
                }
            }
        ]
    };
    return (
        <>

            <SEO title="Destination" />
            <Layout>
            <BannerSlider banners={data.banners}/>
                {/* <BreadcrumbOne
                    title="Destination"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl={data.title}
                /> */}
                <div style={{ marginTop: "50px" }} className="edu-course-area course-three-wrapper  bg-color-white position-relative">
                    <div className="container">
                        <div className='course-details-content'>
                            <ul className="edu-course-tab nav nav-tabs" role="tablist">
                                {/* <li className="nav-item">
                                            <button
                                                className={contentTab === 'overview' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Overview"
                                                onClick={() => handleTab('overview')}
                                            >
                                                FAST FECT
                                            </button>
                                        </li> */}
                                <li className="nav-item">
                                    <button
                                        className={contentTab === 'Top' ? 'nav-link active' : 'nav-link'}
                                        type="button"
                                        aria-label="Top"
                                    // onClick={() => handleTab('overview')}
                                    >
                                        <a href='#tipp1'>TOP UNIVERSITIES</a>
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={contentTab === 'Admission' ? 'nav-link active' : 'nav-link'}
                                        type="button"
                                        aria-label="Admission"
                                    // onClick={() => handleTab('overview')}
                                    >
                                        <a href='#tipp2'>ADMISSIONS </a>
                                    </button>
                                </li>

                                <li className="nav-item">
                                    <button
                                        className={contentTab === 'Scholar' ? 'nav-link active' : 'nav-link'}
                                        type="button"
                                        aria-label="Scholar"
                                    // onClick={() => handleTab('overview')}
                                    >
                                        <a href="#tipp3">SCHOLARSHIPS </a>
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={contentTab === 'curriculam' ? 'nav-link active' : 'nav-link'}
                                        type="button"
                                        aria-label="Curriculam"
                                    //onClick={() => handleTab('overview')}
                                    >
                                        <a href='#tipp4'> WORK OPPORTUNITIES </a>
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className={contentTab === 'faq' ? 'nav-link active' : 'nav-link'}
                                        type="button"
                                        aria-label="faq"
                                    // onClick={() => handleTab('overview')}
                                    >
                                        <a href='#tipp5' > FAQ</a>
                                    </button>
                                </li>
                                {/* <li className="nav-item">
                                            <button
                                                className={contentTab === 'instructor' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Instructor"
                                                onClick={() => handleTab('instructor')}
                                            >
                                               COST OF LIVING
                                            </button>
                                        </li> */}
                                {/* <li className="nav-item">
                                            <button
                                                className={contentTab === 'reviews' ? 'nav-link active' : 'nav-link'}
                                                type="button"
                                                aria-label="Reviews"
                                                onClick={() => handleTab('reviews')}
                                            >
                                               WORK OPPORTUNITIES
                                            </button>
                                        </li> */}


                            </ul>



                        </div>
                        {/* <CourseTypeFilter itemToShow="9" /> */}
                        <Block datas={data.fastFacts} />
                        <div style={{ fontWeight: "bolder", fontSize: "40px", paddingTop: "40px", color: "black" }}>

                        <blockquote className="blockquote-style-1" style={{ background: "#FFFFFF", borderRadius: "5px", borderTop: "3px solid var(--color-primary)", color: "var(--color-primary)", boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.06)", textAlign: "center", padding: "25px 68px", fontWeight: "bolder" }}> <h1 style={{ fontSize: "30px", color: "var(--color-primary)" }}>{data.Quote[0].title} </h1><br></br>

{data.Quote[0].detail}
<img className="quote-image" src="/images/icons/quote-2.png" alt="Quote Images" />
</blockquote>
                        </div>
                        <div>
                            <div style={{ fontWeight: "bolder", fontSize: "40px", paddingTop: "30px", color: "black" }}> What’s Trending? </div>
                            <Slider
                                className="eduvibe-post-one-carousel-wrapper mt--10 mb--50 edu-slick-button"
                                {...BlogCarouselParams}
                            >
                                {
                                    data.topTrending.slice(0, 6).map((item) => (
                                        <div className="eduvibe-post-one-carousel-item" key={item.id}>
                                            <DataSet data={item} />
                                        </div>
                                    ))
                                }
                            </Slider>
                            {/* <HomeFiveCategories /> */}
                            <TopUniversitySlider university={data.University} />
                            {/* <CategoryThree /> */}
                            <div className="row g-lg-5 g-md-5 g-sm-5">
                                <div style={{ fontWeight: "bolder", fontSize: "40px", color: "black", marginTop: "19px" }}> Admission Requirement</div>
                                <div>{data.admissionRequirement[0].title}</div>
                                <div className="col-lg-12 col-xl-6">
                                    <div className="inner mt_mobile--40">



                                        <div className="feature-style-6 mt--40">
                                            {data.admissionRequirement[0].label.slice(0, 5).map((item) => {

                                                return (
                                                    <div className="edu-feature-list color-variation-1">
                                                        <div className="icon" style={{ marginTop: "20px" }}>
                                                            <div style={{ color: "black", fontSize: "30px" }}> <FaPassport /></div>
                                                            {/* <img src="/images/about/about-05/student.png" alt="Icons Images" /> */}
                                                        </div>
                                                        <div className="content" style={{ marginTop: "26px" }}>


                                                            <h6 className="title">{item}</h6>
                                                            {/* <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p> */}
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-12 col-xl-6">
                                    <div className="inner mt_mobile--40">


                                        <div className="feature-style-6 mt--40">
                                            {data.admissionRequirement[0].label.slice(6, 11).map((item) => {

                                                return (
                                                    <div className="edu-feature-list color-variation-1">
                                                        <div className="icon" style={{ marginTop: "20px" }}>
                                                            <div style={{ color: "black", fontSize: "30px" }}> <FaPassport /></div>
                                                            {/* <img src="/images/about/about-05/student.png" alt="Icons Images" /> */}
                                                        </div>
                                                        <div className="content" style={{ marginTop: "26px" }}>


                                                            <h6 className="title">{item}</h6>
                                                            {/* <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p> */}
                                                        </div>
                                                    </div>

                                                )
                                            })}
                                        </div>
                                    </div>

                                </div>
                            </div>




                        </div>
                        <div style={{ paddingTop: "230px" }}>
                            <CallToActionTwo />
                        </div>

                       
                        {
                        data.Scholarships[0].list.length < 1
                        ?
                        <div></div>
                        :
                        <div id="tipp3" style={{ fontWeight: "bolder", fontSize: "40px", color: "black", marginTop: "25px" }}>
                            ScholarShip
                            <h6 style={{ fontSize: "16px" }}>{data.Scholarships[0].title}</h6>


                            <div className="course-details-card mt--5" style={{ boxShadow: "0 4px 24px rgba(0,0,0,.12", border: "2px solid #eaeaea" }}>
                                <div className="row g-lg-5 g-md-5 g-sm-5">
                                    <div className="col-lg-12 col-xl-6">
                                        <div className="inner mt_mobile--40">

                                            <div className="feature-style-6">

                                                {data.Scholarships[0].list[0].listt.slice(0, 6).map((scholar) => {
                                                    return (
                                                        <div className="edu-feature-list color-variation-2">
                                                            <div className="icon" style={{ marginTop: "20px" }}>
                                                                <div style={{ color: "black", fontSize: "25px" }}> <FaDollarSign /></div>

                                                            </div>
                                                            <div className="content" style={{ marginTop: "26px" }}>
                                                                <h6 className="title">{scholar}</h6>

                                                            </div>
                                                        </div>

                                                    )
                                                })}



                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-12 col-xl-6">
                                        <div className="inner mt_mobile--40">

                                            <div className="feature-style-6">




                                                {data.Scholarships[0].list[0].listt.slice(7, 12).map((scholar) => {
                                                    return (
                                                        <div className="edu-feature-list color-variation-2">
                                                            <div className="icon" style={{ marginTop: "20px" }}>
                                                                <div style={{ color: "black", fontSize: "25px" }}> <FaDollarSign /></div>
                                                               
                                                            </div>
                                                            <div className="content" style={{ marginTop: "26px" }}>
                                                                <h6 className="title">{scholar}</h6>
                                                                
                                                            </div>
                                                        </div>

                                                    )
                                                })}


                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                         } 
                        <div id="tipp4" style={{ paddingTop: "80px", fontWeight: "bolder", fontSize: "40px", color: "black" }}>
                            Careers + Work Opportunities
                            <p>{data.Careers[0].title}</p>

                            <div className={`home-three-about edu-about-area about-style-4 bg-color-white`} >
                                <div className="container eduvibe-animated-shape" style={{ paddingBottom: "50px" }}>
                                    <div className="row g-lg-5 g-md-5 g-sm-5">
                                        {/* <div className="col-lg-12 col-xl-6">
                        <div className="gallery-wrapper">
                            <img src="/images/about/about-05/about-group-01.jpg" alt="About Images" />
                            <div className="image-2">
                                <Parallax y={[-25, 15]} tagOuter="figure">
                                    <img src="/images/about/about-05/about-group-02.jpg" alt="About Images" />
                                </Parallax>
                            </div>
                            <div className="image-3">
                                <Parallax y={[40, -40]} tagOuter="figure">
                                    <img src="/images/about/about-05/about-group-03.jpg" alt="About Images" />
                                </Parallax>
                            </div>
                        </div>
                    </div> */}
                                        <div className="col-lg-12 col-xl-6">
                                            <div className="inner mt_mobile--40">

                                                <div className="feature-style-6 mt--40">
                                                    <div className="edu-feature-list color-variation-1">
                                                        <div className="icon">
                                                            <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Arts and Humanities</h6>
                                                            {/* <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p> */}
                                                        </div>
                                                    </div>

                                                    <div className="edu-feature-list color-variation-2">
                                                        <div className="icon">
                                                            <img src="/images/about/about-05/book.png" alt="Icons Images" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Social Sciences</h6>
                                                            {/* <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p> */}
                                                        </div>
                                                    </div>

                                                    <div className="edu-feature-list color-variation-3">
                                                        <div className="icon">
                                                            <img src="/images/about/about-05/reward.png" alt="Icons Images" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Law and Legal Studies</h6>
                                                            {/* <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p> */}
                                                        </div>
                                                    </div>
                                                    <div className="edu-feature-list color-variation-3">
                                                        <div className="icon">
                                                            <img src="/images/about/about-05/reward.png" alt="Icons Images" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Engineering and Technology</h6>
                                                            {/* <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p> */}
                                                        </div>
                                                    </div>
                                                    {/* <div className="edu-feature-list color-variation-1">
                                    <div className="icon">
                                        <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Industry Expert Instructor</h6>
                                        <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p>
                                    </div>
                                </div> */}

                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-12 col-xl-6">
                                            <div className="inner mt_mobile--40">

                                                <div className="feature-style-6 mt--40">
                                                    <div className="edu-feature-list color-variation-1">
                                                        <div className="icon">
                                                            <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Architecture</h6>
                                                            {/* <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p> */}
                                                        </div>
                                                    </div>

                                                    <div className="edu-feature-list color-variation-2">
                                                        <div className="icon">
                                                            <img src="/images/about/about-05/book.png" alt="Icons Images" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Design</h6>
                                                            {/* <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p> */}
                                                        </div>
                                                    </div>

                                                    <div className="edu-feature-list color-variation-3">
                                                        <div className="icon">
                                                            <img src="/images/about/about-05/reward.png" alt="Icons Images" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Medicine and Life Sciences</h6>
                                                            {/* <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p> */}
                                                        </div>
                                                    </div>
                                                    <div className="edu-feature-list color-variation-3">
                                                        <div className="icon">
                                                            <img src="/images/about/about-05/reward.png" alt="Icons Images" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Natural Sciences</h6>
                                                            {/* <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p> */}
                                                        </div>
                                                    </div>
                                                    {/* <div className="edu-feature-list color-variation-1">
                                    <div className="icon">
                                        <img src="/images/about/about-05/student.png" alt="Icons Images" />
                                    </div>
                                    <div className="content">
                                        <h6 className="title">Industry Expert Instructor</h6>
                                        <p className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmoded tempor incididunt dolore magna aliqua.</p>
                                    </div>
                                </div> */}

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                                        <div className="shape-image shape-image-1">
                                            <img src="/images/shapes/shape-03-08.png" alt="Shape Thumb" />
                                        </div>
                                        <div className="shape-image shape-image-2">
                                            <img src="/images/shapes/shape-27.png" alt="Shape Thumb" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: "80px", fontWeight: "bolder", fontSize: "40px", color: "black" }}>
                            Explore More Destinations

                            <Slider
                                className="eduvibe-post-one-carousel-wrapper mt--10 mb--50 edu-slick-button"
                                {...BlogCarouselParams}
                            >
                                {
                                    data.ExploreDestianation.slice(0, 6).map((item) => (
                                        <div className="eduvibe-post-one-carousel-item" key={item.id}>
                                            <Explore data={item} />
                                        </div>
                                    ))
                                }
                            </Slider>


                            <br></br>
                            {/* <div style={{backgroundColor:"var(--color-primary)",paddingTop:"20px",color:"white",fontSize:"34px",padding:"20px"}}>
                                    <> Why Study In United Kingdom ?</>
                                    <p style={{color:"white",fontSize:"14px"}}>The United Kingdom is home to the world’s most esteemed universities and is among the popular destinations for studying abroad. Ranked as the best education systems in the world, the British Education System provides a plethora of courses in various subjects including Business, Engineering, Medicine, Arts, and Design delivered through exceptional teaching styles. The curriculum is designed in a flexible way which helps students customize their courses depending on their unique interests. The United Kingdom is among the top countries for advanced research and has contributed consistently to groundbreaking discoveries.

Apart from ranking high in academic excellence, the UK is known for its multicultural ethos that attracts many students globally. Famed for its heritage sites and art, students can also indulge a dynamic culture which makes their stay merrier. Being the global hub of Europe, the country has a high-income economy making it the best place to find various job opportunities. <img style={{float:"right",marginTop:"21px",opacity:"0.2"}} class="quote-image" src="/images/icons/quote.png" alt="Quote Images"/></p>
Explore More Destinations

                                </div> */}
                            

                        </div>

                        <div id="tipp5">  <FAQ datas={data} /></div>


                    </div>
                </div>
                {/* </div> */}
            </Layout>

        </>
    )
}

export default Destination;
